import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../common/scss/pages/signup.scss";
import Auth from "../libs/auth";

import logo from "../assets/images/logo.svg";

function ThankYou() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const user = Auth.getCurrentUser();
    if (!user) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <div style={{ background: "#f4f4f4" }} className="thankyou-page">
        {/* <h1 style={{ margin: 0, fontSize: "42px", marginBottom: "10px" }}>
          Thank you
        </h1> */}

        <div>
          <div
            style={{
              background: "#fff",
              width: "600px",
              height: "500px",
              border: "1px solid #e2e2e2",
              borderRadius: "5px",
              boxShadow: "4px 3px 12px -3px rgba(0,0,0,0.75)",
            }}
          >
            <div
              style={{ display: "flex", marginTop: "20px", marginLeft: "20px" }}
            >
              <img src={logo} style={{ width: "100px" }} />
            </div>
            <div>
              <h2 style={{ fontSize: "24px", fontWeight: 600 }}>
                Welcome To BusiMeet,
              </h2>
            </div>

            <div
              style={{
                padding: "20px",
              }}
            >
              <p
                style={{
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: 0,
                }}
              >
                The world's first &nbsp; <strong>Social Media Platform</strong>{" "}
                &nbsp; for &nbsp;<strong>Business Community.</strong>
              </p>

              <p
                style={{
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: 0,
                  marginTop: "15px",
                }}
              >
                Our platform allows you to seamlessly connect with your business
                colleagues.
              </p>

              <p
                style={{
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: 0,
                  textAlign: "justify",
                  marginTop: "15px",
                }}
              >
                Please go ahead and socialize, conduct business transactions &
                negotiations, do posts, likes, comments, shares, chat and many
                more...
              </p>

              <p
                style={{
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: 0,
                  marginTop: "15px",
                }}
              >
                We hope you enjoy this experience !
              </p>

              <p
                style={{
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: 0,
                  marginTop: "15px",
                }}
              >
                Thank You,
              </p>

              <p
                style={{
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: 0,
                }}
              >
                BusiMeet Team.
              </p>
            </div>

            <Link to="/" className="button button-primary mt-5">
              Navigate to Home
            </Link>
          </div>
        </div>
        {/* <p>
          <span>
            You're now a member of <strong>BusiMeet</strong>.
          </span>
          We appreciate your time to become a valuable member of BusiMeet
          family. Stay connected and enjoy using Business Social Media platform.
          We assure you, a lot you can do for your Business here !
        </p>
        */}
      </div>
    </>
  );
}

export default ThankYou;
