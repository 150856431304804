import React, { useEffect, useRef, useState } from "react";
import Header from "../common/header";
import Breadcrumb from "../common/breadcrumb";
import "../common/scss/pages/product-protfolio.scss";
import { ReactComponent as EditIcon } from "../assets/images/edit-icon2.svg";
import { ReactComponent as CompleteIcon } from "../assets/images/complete-icon.svg";
import { ReactComponent as InfoIcon } from "../assets/images/info.svg";
import { ReactComponent as AttachmentIcon } from "../assets/images/attachment.svg";
import { ReactComponent as ButtonEditIcon } from "../assets/images/button-edit-icon.svg";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import ProductImage from "../assets/images/product-image.png";
import ProductImagePlaceholder from "../assets/images/uploadImg.svg";
import Cancel from "../assets/images/cancel.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ArrowDownIcon from "../assets/images/arrow-down.svg";
import Tooltip from "react-bootstrap/Tooltip";
import { post, get, postwithOu, getAuthConfig } from "../libs/http-hydrate";
import FieldValidationError from "../components/error-messages/field-validation-error";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-easy-crop";
import getCroppedImg, { createImage } from "./Crop";
import getCroppedImgr, {
  convertDataURIToBinary,
  dataURLtoFile,
  getBase64Image,
} from "../utils/dataURLtoFile";
import { useLocation, useNavigate } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import PostHideFrom from "./post-hide-modal";
import { customStyles } from "../utils/react-select-input-style";
import { IconArrow } from "../utils/icons";

function EditPost() {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }
  const location = useLocation();
  const [templateArray, setTemplateArray] = useState([]);
  const [productArray, setProductArray] = useState({
    items: [],
    has_more: false,
  });
  const [productSubCategoryArray, setProductSubCategoryArray] = useState({
    items: [],
    has_more: false,
  });
  const refy = useRef(null);
  const [categoryPage, setcategoryPage] = useState(0);
  const [subCatPage, setsubCatPage] = useState(0);
  const [BrandArray, setBrandArray] = useState([{ id: "", name: "other" }]);
  const [availableColor, setAvailableColor] = useState([]);
  const [certificate, setCertificate] = useState({ data: "" });
  const [availableUnit, setAvailableUnit] = useState({
    items: [],
    has_more: false,
  });
  const [unitPage, setUnitPage] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [output, setOutput] = useState(null);
  const navigate = useNavigate();
  const [ProductData, setProductData] = useState({
    name: "",
    category_id: "",
    sub_category_id: "",
    size: "",
    material_type: "",
    code: "",
    shape: "",
    capacity: "",
    color_id: "",
    brand_id: "",
    min_order_qty: "",
    quality_standard: "",
    certificate: "",
    unit_id: "",
    for_advance_payment: "",
    for_credit_payment: "",
    hsn_code: "",
    gst_rate: "",
    delivery_within_state: "",
    delivery_outside_state: "",
    description: "",
    Front_Image: " ",
    Back_Image: " ",
    Upper_Image: " ",
    Lower_Image: " ",
    Left_Image: " ",
    Right_Image: " ",
    otherBrandName: " ",
    resticate: [],
    certificates: "",
  });
  const [resti, setResti] = useState(0);
  const [ProductDataErr, setProductDataErr] = useState({
    name: false,
    category_id: false,
    sub_category_id: false,
    size: false,
    material_type: false,
    code: false,
    shape: false,
    capacity: false,
    color_id: false,
    brand_id: false,
    min_order_qty: false,
    quality_standard: false,
    certificate: false,
    unit_id: false,
    for_advance_payment: false,
    for_credit_payment: false,
    hsn_code: false,
    gst_rate: false,
    delivery_within_state: false,
    delivery_outside_state: false,
    Front_Image: false,
  });

  const categoryRef = useRef();
  const DifferentiationRef = useRef();
  const OtherRef = useRef();
  const PricingRef = useRef();
  const TaxRef = useRef();
  const DeliveryRef = useRef();
  const descriptionRef = useRef();
  const imagesRef = useRef();
  const selectInputRef = useRef();
  const nameRef = useRef();

  const onTop = () => {
    window.scrollTo(0, 0);
  };
  UseEffectOnce(() => {
    onTop();
  }, [location]);

  UseEffectOnce(() => {
    get(`/product/detail/${location?.state?.id}?is_for_edit=1`, getAuthConfig())
      .then((response) => {
        setProductData(response.data.data.product);
        setResti(response?.data?.data?.user_resticated_count);
        if (response.data.data.product.media) {
          ImageMaptoState(response.data.data.product.media);
        }
      })
      .catch((e) => {
        // alert("this is the errror", e);
      });
  });
  function TemplateFunction() {
    get("/general/content?search&type=post_to_sell")
      .then((response) => {
        setTemplateArray(response.data.data);
      })
      .catch((e) => {
        // alert("this is the errror", e);
      });
  }

  function intialCat() {
    post("/general/category_listing", {
      type: "product",
      page: categoryPage + 1,
    })
      .then((response) => {
        setProductArray((p) => ({
          ...p,
          items: productArray.items.concat(response?.data?.data),
        }));
        setProductArray((p) => ({ ...p, has_more: response?.data?.has_more }));
      })
      .catch((e) => {
        // alert("this is the errror", e);
      });
  }

  function searcSubCat(parent_id, newValue) {
    post("/general/category_listing", {
      type: "product_sub_category",
      per_page: 50,
      page: subCatPage + 0,
      search: newValue,
      parent_id: parent_id,
    })
      .then((response) => {
        setProductSubCategoryArray((p) => ({
          ...p,
          items: response?.data?.data,
        }));
        setProductSubCategoryArray((p) => ({
          ...p,
          has_more: response?.data?.has_more,
        }));
        // setProductSubCategoryArray(response.data.data);
      })
      .catch((e) => {
        // alert("this is the errror", e);
      });
  }
  function IntialColor() {
    post("/general/category_listing", { type: "color" })
      .then((response) => {
        setAvailableColor(response.data.data);
      })
      .catch((e) => {
        // alert("this is the error", e);
      });
  }
  useEffect(() => {
    IntialUnit();
  }, [unitPage]);
  function IntialUnit() {
    post("/general/category_listing", { type: "unit", per_page: 5000 })
      .then((response) => {
        if (unitPage > 1) {
          setAvailableUnit((p) => ({
            ...p,
            items: availableUnit?.items?.concat(response?.data?.data),
          }));
        } else {
          setAvailableUnit((p) => ({ ...p, items: response?.data?.data }));
        }
        setAvailableUnit((p) => ({ ...p, has_more: response?.data?.has_more }));
      })
      .catch((e) => {
        // alert("this is the error", e);
      });
  }

  function subCategory(id) {
    post("/general/category_listing", {
      type: "product_sub_category",
      parent_id: id,
    })
      .then((response) => {
        setProductSubCategoryArray(response.data.data);
      })
      .catch((e) => {
        // alert("this is the error", e);
      });
  }

  function clear() {
    selectInputRef.current?.commonProps?.setValue("");
  }
  useEffect(() => {
    if (subCatPage === 0) {
    } else {
      // searcSubCat(ProductData?.category_id);
    }
  }, [subCatPage]);
  useEffect(() => {
    if (categoryPage === 0) {
    } else {
      intialCat();
    }
  }, [categoryPage]);

  async function handleProductCreate(e) {
    e.preventDefault();
    var bool = false;
    if (
      ProductData?.Front_Image.trim() === "" &&
      ProductData?.Back_Image.trim() === "" &&
      ProductData?.Lower_Image.trim() === "" &&
      ProductData?.Upper_Image.trim() === "" &&
      ProductData?.Left_Image.trim() === "" &&
      ProductData?.Right_Image.trim() === ""
    ) {
      bool = true;
      setProductDataErr((p) => ({ ...p, Front_Image: true }));
      imagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // if (ProductData?.description && ProductData?.description.trim() === "") {
    //   bool = true;

    //   setProductDataErr((p) => ({ ...p, description: true }));
    //   descriptionRef.current.scrollIntoView({ behavior: "smooth" });
    // }
    // if (
    //   ProductData?.delivery_details?.delivery_outside_state &&
    //   ProductData?.delivery_details?.delivery_outside_state.trim() === ""
    // ) {
    //   bool = true;

    //   setProductDataErr((p) => ({ ...p, delivery_outside_state: true }));
    //   DeliveryRef.current.scrollIntoView({ behavior: "smooth" });
    // }
    // if (
    //   ProductData?.delivery_details?.delivery_within_state &&
    //   ProductData?.delivery_details?.delivery_within_state.trim() === ""
    // ) {
    //   bool = true;

    //   setProductDataErr((p) => ({ ...p, delivery_within_state: true }));
    //   DeliveryRef.current.scrollIntoView({ behavior: "smooth" });
    // }

    if (ProductData?.gst_rate && ProductData?.gst_rate.trim() === "") {
      bool = true;

      setProductDataErr((p) => ({ ...p, gst_rate: true }));
      TaxRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (ProductData?.hsn_code && ProductData?.hsn_code.trim() === "") {
      bool = true;

      setProductDataErr((p) => ({ ...p, hsn_code: true }));
      TaxRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (ProductData?.amount === "" && ProductData?.credit_amount === "") {
      bool = true;

      setProductDataErr((p) => ({ ...p, for_advance_payment: true }));
      PricingRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (ProductData?.unit_id === "") {
      bool = true;

      setProductDataErr((p) => ({ ...p, unit_id: true }));
      OtherRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (ProductData?.order_qty === "") {
      bool = true;

      setProductDataErr((p) => ({ ...p, min_order_qty: true }));
      OtherRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (
      ProductData?.size === "" &&
      ProductData?.color_id === "" &&
      ProductData?.material_type === "" &&
      ProductData?.brand_id === "" &&
      ProductData?.code === "" &&
      ProductData?.shape === "" &&
      ProductData?.storage_capacity === ""
    ) {
      bool = true;

      setProductDataErr((p) => ({ ...p, size: true }));
      DifferentiationRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (ProductData?.sub_category_id === "") {
      bool = true;

      setProductDataErr((p) => ({ ...p, sub_category_id: true }));
      categoryRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (ProductData?.category_id === "") {
      bool = true;

      setProductDataErr((p) => ({ ...p, category_id: true }));
      categoryRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (ProductData?.name === "") {
      bool = true;

      setProductDataErr((p) => ({ ...p, name: true }));
      nameRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (
      ProductDataErr?.category_id === true ||
      ProductDataErr?.sub_category_id === true ||
      ProductDataErr?.size === true ||
      ProductDataErr?.order_qty === true ||
      ProductDataErr?.unit_id === true ||
      ProductDataErr?.for_advance_payment === true ||
      ProductDataErr?.hsn_code === true ||
      ProductDataErr?.gst_rate === true ||
      ProductDataErr?.delivery_details?.delivery_outside_state === true ||
      ProductDataErr?.delivery_details?.delivery_within_state === true ||
      // ProductDataErr?.description === true ||
      ProductDataErr?.name === true
    ) {
      bool = true;
      return;
    }

    if (bool === true) {
      setLoading(false);
      return;
    }

    const formdata = new FormData();
    if (ProductData?.brand_id === "other") {
      const BrandFromData = new FormData();
      BrandFromData.append("sub_category_id", ProductData?.sub_category_id);
      BrandFromData.append("name", ProductData?.otherBrandName);
      setLoading(true);

      const url = "/product/brand/create";
      const response = await postwithOu(url, getAuthConfig(), BrandFromData);
      setLoading(false);
    }

    if (ProductData?.brand_id != "other" && ProductData?.brand_id != null) {
      formdata.append("brand_id", ProductData?.brand_id);
    }

    formdata.append("name", ProductData?.name);

    formdata.append("type", "product");

    formdata.append("category_id", ProductData?.category_id);
    formdata.append("sub_category_id", ProductData?.sub_category_id);
    formdata.append("size", ProductData?.size);

    if (ProductData?.material_type === null) {
    } else {
      formdata.append("material_type", ProductData?.material_type);
    }
    if (ProductData?.product_code === null) {
    } else {
      formdata.append("product_code", ProductData?.product_code);
    }
    if (ProductData?.shape === null) {
    } else {
      formdata.append("shape", ProductData?.shape);
    }
    if (ProductData?.storage_capacity === null) {
    } else {
      formdata.append("storage_capacity", ProductData?.storage_capacity);
    }
    if (ProductData?.color?.id === 0) {
    } else {
      formdata.append("color_id", ProductData?.color?.id);
    }
    formdata.append("order_qty", ProductData?.order_qty);
    if (ProductData?.certificates != undefined) {
      formdata.append("attachment", ProductData?.certificates);
    }
    if (ProductData?.product_catalogue != undefined) {
      formdata.append("catalogue", ProductData?.product_catalogue);
    }
    if (ProductData?.unit_id === 0) {
    } else {
      formdata.append("unit_id", ProductData?.unit_id);
    }
    formdata.append("amount", ProductData?.amount);
    formdata.append("credit_amount", ProductData?.credit_amount);
    formdata.append("hsn_code", ProductData?.hsn_code);
    formdata.append("gst_rate", ProductData?.gst_rate);
    formdata.append(
      "delivery_within_state",
      ProductData?.delivery_details?.delivery_within_state
    );

    formdata.append(
      "delivery_outside_state",
      ProductData?.delivery_details?.delivery_outside_state
    );
    formdata.append("description", ProductData?.description);

    formdata.append("negotiation", ProductData?.negotiation);
    formdata.append("min_order_value", ProductData?.min_order_value);
    formdata.append("freight_charges", ProductData?.freight_charges);
    formdata.append("post_template_id", ProductData?.post_template?.id);

    if (ProductData?.Front_Image != undefined) {
      const filtered = ProductData?.media.filter((obj) => {
        return obj.collection_name === "front";
      });

      if (filtered.length >= 0) {
        if (
          filtered.length > 0 &&
          filtered[0].file === ProductData?.Front_Image
        ) {
        } else {
          formdata.append("gallery[0][type]", "front");
          if (ProductData?.Front_Image.substring(0, 4) == "blob") {
            const ddata = await getCroppedImgr(ProductData?.Front_Image);
            formdata.append(
              "gallery[0][image]",
              dataURLtoFile(ddata, "Front.jpeg")
            );
          } else {
            formdata.append(
              "gallery[0][image]",
              dataURLtoFile(ProductData?.Front_Image, "Front.jpeg")
            );
          }
        }
      }

      // let front = false ;
      // ProductData?.media.map(async (item, index) => {
      //   if (item.type === "front") {
      //     if (item.file === ProductData?.Front_Image) {
      //     } else {
      //       formdata.append("gallery[0][type]", "front");
      //       if (ProductData?.Front_Image.substring(0, 4) == "blob") {
      //         const ddata = await getCroppedImgr(ProductData?.Front_Image);
      //         formdata.append(
      //           "gallery[0][image]",
      //           dataURLtoFile(ddata, "Front.jpeg")
      //         );
      //       } else {
      //         formdata.append(
      //           "gallery[0][image]",
      //           dataURLtoFile(ProductData?.Front_Image, "Front.jpeg")
      //         );
      //       }
      //     }
      //   }
      //   else {

      //                 front = true ;
      //   }
      // });
      // if(front === true ){
      //   const ddata = await getCroppedImgr(ProductData?.Front_Image);
      //   formdata.append(
      //     "gallery[0][image]",
      //     dataURLtoFile(ddata, "Front.jpeg")
      //   );
      // }
    }
    if (ProductData?.Back_Image != undefined) {
      const filtered = ProductData?.media.filter((obj) => {
        return obj.collection_name === "back";
      });
      if (filtered.length >= 0) {
        if (
          filtered.length > 0 &&
          filtered[0].file === ProductData?.Back_Image
        ) {
        } else {
          formdata.append("gallery[1][type]", "back");
          if (ProductData?.Back_Image.substring(0, 4) == "blob") {
            const ddata = await getCroppedImgr(ProductData?.Back_Image);
            formdata.append(
              "gallery[1][image]",
              dataURLtoFile(ddata, "back.jpeg")
            );
          } else {
            formdata.append(
              "gallery[1][image]",
              dataURLtoFile(ProductData?.Back_Image, "back.jpeg")
            );
          }
        }
      }
    }
    if (ProductData?.Upper_Image != undefined) {
      const filtered = ProductData?.media.filter((obj) => {
        return obj.collection_name === "upper";
      });
      if (filtered.length >= 0) {
        if (
          filtered.length > 0 &&
          filtered[0].file === ProductData?.Upper_Image
        ) {
        } else {
          formdata.append("gallery[0][type]", "upper");
          if (ProductData?.Upper_Image.substring(0, 4) == "blob") {
            const ddata = await getCroppedImgr(ProductData?.Upper_Image);
            formdata.append(
              "gallery[2][image]",
              dataURLtoFile(ddata, "uppper.jpeg")
            );
          } else {
            formdata.append(
              "gallery[2][image]",
              dataURLtoFile(ProductData?.Upper_Image, "upper.jpeg")
            );
          }
        }
      }
    }
    if (ProductData?.Lower_Image != undefined) {
      const filtered = ProductData?.media.filter((obj) => {
        return obj.collection_name === "lower";
      });
      if (filtered.length >= 0) {
        if (
          filtered.length > 0 &&
          filtered[0].file === ProductData?.Lower_Image
        ) {
        } else {
          formdata.append("gallery[3][type]", "lower");
          if (ProductData?.Lower_Image.substring(0, 4) == "blob") {
            const ddata = await getCroppedImgr(ProductData?.Lower_Image);
            formdata.append(
              "gallery[3][image]",
              dataURLtoFile(ddata, "lower.jpeg")
            );
          } else {
            formdata.append(
              "gallery[3][image]",
              dataURLtoFile(ProductData?.Lower_Image, "lower.jpeg")
            );
          }
        }
      }
    }
    if (ProductData?.Right_Image != undefined) {
      const filtered = ProductData?.media.filter((obj) => {
        return obj.collection_name === "right";
      });
      if (filtered.length >= 0) {
        if (
          filtered.length > 0 &&
          filtered[0].file === ProductData?.Right_Image
        ) {
        } else {
          formdata.append("gallery[4][type]", "right");
          if (ProductData?.Right_Image.substring(0, 4) == "blob") {
            const ddata = await getCroppedImgr(ProductData?.Right_Image);
            formdata.append(
              "gallery[4][image]",
              dataURLtoFile(ddata, "right.jpeg")
            );
          } else {
            formdata.append(
              "gallery[4][image]",
              dataURLtoFile(ProductData?.Right_Image, "right.jpeg")
            );
          }
        }
      }
    }
    if (ProductData?.Left_Image != undefined) {
      const filtered = ProductData?.media.filter((obj) => {
        return obj.collection_name === "left";
      });
      if (filtered.length >= 0) {
        if (
          filtered.length > 0 &&
          filtered[0].file === ProductData?.Left_Image
        ) {
        } else {
          formdata.append("gallery[5][type]", "left");
          if (ProductData?.Left_Image.substring(0, 4) == "blob") {
            const ddata = await getCroppedImgr(ProductData?.Left_Image);
            formdata.append(
              "gallery[5][image]",
              dataURLtoFile(ddata, "left.jpeg")
            );
          } else {
            formdata.append(
              "gallery[3][image]",
              dataURLtoFile(ProductData?.Left_Image, "left.jpeg")
            );
          }
        }
      }
    }

    if (
      ProductData?.resticate != undefined &&
      ProductData?.resticate.length > 0
    ) {
      ProductData?.resticate.map((item, index) => {
        formdata.append(`resticated_users[${index}]`, item);
      });
    }
    const url = `/product/update/${ProductData?.id}`;
    const response = await postwithOu(url, getAuthConfig(), formdata)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          navigate("/my-post");
        }
      })
      .catch((err) => {
        setLoading(false);
        // alert(err.response.message);
      });
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const [croppedImages, setCroppedImages] = useState({
    Front_Image: false,
    Back_Image: false,
    Upper_Image: false,
    Lower_Image: false,
    Left_Image: false,
    Right_Image: false,
  });
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        croppedImages?.Front_Image === true
          ? ProductData?.Front_Image
          : croppedImages?.Back_Image === true
          ? ProductData?.Back_Image
          : croppedImages?.Left_Image === true
          ? ProductData?.Left_Image
          : croppedImages?.Right_Image === true
          ? ProductData?.Right_Image
          : croppedImages?.Upper_Image === true
          ? ProductData?.Upper_Image
          : croppedImages?.Lower_Image === true
          ? ProductData?.Lower_Image
          : "",
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
      if (croppedImages?.Front_Image === true) {
        setProductData((p) => ({ ...p, Front_Image: croppedImage }));
      }

      if (croppedImages?.Back_Image === true) {
        setProductData((p) => ({ ...p, Back_Image: croppedImage }));
      }

      if (croppedImages?.Left_Image === true) {
        setProductData((p) => ({ ...p, Left_Image: croppedImage }));
      }

      if (croppedImages?.Lower_Image === true) {
        setProductData((p) => ({ ...p, Lower_Image: croppedImage }));
      }
      if (croppedImages?.Right_Image === true) {
        setProductData((p) => ({ ...p, Right_Image: croppedImage }));
      }
      if (croppedImages?.Upper_Image === true) {
        setProductData((p) => ({ ...p, Upper_Image: croppedImage }));
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, ProductData?.Front_Image]);
  function postHideArray(data) {
    let arr = data;
    let filter = [];

    arr.map((item, index) => {
      if (item?.is_blocked === 1) {
        filter.push(item?.id);
      }
    });

    setResti(filter.length);
    setProductData((p) => ({ ...p, resticate: filter }));
  }
  function searcCat(newValue) {
    post("/general/category_listing", {
      type: "product",
      page: 1,
      search: newValue,
    })
      .then((response) => {
        setProductArray((p) => ({
          ...p,
          items: response?.data?.data,
        }));
        setProductArray((p) => ({ ...p, has_more: response?.data?.has_more }));
      })
      .catch((e) => {});
  }

  function searcSubCat(parent_id, newValue) {
    post("/general/category_listing", {
      type: "product_sub_category",
      per_page: 50,
      page: subCatPage + 0,
      search: newValue,
      parent_id: parent_id,
    })
      .then((response) => {
        if (subCatPage === 0) {
          setProductSubCategoryArray((p) => ({
            ...p,
            items: response?.data?.data,
          }));
        } else {
          setProductSubCategoryArray((p) => ({
            ...p,
            items: productSubCategoryArray.items.concat(response?.data?.data),
          }));
        }

        setProductSubCategoryArray((p) => ({
          ...p,
          has_more: response?.data?.has_more,
        }));
        // setProductSubCategoryArray(response.data.data);
      })
      .catch((e) => {
        // alert("this is the errror", e);
      });
  }
  function searcBrand(newValue, parent_id) {
    post("/general/category_listing", {
      type: "brand",
      page: 1,
      search: newValue,
      parent_id: parent_id,
    })
      .then((response) => {
        setBrandArray(response.data.data);
        setBrandArray((oldArray) => [
          ...oldArray,
          { id: "other", name: "other" },
        ]);
      })
      .catch((e) => {
        // alert("this is the errror", e);
      });
  }

  function BrandGet(value) {
    post("/general/category_listing", {
      type: "brand",
      page: 1,
      parent_id: value,
    })
      .then((response) => {
        setBrandArray(response.data.data);

        setBrandArray((oldArray) => [
          ...oldArray,
          { id: "other", name: "other" },
        ]);
      })
      .catch((e) => {
        // alert("this is the errror", e);
      });
  }
  function ImageMaptoState(media) {
    // alert("ImageSet")
    var Images = media;
    Images.map((item) => {
      if (item.collection_name === "front") {
        setProductData((p) => ({ ...p, Front_Image: item.file }));
      } else if (item.collection_name === "back") {
        setProductData((p) => ({ ...p, Back_Image: item.file }));
      } else if (item.collection_name === "right") {
        setProductData((p) => ({ ...p, Right_Image: item.file }));
      } else if (item.collection_name === "upper") {
        setProductData((p) => ({ ...p, Upper_Image: item.file }));
      } else if (item.collection_name === "lower") {
        setProductData((p) => ({ ...p, Lower_Image: item.file }));
      } else if (item.collection_name === "left") {
        setProductData((p) => ({ ...p, Left_Image: item.file }));
      }
    });

    // setTimeout(()=>{
    //   named()
    // },1000)
  }

  async function named() {
    if (ProductData.Front_Image.trim() === "") {
      setProductData((p) => ({ ...p, Front_Image: "" }));
    }
    if (ProductData.Back_Image.trim() === "") {
      setProductData((p) => ({ ...p, Back_Image: "" }));
    }
    if (ProductData.Upper_Image.trim() === "") {
      setProductData((p) => ({ ...p, Upper_Image: "" }));
    }
    if (ProductData.Lower_Image.trim() === "") {
      setProductData((p) => ({ ...p, Lower_Image: "" }));
    }
    if (ProductData.Right_Image.trim() === "") {
      setProductData((p) => ({ ...p, Right_Image: "" }));
    }
    if (ProductData.Left_Image.trim() === "") {
      setProductData((p) => ({ ...p, Left_Image: "" }));
    }
  }

  return (
    <>
      <Header home />
      <div style={{ paddingBottom: "30px" }} className="page">
        <div style={{ width: "70%" }} className="container-fluid">
          {/* <Breadcrumb props={"Edit"} /> */}

          {/* <div className="row protfolio-boxes">
            <div class="col-sm">
              <div className="chips chips--white">
                <div className="chips--text">Minimum Order Valule</div>
                <div className="chips--value">10000 INR</div>
                <button className="button-edit">
                  <EditIcon />
                </button>
              </div>
            </div>
            <div class="col-sm">
              <div className="chips chips--white">
                <div className="chips--text">Freight Charges</div>
                <div className="chips--value">2%</div>
                <button className="button-edit">
                  <EditIcon />
                </button>
              </div>
            </div>
            <div class="col-sm">
              <div className="chips chips--white">
                <div className="chips--text">
                  Credit Policy
                  <CompleteIcon className="complete" />
                </div>
                <div className="chips--value">
                  <span className="text-small">
                    <InfoIcon />
                    Info
                  </span>
                </div>
                <button className="button-edit">
                  <EditIcon />
                </button>
              </div>
            </div>
            <div class="col-sm">
              <div className="chips chips--white">
                <div className="chips--text">Product Catalogue</div>
                <div className="chips--value">0/2</div>
                <button className="button-edit">
                  <EditIcon />
                </button>
              </div>
            </div>
            <div class="col-sm">
              <div className="chips chips--white">
                <div className="chips--text">Negotiation</div>
                <div className="chips--value">
                  <div className="switch-box switch-box--withLabel switch-box--small">
                    <input type="checkbox" id="negotiation_switch" />
                    <label htmlFor="negotiation_switch">
                      <span className="switch-icon off">OFF</span>
                      <span className="switch-icon on">ON</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm">
              <div className="chips chips--white">
                <div className="chips--text">Price Hide From</div>
                <div className="chips--value">
                  <span className="color-red">5</span> Users
                </div>
                <button className="button-edit">
                  <EditIcon />
                </button>
              </div>
            </div>
            <div class="col-sm"></div>
          </div> */}
          <div className="create-product-protfolio">
            <div
              style={{ padding: "20px" }}
              className="page-heading d-flex align-items-center justify-content-center"
            >
              <h6>Edit Post</h6>
              {/* <button
                className="button button-primary"
                onClick={(e) => {
                  handleProductCreate(e);
                }}
              >
                {Loading === true ? <PuffLoader size={15} /> : "Edit Product"}
              </button> */}
            </div>
            <form>
              <div style={{ padding: "20px 85px" }}>
                <div className="row">
                  <div class="col-sm">
                    <div className="field-set">
                      <div className="field-set-label">
                        <label>
                          1. Product Details<span className="mendatory">*</span>
                        </label>
                      </div>
                    </div>
                    <div className="form-field">
                      {/* <label className="form-label" ref={nameRef}>
                        Product Name
                      </label> */}
                      <input
                        type="text"
                        value={ProductData?.name}
                        name="size"
                        id="size"
                        className="form-input"
                        placeholder="Write Product Name "
                        onChange={(e) => {
                          setProductData({
                            ...ProductData,
                            name: e.target.value,
                          });
                          setProductDataErr({ ...ProductDataErr, name: false });
                        }}
                        style={{
                          borderRadius: "10px",
                        }}
                      />

                      {ProductDataErr?.name === true ? (
                        <FieldValidationError message="Name is required" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="field-set">
                  {/* <div className="field-set-label">
                    <label>
                      Product Categories<span className="mendatory">*</span>
                    </label>
                    <span className="info">
                      Both Must Be Selected
                      <span className="tooltip-icon">
                        <OverlayTrigger
                          key="mobile-tip"
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-mobile-tip">
                              Product Categories and Sub Categories
                            </Tooltip>
                          }
                        >
                          <InfoIcon />
                        </OverlayTrigger>
                      </span>
                    </span>
                  </div> */}

                  <div className="row">
                    <div class="col-sm">
                      <div>
                        {/* <label className="form-label" ref={categoryRef}>
                          Category
                        </label> */}

                        <Select
                          placeholder={`${
                            ProductData?.category?.parent?.name ||
                            "Select Product Category"
                          }`}
                          id="category"
                          onFocus={(e) => {
                            e.preventDefault();
                            intialCat();
                          }}
                          options={
                            productArray?.items &&
                            productArray?.items.map(function (te) {
                              return {
                                value: te.id,
                                label: te.name,
                              };
                            })
                          }
                          onKeyDown={(e) => {
                            setTimeout(() => {
                              searcCat(e.target.value);
                            }, 1000);
                          }}
                          onChange={(e) => {
                            clear();
                            setProductData((p) => ({
                              ...p,
                              category_id: e.value,
                            }));

                            setProductDataErr({
                              ...ProductDataErr,
                              category_id: false,
                            });
                            //  selectInputRef.current.commonProps.setValue("");
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                            setcategoryPage(0);
                            setProductArray((p) => ({ ...p, items: [] }));
                          }}
                          onMenuScrollToBottom={(e) => {
                            if (productArray?.has_more === true) {
                              let p = categoryPage + 1;
                              setcategoryPage(p);
                            }
                          }}
                          styles={customStyles(darkMode)}
                          menuPlacement="auto"
                          menuPosition="absolute"
                        ></Select>
                        {/* 
                      <select
                        value={ProductData.category_id}
                        onChange={(e) => {
                          setProductData({
                            ...ProductData,
                            category_id: e.target.value,
                          });
                          setProductDataErr({
                            ...ProductDataErr,
                            category_id: false,
                          });
                        }}
                        className="form-input"
                      >
                        <option>Select Your Category</option>
                        {productArray.map((product) => (
                          <option value={product.id}>{product.name}</option>
                        ))}
                      </select> */}

                        {ProductDataErr?.category_id === true ? (
                          <FieldValidationError message="Please select category "></FieldValidationError>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                    <div class="col-sm">
                      <div className="mb-3">
                        {/* <label className="form-label">Sub Category</label> */}
                        <Select
                          ref={refy}
                          id="subCategory"
                          placeholder={`${
                            ProductData?.category?.name ||
                            "Select Product Sub-Category"
                          }`}
                          options={
                            productSubCategoryArray?.items &&
                            productSubCategoryArray?.items.map(function (
                              sub_category
                            ) {
                              return {
                                value: sub_category.id,
                                label: sub_category.name,
                              };
                            })
                          }
                          onFocus={(e) => {
                            e.preventDefault();
                            if (ProductData?.category_id != "") {
                              searcSubCat(ProductData?.category_id);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (ProductData?.category_id != "") {
                              setTimeout(() => {
                                searcSubCat(
                                  ProductData?.category_id,
                                  e.target.value
                                );
                              }, 1000);
                            }
                          }}
                          // onInputChange={(newValue) => {
                          //   searcSubCat(newValue, ProductData?.category_id);
                          // }}
                          onChange={(e) => {
                            setProductData({
                              ...ProductData,
                              sub_category_id: e.value,
                            });

                            setProductDataErr({
                              ...ProductDataErr,
                              sub_category_id: false,
                            });
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                          }}
                          onMenuScrollToBottom={(e) => {
                            if (productSubCategoryArray?.has_more === true) {
                              let p = subCatPage + 1;
                              setsubCatPage(p);
                            }
                          }}
                          styles={customStyles(darkMode)}
                          menuPlacement="auto"
                          menuPosition="absolute"
                        ></Select>
                        {ProductDataErr?.sub_category_id === true ? (
                          <FieldValidationError message="Please select sub category "></FieldValidationError>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field-set" style={{ marginTop: "20px" }}>
                  <div className="field-set-label" ref={DifferentiationRef}>
                    <label>
                      2. Product Differentiation
                      <span className="mendatory ml-1">
                        (Any one field must be filled)
                      </span>
                    </label>
                    {/* <span className="info">
                      Any one must be filled. and All or Multiple can be filled
                      <span className="tooltip-icon">
                        <OverlayTrigger
                          key="mobile-tip"
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-mobile-tip">
                              Any one must be filled. and All or Multiple can be
                              filled
                            </Tooltip>
                          }
                        >
                          <InfoIcon />
                        </OverlayTrigger>
                      </span>
                    </span> */}
                  </div>
                  <div className="row">
                    <div class="col-sm">
                      <div className="form-field">
                        {/* <label className="form-label">Size</label> */}
                        <input
                          type="text"
                          value={ProductData?.size}
                          name="size"
                          id="size"
                          className="form-input"
                          placeholder="Product Size"
                          onChange={(e) => {
                            setProductData({
                              ...ProductData,
                              size: e.target.value,
                            });
                            setProductDataErr({
                              ...ProductDataErr,
                              size: false,
                            });
                          }}
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-sm">
                      <div>
                        {/* <label className="form-label">Colours</label> */}
                        <Select
                          onFocus={(e) => {
                            e.preventDefault();
                            IntialColor();
                          }}
                          placeholder={
                            ProductData?.color?.name
                              ? ProductData?.color?.name
                              : "Select Color"
                          }
                          options={availableColor.map(function (color) {
                            return { value: color.id, label: color.name };
                          })}
                          onChange={(e) => {
                            setProductData((p) => ({
                              ...p,
                              color: {
                                ...ProductData.color,
                                id: e.value,
                              },
                            }));

                            setProductDataErr({
                              ...ProductDataErr,
                              size: false,
                            });
                          }}
                          styles={customStyles(darkMode)}
                        ></Select>
                      </div>
                    </div>
                    <div class="col-sm">
                      <div className="form-field">
                        {/* <label className="form-label">Product Code</label> */}
                        <input
                          type="text"
                          value={ProductData?.product_code}
                          name="pcode"
                          id="pcode"
                          className="form-input"
                          placeholder="Product Code"
                          onChange={(e) => {
                            setProductData({
                              ...ProductData,
                              product_code: e.target.value,
                            });
                            setProductDataErr({
                              ...ProductDataErr,
                              size: false,
                            });
                          }}
                          style={{ borderRadius: "10px" }}
                        />
                      </div>
                    </div>
                    <div class="col-sm">
                      <div className="form-field">
                        {/* <label className="form-label">
                          Storage Capacity/Weight
                        </label> */}
                        <input
                          type="text"
                          value={ProductData?.storage_capacity}
                          name="capacity"
                          id="capacity"
                          className="form-input"
                          placeholder="Weight / Storage"
                          onChange={(e) => {
                            setProductData({
                              ...ProductData,
                              capacity: e.target.value,
                            });
                            setProductDataErr({
                              ...ProductDataErr,
                              size: false,
                            });
                          }}
                          style={{ borderRadius: "10px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm">
                      <div className="form-field">
                        {/* <label className="form-label">Shape</label> */}
                        <input
                          type="text"
                          value={ProductData?.shape}
                          name="pshape"
                          id="pshape"
                          className="form-input"
                          placeholder="Product Shape"
                          onChange={(e) => {
                            setProductData({
                              ...ProductData,
                              shape: e.target.value,
                            });
                            setProductDataErr({
                              ...ProductDataErr,
                              size: false,
                            });
                          }}
                          style={{ borderRadius: "10px" }}
                        />
                      </div>
                    </div>
                    <div class="col-sm">
                      <div className="form-field">
                        {/* <label className="form-label">Material Type </label> */}
                        <input
                          type="text"
                          value={ProductData?.material_type}
                          name="type"
                          id="type"
                          className="form-input"
                          placeholder="Enter Your Material"
                          onChange={(e) => {
                            setProductData({
                              ...ProductData,
                              material_type: e.target.value,
                            });

                            setProductDataErr({
                              ...ProductDataErr,
                              size: false,
                            });
                          }}
                          style={{ borderRadius: "10px" }}
                        />
                      </div>
                    </div>
                    <div class="col-sm">
                      <div className="form-field">
                        {/* <label className="form-label">Brand</label> */}
                        <Select
                          onFocus={(e) => {
                            e.preventDefault();
                            BrandGet(ProductData?.sub_category_id);
                          }}
                          placeholder={
                            ProductData?.brand?.name
                              ? ProductData?.brand?.name
                              : "Select Brand"
                          }
                          options={BrandArray.map(function (productArray) {
                            return {
                              value: productArray.id,
                              label: productArray.name,
                            };
                          })}
                          onKeyDown={(e) => {
                            searcBrand(
                              e.target.value,
                              ProductData?.sub_category_id
                            );
                          }}
                          onChange={(e) => {
                            setProductData((p) => ({
                              ...p,
                              brand_id: e.value,
                              brand: {
                                ...ProductData.brand,
                                id: e.value,
                              },
                            }));
                            setProductData((p) => ({
                              ...p,

                              brand: {
                                ...ProductData.brand,
                                name: e.label,
                              },
                            }));
                            // setBrandArray((oldArray) => [...oldArray,{id: "other",name:"other"}])

                            setProductDataErr({
                              ...ProductDataErr,
                              brand_id: false,
                            });
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                          }}
                          styles={customStyles(darkMode)}
                        ></Select>
                      </div>
                    </div>
                    {ProductData?.brand_id === "other" ? (
                      <div className="col-sm">
                        <div className="form-field">
                          {/* <label className="form-label">
                              Enter Brand Name{" "}
                            </label> */}
                          <input
                            type="text"
                            value={ProductData?.otherBrandName}
                            name="pcode"
                            id="pcode"
                            className="form-input"
                            placeholder="Brand Name "
                            onChange={(e) => {
                              setProductData({
                                ...ProductData,
                                otherBrandName: e.target.value,
                              });
                            }}
                            style={{ borderRadius: "10px" }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {ProductDataErr?.size === true ? (
                    <FieldValidationError message="Please enter atleast one field to complete " />
                  ) : (
                    ""
                  )}
                </div>

                <div className="field-set" style={{ marginTop: "20px" }}>
                  <div className="field-set-label" ref={OtherRef}>
                    <label>
                      3. Mandatory Details <span className="mendatory">*</span>
                    </label>{" "}
                  </div>
                  <div className="row">
                    <div class="col-sm">
                      <div className="form-field">
                        {/* <label className="form-label">
                          Minimum Order Qty<span className="mendatory">*</span>
                        </label> */}
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          value={ProductData?.order_qty}
                          name="orde_qty"
                          id="orde_qty"
                          className="form-input"
                          placeholder="Minimum Order Qty"
                          onChange={(e) => {
                            setProductData({
                              ...ProductData,
                              order_qty: e.target.value,
                            });

                            setProductDataErr({
                              ...ProductDataErr,
                              min_order_qty: false,
                            });
                          }}
                          style={{ borderRadius: "10px" }}
                        />

                        {ProductDataErr?.min_order_qty === true ? (
                          <FieldValidationError message="Please enter minimum order quantity " />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="col-sm">
                      <div>
                        {/* <label className="form-label">
                          Please select Unit<span className="mendatory">*</span>
                        </label> */}

                        <Select
                          placeholder={
                            ProductData?.unit?.name || "Select Unit Of Qty"
                          }
                          options={availableUnit?.items?.map(function (unit) {
                            return { value: unit.id, label: unit.name };
                          })}
                          onFocus={(e) => {
                            e.preventDefault();
                            IntialUnit();
                          }}
                          onChange={(e) => {
                            setProductData({
                              ...ProductData,
                              unit_id: e.value,
                            });
                            setProductDataErr({
                              ...ProductDataErr,
                              unit_id: false,
                            });
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                          }}
                          // onMenuScrollToBottom={(e) =>{
                          //   setUnitPage((prevPageNumber) => prevPageNumber +1)
                          // }}
                          styles={customStyles(darkMode)}
                        ></Select>

                        {ProductDataErr?.unit_id === true ? (
                          <FieldValidationError message="Please select unit" />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm">
                      <div className="form-field">
                        {/* <label className="form-label">
                          Pricing For Advanced Payment
                        </label> */}
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          value={ProductData?.amount}
                          name="advance_payment"
                          id="advance_payment"
                          className="form-input"
                          placeholder="Price for advanced payment"
                          onChange={(e) => {
                            setProductData({
                              ...ProductData,
                              amount: e.target.value,
                            });
                            setProductDataErr({
                              ...ProductDataErr,
                              for_advance_payment: false,
                            });
                          }}
                          style={{ borderRadius: "10px" }}
                        />
                      </div>
                      {ProductDataErr?.for_advance_payment === true ? (
                        <FieldValidationError message="Please add data in the atleast one field" />
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-sm">
                      <div className="form-field">
                        {/* <label className="form-label">
                          Price for Credit Payment
                        </label> */}
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          value={ProductData?.credit_amount}
                          name="credit_payment"
                          id="credit_payment"
                          className="form-input"
                          placeholder="Price for credit payment"
                          onChange={(e) => {
                            setProductData({
                              ...ProductData,
                              credit_amount: e.target.value,
                            });
                            setProductDataErr({
                              ...ProductDataErr,
                              for_advance_payment: false,
                            });
                          }}
                          style={{ borderRadius: "10px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm">
                      <div className="form-field">
                        {/* <label className="form-label">HSN Code</label> */}
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          value={ProductData?.hsn_code}
                          name="hsn"
                          id="hsn"
                          className="form-input"
                          placeholder="HSN Code"
                          onChange={(e) => {
                            setProductData({
                              ...ProductData,
                              hsn_code: e.target.value,
                            });

                            setProductDataErr({
                              ...ProductDataErr,
                              hsn_code: false,
                            });
                          }}
                          style={{ borderRadius: "10px" }}
                        />
                        {ProductDataErr?.hsn_code === true ? (
                          <FieldValidationError message="Please enter valid HSN code" />
                        ) : (
                          ""
                        )}
                        {ProductData?.hsn_code.length !== 8 ? (
                          <FieldValidationError message="Please enter 8 digit HSN code" />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div class="col-sm">
                      <div className="form-field">
                        {/* <label className="form-label">GST Rate</label> */}
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min={0}
                          max={100}
                          value={ProductData?.gst_rate}
                          name="gst"
                          id="gst"
                          className="form-input"
                          placeholder="GST Rate %"
                          onChange={(e) => {
                            if (
                              !(e.target.value >= 0 && e.target.value <= 100)
                            ) {
                              return false;
                            }
                            setProductData({
                              ...ProductData,
                              gst_rate: e.target.value,
                            });
                            setProductDataErr({
                              ...ProductDataErr,
                              gst_rate: false,
                            });
                          }}
                          style={{ borderRadius: "10px" }}
                        />
                        {ProductDataErr?.gst_rate === true ? (
                          <FieldValidationError message="Please enter valid GST rate" />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="field-set" style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "5px",
                      }}
                      className="field-set-label"
                      ref={PricingRef}
                    >
                      <a
                        className="collapse-button collapsed"
                        data-toggle="collapse"
                        href="#collap_Others"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collap_Others"
                      >
                        {/* <img
                          style={{ width: "16px", height: "16px" }}
                          src={ArrowDownIcon}
                        /> */}
                        <IconArrow />
                      </a>
                      <label>
                        4. Optional Details{" "}
                        <span className="mendatory ml-1">
                          (All fields are optional)
                        </span>
                      </label>
                      {/* <span className="info">
                        Any one must be filled
                        <span className="tooltip-icon">
                          <OverlayTrigger
                            key="mobile-tip"
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-mobile-tip">
                                Any one must be filled
                              </Tooltip>
                            }
                          >
                            <InfoIcon />
                          </OverlayTrigger>
                        </span>
                      </span> */}
                    </div>
                    <div className="collapse" id="collap_Others">
                      <div className="row">
                        <div class="col-sm">
                          <div className="form-field">
                            {/* <label className="form-label">
                              Delivery Within State
                            </label> */}
                            <input
                              type="number"
                              value={
                                ProductData?.delivery_details
                                  ?.delivery_within_state
                              }
                              name="within_state"
                              id="within_state"
                              className="form-input"
                              placeholder="Delivery lead time within state (In Days)"
                              onChange={(e) => {
                                setProductData((p) => ({
                                  ...p,

                                  delivery_details: {
                                    ...ProductData.delivery_details,
                                    delivery_within_state: e.target.value,
                                  },
                                }));
                                setProductDataErr({
                                  ...ProductDataErr,
                                  delivery_within_state: false,
                                });
                              }}
                              style={{ borderRadius: "10px" }}
                            />
                            {ProductDataErr?.delivery_within_state === true ? (
                              <FieldValidationError message="Please enter delivery time within state " />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div class="col-sm">
                          <div className="form-field">
                            {/* <label className="form-label">
                              Delivery Outside State
                            </label> */}
                            <input
                              type="number"
                              value={
                                ProductData?.delivery_details
                                  ?.delivery_outside_state
                              }
                              name="outside_state"
                              id="outside_state"
                              className="form-input"
                              placeholder="Delivery lead time outside state (In Days)"
                              onChange={(e) => {
                                setProductData((p) => ({
                                  ...p,
                                  delivery_details: {
                                    ...ProductData.delivery_details,
                                    delivery_outside_state: e.target.value,
                                  },
                                }));
                                setProductDataErr({
                                  ...ProductDataErr,
                                  delivery_outside_state: false,
                                });
                              }}
                              style={{ borderRadius: "10px" }}
                            />
                            {ProductDataErr?.delivery_outside_state === true ? (
                              <FieldValidationError message="Please enter delivery time outside state " />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div class="col-sm">
                          <div className="form-field">
                            {/* <label className="form-label">Description</label> */}
                            {/* <Editor
                              apiKey="5ti6dhxcp0v40nnj6xwi874c19ixiq2qtqucc2zlvfxxxs8j"
                              value={ProductData?.description}
                              init={{
                                height: 200,
                                menubar: false,
                              }}
                              onEditorChange={(content, editor) => {
                                setProductData({
                                  ...ProductData,
                                  description: content,
                                });
                                setProductDataErr({
                                  ...ProductDataErr,
                                  description: false,
                                });
                              }}
                            /> */}
                            <textarea
                              style={{
                                borderRadius: "10px",
                                backgroundColor: darkMode ? "#1c2226" : "#fff",
                              }}
                              value={ProductData?.description}
                              name="description"
                              id="description"
                              className="form-control"
                              onChange={(e) => {
                                setProductData({
                                  ...ProductData,
                                  description: e.target.value,
                                });
                                setProductDataErr({
                                  ...ProductDataErr,
                                  description: false,
                                });
                              }}
                              placeholder="Other description (150 words max)"
                              rows={2}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div class="col-sm">
                          <div className="form-field">
                            {/* <label className="form-label">
                          Certificate if Any{" "}
                          <span className="mendatory"> * Max 2MB Allowed</span>
                        </label> */}
                            <div className="file-upload">
                              {/* <input type='file' id='upload_certificate' /> */}
                              {/* {ProductData?.certificate?.name ? (
                          <>
                            <input
                              id="upload-certificate"
                              type="file"
                              placeholder="Upload Newer Image"
                              onChange={(e) =>{
                                if(e.target.files[0].size > 2097152)
                                {
                                  alert("Not More than 2 MB is allowded")
                                  return false;
                                }
                                setProductData({...ProductData,certificate:e.target.files[0]})
                              }}
                            />{" "}
                            <label htmlFor="upload_certificate">
                              {ProductData?.certificate?.name}
                              <span className="icon">
                                <AttachmentIcon />
                              </span>
                            </label>
                          </>
                        ) : (
                          <> */}
                              <input
                                type="file"
                                id="upload_certificate"
                                accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf,application/msword"
                                onChange={(e) => {
                                  if (
                                    !(
                                      e.target.files[0].type === "image/png" ||
                                      e.target.files[0].type === "image/jpg" ||
                                      e.target.files[0].type === "image/jpeg" ||
                                      e.target.files[0].type === "image/gif" ||
                                      e.target.files[0].type ===
                                        "application/pdf" ||
                                      e.target.files[0].type ===
                                        "application/msword" ||
                                      e.target.files[0].type ===
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    )
                                  ) {
                                    alert("Only images,pdf,doc,docx allowded");
                                    return false;
                                  }
                                  if (e.target.files[0].size > 2097152) {
                                    alert("Not More than 2 MB is allowded");
                                    return false;
                                  }
                                  setProductData({
                                    ...ProductData,
                                    certificates: e.target.files[0],
                                  });
                                }}
                                style={{ borderRadius: "10px" }}
                              />
                              <label
                                style={{ borderRadius: "10px" }}
                                htmlFor="upload_certificate"
                              >
                                {ProductData?.certificates?.name
                                  ? ProductData?.certificates?.name
                                  : "Certificate if Any"}
                                <span className="icon">
                                  <AttachmentIcon />
                                </span>
                              </label>

                              {/*    </>)} */}
                            </div>

                            {ProductData?.certificate != "" ? (
                              <a
                                href={ProductData?.certificate}
                                target="_blank"
                              >
                                {" "}
                                Uploaded Certificate{" "}
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div class="col-sm ">
                          <div className="form-field">
                            {/* <label className="form-label">
                              Product Catalogue
                            </label> */}
                            <div className="file-upload">
                              <input
                                type="file"
                                id="upload_certificate2"
                                accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf,application/msword"
                                onChange={(e) => {
                                  if (
                                    !(
                                      e.target.files[0].type === "image/png" ||
                                      e.target.files[0].type === "image/jpg" ||
                                      e.target.files[0].type === "image/jpeg" ||
                                      e.target.files[0].type === "image/gif" ||
                                      e.target.files[0].type ===
                                        "application/pdf" ||
                                      e.target.files[0].type ===
                                        "application/msword" ||
                                      e.target.files[0].type ===
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    )
                                  ) {
                                    alert("Only images,pdf,doc,docx allowded");
                                    return false;
                                  }
                                  if (e.target.files[0].size > 2097152) {
                                    alert("Not More than 2 MB is allowded");
                                    return false;
                                  }

                                  setProductData((p) => ({
                                    ...p,
                                    product_catalogue: e.target.files[0],
                                  }));
                                }}
                                style={{ borderRadius: "10px" }}
                              />
                              <label
                                style={{ borderRadius: "10px" }}
                                htmlFor="upload_certificate2"
                              >
                                {ProductData?.product_catalogue?.name
                                  ? ProductData?.product_catalogue?.name
                                  : "Product Catalogue"}
                                <span className="icon">
                                  <AttachmentIcon />
                                </span>
                              </label>
                            </div>

                            {ProductData?.catalogue != "" ? (
                              <a href={ProductData?.catalogue} target="_blank">
                                {" "}
                                Uploaded Catelogue{" "}
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="field-set">
                  <div className="field-set-label" ref={TaxRef}>
                    <label>
                      Tax Details<span className="mendatory">*</span>
                    </label>
                    <span className="info">
                      Both must be filled
                      <span className="tooltip-icon">
                        <OverlayTrigger
                          key="mobile-tip"
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-mobile-tip">
                              Both must be filled
                            </Tooltip>
                          }
                        >
                          <InfoIcon />
                        </OverlayTrigger>
                      </span>
                    </span>
                  </div>
                  <div className="row"></div>
                </div> */}

                <div className="field-set" style={{ marginTop: "20px" }}>
                  <div className="field-set-label" ref={DeliveryRef}>
                    <label>5. Select your post template</label>
                  </div>
                  <div className="row">
                    <div class="col-sm ">
                      <div>
                        {/* <label className="form-label">
                          Post Template <span className="mendatory">*</span>{" "}
                        </label> */}

                        <Select
                          placeholder={
                            ProductData?.post_template?.content
                              ? ProductData?.post_template?.content
                              : "Select Post Template "
                          }
                          id="Template "
                          onFocus={(e) => {
                            e.preventDefault();
                            TemplateFunction();
                          }}
                          options={templateArray.map(function (productArray) {
                            return {
                              value: productArray.id,
                              label: productArray.content,
                            };
                          })}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          onChange={(e) => {
                            setProductData((p) => ({
                              ...p,

                              post_template: {
                                ...ProductData.post_template,
                                content: e.label,
                              },
                            }));
                            setProductData((p) => ({
                              ...p,

                              post_template: {
                                ...ProductData.post_template,
                                id: e.value,
                              },
                            }));

                            setProductDataErr({
                              ...ProductDataErr,
                              template: false,
                            });
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                          }}
                          styles={{
                            ...customStyles(darkMode),
                            menu: (provided) => ({
                              ...provided,
                              borderRadius: "8px", // Rounded corners for the dropdown
                              marginTop: "0px", // No margin between the input and the dropdown
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow for the dropdown
                              maxHeight: "300px", // Fixed height for the dropdown
                              overflowY: "auto",
                              background: darkMode ? "#1C2226" : "#fff", // Background color for dropdown in dark mode
                              color: darkMode ? "#fff" : "#000",
                            }),
                          }}
                        ></Select>
                        {ProductDataErr?.template === true ? (
                          <FieldValidationError message="Please select post template "></FieldValidationError>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field-set" style={{ marginTop: "20px" }}>
                  <div className="field-set-label">
                    <label>
                      6. Attach Product Images{" "}
                      <span className="mendatory ml-1">
                        (Mininum One-Maximun 6 Images to Upload)
                      </span>
                    </label>
                  </div>
                  {/* <div className="field-set-label">
                    <div
                      className="card-header card-header--large"
                      style={{ borderBottom: "none" }}
                    >
                      <label
                        style={{ fontSize: "1.1904761905vw" }}
                        ref={imagesRef}
                      >
                        Upload Product Images
                      </label>
                      <button
                        className="button-edit"
                        style={{ marginLeft: "0.5vw" }}
                        data-toggle="modal"
                        data-target="#UpdateProductModal"
                      >
                        <ButtonEditIcon />
                      </button>
                      <span
                        className="mendatory"
                        style={{ color: "red", "font-weight": "bolder" }}
                      >
                        {" "}
                        * Max 2MB Allowed
                      </span>
                      &nbsp;
                      <span style={{ color: "red" }}>
                        (Upload image size should be 400*400)
                      </span>
                    </div>
                    <span className="info">
                      Min-1 and Max 6
                      <span className="tooltip-icon">
                        <OverlayTrigger
                          key="mobile-tip"
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-mobile-tip">
                              Min-1 and Max 6
                            </Tooltip>
                          }
                        >
                          <InfoIcon />
                        </OverlayTrigger>
                      </span>
                    </span>
                  </div> */}
                  <div className="row" style={{ marginTop: "20px" }}>
                    <div class="col-sm">
                      <div className="form-field">
                        <div className="file-upload upload-prod-image">
                          <input
                            type="file"
                            accept="image/*"
                            id="upload_prod_img1"
                            onChange={(e) => {
                              if (
                                !(
                                  e.target.files[0].type === "image/png" ||
                                  e.target.files[0].type === "image/jpg" ||
                                  e.target.files[0].type === "image/jpeg" ||
                                  e.target.files[0].type === "image/gif" ||
                                  e.target.files[0].type === "image/svg"
                                )
                              ) {
                                alert("Only images allowed");
                                return false;
                              }
                              if (e.target.files[0].size > 2097152) {
                                alert("Not More than 2 MB is allowded");
                                return false;
                              }
                              setProductData({
                                ...ProductData,
                                Front_Image: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                              });
                              setProductDataErr({
                                ...ProductDataErr,
                                Front_Image: false,
                              });
                            }}
                          />

                          {!ProductData?.Front_Image ? (
                            <label htmlFor="upload_prod_img1">
                              <img
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  padding: "15px",
                                }}
                                src={ProductImagePlaceholder}
                              />
                              <div className="image-type">Front</div>
                            </label>
                          ) : (
                            <>
                              <label>
                                <img
                                  src={ProductData.Front_Image}
                                  // onImageLoaded={setImage}
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                  }}
                                />

                                <img
                                  src={Cancel}
                                  className="cancel-button"
                                  style={{ height: "20px", width: "20px" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setProductData({
                                      ...ProductData,
                                      Front_Image: "",
                                    });
                                  }}
                                />

                                <div className="image-type">Front</div>

                                <span
                                  onClick={(e) => {
                                    setShow(true);

                                    setCroppedImages((p) => ({
                                      ...p,
                                      Front_Image: true,
                                    }));
                                  }}
                                >
                                  <EditIcon />
                                </span>
                              </label>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="col-sm">
                      <div className="form-field">
                        <div className="file-upload upload-prod-image">
                          <input
                            type="file"
                            accept="image/*"
                            id="upload_prod_img2"
                            onChange={(e) => {
                              if (
                                !(
                                  e.target.files[0].type === "image/png" ||
                                  e.target.files[0].type === "image/jpg" ||
                                  e.target.files[0].type === "image/jpeg" ||
                                  e.target.files[0].type === "image/gif" ||
                                  e.target.files[0].type === "image/svg"
                                )
                              ) {
                                alert("Only images allowed");
                                return false;
                              }
                              if (e.target.files[0].size > 2097152) {
                                alert("Not More than 2 MB is allowded");
                                return false;
                              }
                              setProductData({
                                ...ProductData,
                                Back_Image: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                              });
                              setProductDataErr({
                                ...ProductDataErr,
                                Front_Image: false,
                              });
                            }}
                          />

                          {!ProductData?.Back_Image ? (
                            <label htmlFor="upload_prod_img2">
                              <img
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  padding: "15px",
                                }}
                                src={ProductImagePlaceholder}
                              />
                              <div className="image-type">Back</div>
                            </label>
                          ) : (
                            <label>
                              <img
                                src={ProductData?.Back_Image}
                                style={{
                                  width: "80px",
                                  height: "80px",
                                }}
                                // onImageLoaded={setImage}
                              />

                              <img
                                src={Cancel}
                                className="cancel-button"
                                style={{ height: "20px", width: "20px" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setProductData({
                                    ...ProductData,
                                    Back_Image: "",
                                  });
                                }}
                              />
                              {/* <button
                              className="cancel-button"
                              onClick={(e) => {
                                e.preventDefault();
                                setProductData({
                                  ...ProductData,
                                  Front_Image: "",
                                });
                              }}
                            >
                              <img src={Cancel} />
                            </button> */}
                              <div className="image-type">Back</div>

                              <span
                                onClick={(e) => {
                                  setShow(true);
                                  setCroppedImages((p) => ({
                                    ...p,
                                    Back_Image: true,
                                  }));
                                }}
                              >
                                <EditIcon />
                              </span>
                            </label>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="col-sm">
                      <div className="form-field">
                        <div className="file-upload upload-prod-image">
                          <input
                            type="file"
                            accept="image/*"
                            id="upload_prod_img3"
                            onChange={(e) => {
                              if (
                                !(
                                  e.target.files[0].type === "image/png" ||
                                  e.target.files[0].type === "image/jpg" ||
                                  e.target.files[0].type === "image/jpeg" ||
                                  e.target.files[0].type === "image/gif" ||
                                  e.target.files[0].type === "image/svg"
                                )
                              ) {
                                alert("Only images allowed");
                                return false;
                              }
                              if (e.target.files[0].size > 2097152) {
                                alert("Not More than 2 MB is allowded");
                                return false;
                              }
                              setProductData({
                                ...ProductData,
                                Left_Image: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                              });
                              setProductDataErr({
                                ...ProductDataErr,
                                Front_Image: false,
                              });
                            }}
                          />

                          {!ProductData?.Left_Image ? (
                            <label htmlFor="upload_prod_img3">
                              <img
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  padding: "15px",
                                }}
                                src={ProductImagePlaceholder}
                              />
                              <div className="image-type">Left</div>
                            </label>
                          ) : (
                            <label>
                              <img
                                style={{
                                  width: "80px",
                                  height: "80px",
                                }}
                                src={ProductData?.Left_Image}
                                // onImageLoaded={setImage}
                              />

                              <img
                                src={Cancel}
                                className="cancel-button"
                                style={{ height: "20px", width: "20px" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setProductData({
                                    ...ProductData,
                                    Left_Image: "",
                                  });
                                }}
                              />
                              {/* <button
                              className="cancel-button"
                              onClick={(e) => {
                                e.preventDefault();
                                setProductData({
                                  ...ProductData,
                                  Front_Image: "",
                                });
                              }}
                            >
                              <img src={Cancel} />
                            </button> */}
                              <div className="image-type">Left</div>

                              <span
                                onClick={(e) => {
                                  setShow(true);
                                  setCroppedImages((p) => ({
                                    ...p,
                                    Left_Image: true,
                                  }));
                                }}
                              >
                                <EditIcon />
                              </span>
                            </label>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="col-sm">
                      <div className="form-field">
                        <div className="file-upload upload-prod-image">
                          <input
                            type="file"
                            accept="image/*"
                            id="upload_prod_img4"
                            onChange={(e) => {
                              if (
                                !(
                                  e.target.files[0].type === "image/png" ||
                                  e.target.files[0].type === "image/jpg" ||
                                  e.target.files[0].type === "image/jpeg" ||
                                  e.target.files[0].type === "image/gif" ||
                                  e.target.files[0].type === "image/svg"
                                )
                              ) {
                                alert("Only images allowed");
                                return false;
                              }
                              if (e.target.files[0].size > 2097152) {
                                alert("Not More than 2 MB is allowded");
                                return false;
                              }
                              setProductData({
                                ...ProductData,
                                Right_Image: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                              });
                              setProductDataErr({
                                ...ProductDataErr,
                                Front_Image: false,
                              });
                            }}
                          />

                          {!ProductData?.Right_Image ? (
                            <label htmlFor="upload_prod_img4">
                              <img
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  padding: "15px",
                                }}
                                src={ProductImagePlaceholder}
                              />
                              <div className="image-type">Right</div>
                            </label>
                          ) : (
                            <label>
                              <img
                                style={{
                                  width: "80px",
                                  height: "80px",
                                }}
                                src={ProductData?.Right_Image}
                                // onImageLoaded={setImage}
                              />

                              <img
                                src={Cancel}
                                className="cancel-button"
                                style={{ height: "20px", width: "20px" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setProductData({
                                    ...ProductData,
                                    Right_Image: "",
                                  });
                                }}
                              />

                              {/* <button
                              className="cancel-button"
                              onClick={(e) => {
                                e.preventDefault();
                                setProductData({
                                  ...ProductData,
                                  Front_Image: "",
                                });
                              }}
                            >
                              <img src={Cancel} />
                            </button> */}
                              <div className="image-type">Right</div>

                              <span
                                onClick={(e) => {
                                  setShow(true);
                                  setCroppedImages((p) => ({
                                    ...p,
                                    Right_Image: true,
                                  }));
                                }}
                              >
                                <EditIcon />
                              </span>
                            </label>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="col-sm">
                      <div className="form-field">
                        <div className="file-upload upload-prod-image">
                          <input
                            type="file"
                            accept="image/*"
                            id="upload_prod_img5"
                            onChange={(e) => {
                              if (
                                !(
                                  e.target.files[0].type === "image/png" ||
                                  e.target.files[0].type === "image/jpg" ||
                                  e.target.files[0].type === "image/jpeg" ||
                                  e.target.files[0].type === "image/gif" ||
                                  e.target.files[0].type === "image/svg"
                                )
                              ) {
                                alert("Only images allowed");
                                return false;
                              }
                              if (e.target.files[0].size > 2097152) {
                                alert("Not More than 2 MB is allowded");
                                return false;
                              }
                              setProductData({
                                ...ProductData,
                                Upper_Image: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                              });
                              setProductDataErr({
                                ...ProductDataErr,
                                Front_Image: false,
                              });
                            }}
                          />

                          {!ProductData?.Upper_Image ? (
                            <label htmlFor="upload_prod_img5">
                              <img
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  padding: "15px",
                                }}
                                src={ProductImagePlaceholder}
                              />
                              <div className="image-type">Upper</div>
                            </label>
                          ) : (
                            <label>
                              <img
                                src={ProductData?.Upper_Image}
                                // onImageLoaded={setImage}
                                style={{
                                  width: "80px",
                                  height: "80px",
                                }}
                              />

                              <img
                                src={Cancel}
                                className="cancel-button"
                                style={{ height: "20px", width: "20px" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setProductData({
                                    ...ProductData,
                                    Upper_Image: "",
                                  });
                                }}
                              />
                              {/* <button
                              className="cancel-button"
                              onClick={(e) => {
                                e.preventDefault();
                                setProductData({
                                  ...ProductData,
                                  Front_Image: "",
                                });
                              }}
                            >
                              <img src={Cancel} />
                            </button> */}
                              <div className="image-type">Upper</div>

                              <span
                                onClick={(e) => {
                                  setShow(true);
                                  setCroppedImages((p) => ({
                                    ...p,
                                    Upper_Image: true,
                                  }));
                                }}
                              >
                                <EditIcon />
                              </span>
                            </label>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="col-sm">
                      <div className="form-field">
                        <div className="file-upload upload-prod-image">
                          <input
                            type="file"
                            accept="image/*"
                            id="upload_prod_img6"
                            onChange={(e) => {
                              if (
                                !(
                                  e.target.files[0].type === "image/png" ||
                                  e.target.files[0].type === "image/jpg" ||
                                  e.target.files[0].type === "image/jpeg" ||
                                  e.target.files[0].type === "image/gif" ||
                                  e.target.files[0].type === "image/svg"
                                )
                              ) {
                                alert("Only images allowed");
                                return false;
                              }
                              if (e.target.files[0].size > 2097152) {
                                alert("Not More than 2 MB is allowded");
                                return false;
                              }
                              setProductData({
                                ...ProductData,
                                Lower_Image: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                              });
                              setProductDataErr({
                                ...ProductDataErr,
                                Front_Image: false,
                              });
                            }}
                          />

                          {!ProductData?.Lower_Image ? (
                            <label htmlFor="upload_prod_img6">
                              <img
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  padding: "15px",
                                }}
                                src={ProductImagePlaceholder}
                              />
                              <div className="image-type">Lower</div>
                            </label>
                          ) : (
                            <label>
                              <img
                                style={{
                                  width: "80px",
                                  height: "80px",
                                }}
                                src={ProductData?.Lower_Image}
                                // onImageLoaded={setImage}
                              />

                              <img
                                src={Cancel}
                                className="cancel-button"
                                style={{ height: "20px", width: "20px" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setProductData({
                                    ...ProductData,
                                    Lower_Image: "",
                                  });
                                }}
                              />

                              {/* <button
                              className="cancel-button"
                              onClick={(e) => {
                                e.preventDefault();
                                setProductData({
                                  ...ProductData,
                                  Front_Image: "",
                                });
                              }}
                            >
                              <img src={Cancel} />
                            </button> */}
                              <div className="image-type">Lower</div>

                              <span
                                onClick={(e) => {
                                  setShow(true);
                                  setCroppedImages((p) => ({
                                    ...p,
                                    Lower_Image: true,
                                  }));
                                }}
                              >
                                <EditIcon />
                              </span>
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    {ProductDataErr?.Front_Image === true ? (
                      <FieldValidationError message="Please add atleast one image"></FieldValidationError>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {ProductData?.type === "post_to_sell" ? (
                  <>
                    <div
                      className="row protfolio-boxes"
                      style={{ marginTop: "20px" }}
                    >
                      <div class="col-sm">
                        <div className="chips chips--white">
                          <div className="chips--text">Min. Order Value</div>
                          <div className="chips--value">
                            {ProductData?.min_order_value} INR
                          </div>
                          <button
                            className="button-edit"
                            data-toggle="modal"
                            data-target="#MinimumOrderModal"
                            onClick={(e) => {
                              e.preventDefault();
                              // GetCompanydDetails();
                            }}
                          >
                            <EditIcon />
                          </button>
                        </div>
                      </div>
                      <div class="col-sm">
                        <div className="chips chips--white">
                          <div className="chips--text">Freight Charges</div>
                          <div className="chips--value">
                            {ProductData?.freight_charges}%
                          </div>
                          <button
                            className="button-edit"
                            data-toggle="modal"
                            data-target="#FreightChargesModal"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <EditIcon />
                          </button>
                        </div>
                      </div>
                      <div
                        class="col-sm"
                        data-toggle="modal"
                        data-target="#CreditPolicyModal"
                        onClick={(e) => {
                          e.preventDefault();
                          // GetCompanydDetails();
                        }}
                      >
                        <div className="chips chips--white">
                          <div className="chips--text">
                            Credit Policy
                            <CompleteIcon className="complete" />
                          </div>
                          <div className="chips--value">
                            <span className="text-small">
                              <InfoIcon />
                              Info
                            </span>
                          </div>
                          {/* <button className='button-edit'><EditIcon /></button> */}
                        </div>
                      </div>
                      <div class="col-sm">
                        <div className="chips chips--white">
                          <div className="chips--text">Negotiation</div>
                          <div className="chips--value">
                            <div className="switch-box switch-box--withLabel switch-box--small">
                              <input
                                type="checkbox"
                                id="negotiation_switch"
                                checked={
                                  ProductData?.negotiation == 1 ? true : false
                                }
                                onChange={(e) => {
                                  if (ProductData?.negotiation === 0) {
                                    setProductData((p) => ({
                                      ...p,
                                      negotiation: 1,
                                    }));
                                  }
                                  if (ProductData?.negotiation === 1) {
                                    setProductData((p) => ({
                                      ...p,
                                      negotiation: 0,
                                    }));
                                  }
                                }}
                              />
                              <label htmlFor="negotiation_switch">
                                <span className="switch-icon off">OFF</span>
                                <span className="switch-icon on">ON</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm">
                        <div className="chips chips--white">
                          <div className="chips--text">Post Hide From</div>
                          <div className="chips--value">
                            <span className="color-red">{resti}</span> Users
                          </div>
                          <button
                            className="button-edit"
                            data-toggle="modal"
                            data-target="#PostHideModal"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <EditIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="form-button">
                  <button
                    className="button button-primary"
                    onClick={(e) => {
                      handleProductCreate(e);
                    }}
                    style={{ borderRadius: "5px" }}
                  >
                    {Loading === true ? (
                      <PuffLoader size={15} />
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Title>
            <h3>Crop Your Image</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div
              className="col-lg-12 col-md-12 col-12"
              style={{ height: "60vh" }}
            >
              <Cropper
                image={
                  croppedImages?.Front_Image === true
                    ? ProductData?.Front_Image
                    : croppedImages?.Back_Image === true
                    ? ProductData?.Back_Image
                    : croppedImages?.Left_Image === true
                    ? ProductData?.Left_Image
                    : croppedImages?.Right_Image === true
                    ? ProductData?.Right_Image
                    : croppedImages?.Upper_Image === true
                    ? ProductData?.Upper_Image
                    : croppedImages?.Lower_Image === true
                    ? ProductData?.Lower_Image
                    : ""
                }
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                zoomSpeed={4}
                maxZoom={3}
                zoomWithScroll={true}
                showGrid={true}
                aspect={4 / 3}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
              />
            </div>
          </div>
          {/* <button
            style={{
              display: "block",
            }}
            onClick={showCroppedImage}
          >
            Preview
          </button>
          <div className="cropped-image-container">
            {croppedImage && (
              <img className="cropped-image" src={croppedImage} alt="cropped" />
            )}
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              if (croppedImages?.Front_Image === true) {
                setCroppedImages((p) => ({ ...p, Front_Image: false }));
              }

              if (croppedImages?.Back_Image === true) {
                setCroppedImages((p) => ({ ...p, Back_Image: false }));
              }

              if (croppedImages?.Left_Image === true) {
                setCroppedImages((p) => ({ ...p, Left_Image: false }));
              }
              if (croppedImages?.Lower_Image === true) {
                setCroppedImages((p) => ({ ...p, Lower_Image: false }));
              }
              if (croppedImages?.Right_Image === true) {
                setCroppedImages((p) => ({ ...p, Right_Image: false }));
              }
              if (croppedImages?.Upper_Image === true) {
                setCroppedImages((p) => ({ ...p, Upper_Image: false }));
              }
              showCroppedImage();
              handleClose();
            }}
          >
            Save{" "}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <PostHideFrom
        postHideArray={postHideArray}
        edit={true}
        id={ProductData?.id}
      />

      <div
        className="modal fade modal-small"
        id="MinimumOrderModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="MinimumOrderModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Minimum Order Value
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-field">
                <label className="form-label">Enter Minimum Amount</label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="minOrder"
                  className="form-input"
                  placeholder="Enter Amount"
                  value={ProductData?.min_order_value}
                  onChange={(e) => {
                    setProductData((p) => ({
                      ...p,
                      min_order_value: e.target.value,
                    }));
                    setProductDataErr((p) => ({
                      ...p,
                      min_order_value: false,
                    }));
                  }}
                />
                {ProductDataErr?.min_order_value === true ? (
                  <FieldValidationError message="Please Enter Valid Minimum order value " />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="button button-primary"
                onClick={(e) => {
                  e.preventDefault();
                }}
                data-dismiss="modal"
                aria-label="Close"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade modal-small"
        id="FreightChargesModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="FreightChargesModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Freight Charges
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-field">
                <label className="form-label">Enter Freight Charges</label>
                <div className="row">
                  <div className="col-sm">
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      name="minOrder"
                      value={ProductData?.freight_charges}
                      className="form-input"
                      placeholder="Enter Freight Charges in Percentage "
                      onChange={(e) => {
                        if (!(e.target.value >= 0 && e.target.value <= 100)) {
                          return false;
                        }
                        setProductData({
                          ...ProductData,
                          freight_charges: e.target.value,
                        });
                      }}
                    />
                    {ProductDataErr?.freight_charges === true ? (
                      <FieldValidationError message="Please Enter Valid Freight Charges" />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-3">
                    <select className="form-input">
                      <option>%</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="button button-primary"
                data-dismiss="modal"
                aria-label="Close"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditPost;
