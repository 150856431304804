import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import { getAuthConfig, post } from "../libs/http-hydrate";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import FieldValidationError from "../components/error-messages/field-validation-error";
import { PuffLoader } from "react-spinners";

const EWayBillModal = ({ id, updateData, vehicleNum }) => {
  const CloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [disableType, setDisableType] = useState(false);

  const [vehicleNumber, setVehicleNumber] = useState(vehicleNum || "");

  const [ewayBillData, setEwayBillData] = useState({
    transporter_id: "",
    transportation_mode: "",
    vehicle_type: "",
    approx_distance: "",
    referenceNumber: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEwayBillData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleVehicleNumber = (e) => {
    setVehicleNumber(e.target.value);
  };

  const generateBill = async () => {
    const formdata = new FormData();
    formdata.append("order_id", id);
    formdata.append("transporter_id", ewayBillData.transporter_id);
    formdata.append("transportation_mode", ewayBillData.transportation_mode);
    formdata.append("vehicle_type", ewayBillData.vehicle_type);
    formdata.append("vehicle_number", vehicleNumber);
    formdata.append("approx_distance", ewayBillData.approx_distance);
    formdata.append("generate_e_way_bill", 1);

    setLoading(true);
    try {
      const res = await post(
        "/short_order/update/e-way-bill",
        formdata,
        getAuthConfig()
      );
      setLoading(false);
      if (res?.status === 200) {
        CloseRef.current.click();

        setTimeout(() => {
          navigate("/product-material-dispatch", { state: { id: id } });
        }, 2000);
        // navigate("/product-e-bill", {
        //   state: { id: id },
        // });
      }
      updateData();
    } catch (error) {
      setLoading(false);
      CloseRef.current.click();
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    // Set the initial value of the vehicleNumber state
    setVehicleNumber(vehicleNum || "");
  }, [vehicleNum]);

  useEffect(() => {
    if (ewayBillData?.transportation_mode !== "Road") {
      setEwayBillData((prevState) => ({
        ...prevState,
        vehicle_type: "",
      }));
      setDisableType(true);
    } else {
      setEwayBillData((prevState) => ({
        ...prevState,
        vehicle_type: "",
      }));
      setDisableType(false);
    }
  }, [ewayBillData?.transportation_mode]);

  return (
    <>
      <ToastContainer />

      <div
        className="modal fade post-social-modal"
        id="e-waybillModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="e-waybillModal"
        aria-hidden="true"
        data-backdrop="static" // Add this line to disable closing on click outside
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                E-Way Bill Details
              </h5>
              <button
                type="button"
                disabled={loading ? true : false}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={CloseRef}
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <div className="form-field">
                    <label className="form-label">Transportation Mode</label>
                    <select
                      name="transportation_mode"
                      value={ewayBillData?.transportation_mode}
                      onChange={handleChange}
                      className="form-input"
                      required
                    >
                      <option value={""}>Select Transportation Mode</option>
                      <option value={"Road"}>Road</option>
                      <option value={"Rail"}>Rail</option>
                      <option value={"Air"}>Air</option>
                      <option value={"Ship"}>Ship</option>
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form-field">
                    <label className="form-label">Transporter ID</label>
                    <input
                      type="text"
                      maxLength={15}
                      name="transporter_id"
                      id="transporter_id"
                      className="form-input"
                      placeholder="Transporter ID"
                      value={ewayBillData?.transporter_id}
                      onChange={handleChange}
                    />
                    {ewayBillData?.transporter_id?.length &&
                    ewayBillData?.transporter_id?.length !== 15 ? (
                      <FieldValidationError message="Minimum 15 characters are required" />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-field">
                    <label className="form-label">
                      Transportation Vehicle Type
                    </label>

                    <select
                      name="vehicle_type"
                      className="form-input"
                      required
                      onChange={handleChange}
                      value={ewayBillData?.vehicle_type}
                      disabled={
                        !ewayBillData?.transportation_mode ? true : false
                      }
                    >
                      <option>Select Vehicle Type</option>
                      <option
                        disabled={ewayBillData?.transportation_mode !== "Road"}
                        value={"ODC"}
                      >
                        ODC
                      </option>
                      <option value={"Regular"}>Regular</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-field">
                    <label className="form-label">
                      Vehicle Number (GJ01BL4567)
                    </label>
                    <input
                      type="text"
                      name="vehicle_number"
                      id="vehicle_number"
                      className="form-input"
                      placeholder="Vehicle Number"
                      value={vehicleNumber}
                      onChange={handleVehicleNumber}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-field">
                    <label className="form-label">
                      Approx Distance (Max 4000 KM)
                    </label>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      name="approx_distance"
                      id="approx_distance"
                      className="form-input"
                      placeholder="Approx Distance"
                      value={ewayBillData.approx_distance}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer text-center">
              {loading === false ? (
                <button
                  className="button button-primary"
                  onClick={generateBill}
                  disabled={loading ? true : false}
                >
                  Generate E-Way Bill
                </button>
              ) : (
                <button className="button button-primary">
                  <div className="text-center">
                    <PuffLoader size={25} />
                  </div>
                </button>
              )}

              {loading && (
                <div className="text-center text-success text-bold">
                  Please wait while processing the e-way bill generation
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EWayBillModal;
