import React, { useEffect, useState } from "react";
import StepItem from "./StepItem";
import DeleteTransaction from "../../components/ShortOrder/DeleteTransaction";
import Skeleton from "react-loading-skeleton";

const ShortOrderStepper = ({ currentStep, isCompleted, orderId }) => {
  const advancedPaymentStepper = [
    { indicator: 1, label: "Short Order", tag: "Buyers" },
    { indicator: 2, label: "Company Details", tag: "Buyer/Seller" },
    { indicator: 3, label: "Purchase Order", tag: "Buyer/Seller" },
    { indicator: 4, label: "Invoice", tag: "Seller" },
    { indicator: 5, label: "Bank Details", tag: "Seller" },
    { indicator: 6, label: "Payment", tag: "Buyers" },
    { indicator: 7, label: "EWayBill", tag: "Seller" },
    { indicator: 8, label: "Material Dispatch", tag: "Seller" },
    { indicator: 9, label: "Delivered", tag: "Buyer/Seller" },
    { indicator: 10, label: "Rating", tag: "Buyer/Seller" },
  ];

  const creditPaymentStepper = [
    { indicator: 1, label: "Short Order", tag: "Buyers" },
    { indicator: 2, label: "Company Details", tag: "Buyer/Seller" },
    { indicator: 3, label: "Purchase Order", tag: "Buyer/Seller" },
    { indicator: 4, label: "Invoice", tag: "Seller" },
    { indicator: 5, label: "EWayBill", tag: "Seller" },
    { indicator: 6, label: "Material Dispatch", tag: "Seller" },
    { indicator: 7, label: "Delivered", tag: "Buyer/Seller" },
    { indicator: 8, label: "Bank Details", tag: "Seller" },
    { indicator: 9, label: "Payment", tag: "Buyers" },
    { indicator: 10, label: "Rating", tag: "Buyer/Seller" },
  ];

  const payment = localStorage.getItem("paymentType");

  const creditStepper = creditPaymentStepper.map((detail) => (
    <StepItem
      indicator={detail.indicator}
      label={detail.label}
      tag={detail.tag}
      isCompleted={isCompleted}
      isActive={detail.indicator === currentStep}
    />
  ));

  const advancedStepper = advancedPaymentStepper.map((detail) => (
    <StepItem
      indicator={detail.indicator}
      label={detail.label}
      tag={detail.tag}
      isCompleted={isCompleted}
      isActive={detail.indicator === currentStep}
    />
  ));

  return (
    <>
      <ul>{payment === "credit" ? creditStepper : advancedStepper}</ul>
      {/* {currentStep < 5 && isCompleted <= 4 && (
        <DeleteTransaction id={orderId} />
      )} */}
    </>
  );
};

export default ShortOrderStepper;
