import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../common/scss/pages/home.scss";
import "../common/scss/pages/request.scss";
import profilePic from "../assets/images/profile.png";
import PostImage from "../assets/images/post-image.jpg";
import PostImage2 from "../assets/images/social1.jpg";
import PostImage3 from "../assets/images/social2.jpg";
import PostImage4 from "../assets/images/social3.jpg";
import { ReactComponent as CaretDownIcon } from "../assets/images/caret-down.svg";
import { ReactComponent as ArrowDownIcon } from "../assets/images/arrow-down.svg";
import { ReactComponent as HistoryIcon } from "../assets/images/history-icon.svg";
import { ReactComponent as EditIcon } from "../assets/images/edit-icon.svg";
import { ReactComponent as LikeIcon } from "../assets/images/like-icon.svg";
import { ReactComponent as LikeIconFilled } from "../assets/images/like-icon-filled.svg";
import { ReactComponent as CommentIcon } from "../assets/images/comment-icon.svg";
import { ReactComponent as CommentIconFilled } from "../assets/images/comment-icon-filled.svg";
import { ReactComponent as SendQuoteIcon } from "../assets/images/send-quote-icon.svg";
import { ReactComponent as MeetingRoomIcon } from "../assets/images/meeting-room-icon.svg";
import { ReactComponent as MeetingRoomIconFilled } from "../assets/images/meeting-room-icon-filled.svg";
import { ReactComponent as PostShareIcon } from "../assets/images/post-share-icon.svg";
import { ReactComponent as PostShareIconFilled } from "../assets/images/post-share-icon-filled.svg";
import { ReactComponent as AcceptDealIcon } from "../assets/images/accept-deal-icon.svg";
import { ReactComponent as NegotiationIcon } from "../assets/images/negotiation-icon.svg";
import { ReactComponent as SocialReactIcon } from "../assets/images/social-react.svg";
import { ReactComponent as CardIcon } from "../assets/images/card-icon.svg";
import { ReactComponent as MoreIcon } from "../assets/images/more-icon.svg";
import { ReactComponent as AttachmentIcon } from "../assets/images/attachment.svg";
import Header from "../common/header";
import { Link, useParams } from "react-router-dom";
import LeftSideBar from "./LeftSideBar";
import RightSideBar from "./RightSideBar";
import { get, getAuthConfig } from "../libs/http-hydrate";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import PostCardSeller from "./Post-card-seller";
import PostCardBuyer from "./post-card-buyer";
import PostCardSocial from "./post-card-social";

const PostDetail = () => {
  const [product, setProduct] = useState({});
  const [userRole, setUserRole] = useState({});
  const [loading, setLoading] = useState(false);
  // const settingsPost = {
  //   arrows: true,
  //   dots: false,
  //   infinite: false,
  //   autoplay: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  const params = useParams();
  const postId = params?.id;
  const postType = params?.type;

  const getPost = async () => {
    try {
      setLoading(true);
      if (postType === "social_post") {
        await get(`/social_post/details/${postId}`, getAuthConfig()).then(
          (res) => {
            setLoading(false);
            setProduct(res.data.data);
          }
        );
      } else {
        await get(`/product/detail/${postId}`, getAuthConfig()).then((res) => {
          setLoading(false);
          // Merge product, interactions, and credit_policy
          const mergedProduct = {
            ...res.data.data.product, // Assuming product is always present
            interactions: res.data.data.interactions,
            credit_policy: res.data.data.credit_policy,
          };

          setProduct(mergedProduct);
          // setProduct(res.data.data);
          setUserRole(res.data.data.product);
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error?.response?.data || "some error occurred");

      setLoading(false);
    }
  };

  UseEffectOnce(() => {
    getPost();
  });
  return (
    <>
      <Header home />

      <div className="grey-bg">
        <div className="container-fluid">
          <div className="layout-grid-box">
            <LeftSideBar />
            <div className="layout-grid">
              <div className="posts">
                <div className="posts-header"></div>

                <div className="posts-content collapse show" id="collapupdate">
                  {userRole.type === "post_to_sell" ? (
                    <PostCardSeller productdata={product} loading={loading} />
                  ) : userRole.type === "post_to_buy" ? (
                    <PostCardBuyer productdata={product} loading={loading} />
                  ) : (
                    <PostCardSocial productdata={product} loading={loading} />
                  )}
                </div>
              </div>
            </div>
            <RightSideBar />
          </div>
        </div>
      </div>
    </>
  );
};

export default PostDetail;
