import React, { useEffect, useRef } from "react";
import SupportChatHeader from "./SupportChatHeader";
import SupportChatInput from "./SupportChatInput";
import logoSmall from "../../assets/images/logo-small.svg";
import Auth from "../../libs/auth";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import chatGif from "../../assets/images/chat-defualt.png";

const SupportChatBox = ({
  data,
  loading,
  chatHistory,
  message,
  loadingChat,
  setMessage,
  ticketIds,
  tab,
  handlePageChangeforchatListchat,
  chatPage,
  supportDetails,
  attachment,
}) => {
  const user = Auth?.getCurrentUser();

  const sortedData = data?.items?.sort(
    (a, b) => moment(a?.created_at) - moment(b?.created_at)
  );

  const scrollDivRef = useRef(null);
  useEffect(() => {
    if (sortedData && chatPage === 1) {
      const scroll =
        scrollDivRef?.current?.scrollHeight -
        scrollDivRef?.current?.clientHeight;
      scrollDivRef?.current?.scrollTo(0, scroll);
    }
  }, [sortedData]);

  return (
    <>
      <div className="card-body p-0">
        <SupportChatHeader />
        {loading ? (
          <>
            <div className="chat-body">
              <div className="sender">
                <Skeleton width={100} height={50} />
              </div>
            </div>
            <div className="chat-body">
              <div className="sender">
                <Skeleton width={100} height={50} />
              </div>
            </div>
            <div className="chat-body">
              <div className="receiver">
                <Skeleton width={100} height={50} />
              </div>
            </div>
          </>
        ) : (
          <>
            {sortedData?.length <= 0 && (
              <div className="bg-white text-center">
                <img src={chatGif} height={400} width={500} />
              </div>
            )}

            {sortedData?.length > 0 && (
              <div
                className="chat-body"
                style={{
                  maxHeight: "400px",
                  minHeight: "400px",
                  overflowY: "scroll",
                }}
                ref={scrollDivRef}
                onScroll={(e) => {
                  e.preventDefault();
                  if (
                    scrollDivRef.current.scrollTop === 0 &&
                    data?.has_more === true
                  ) {
                    handlePageChangeforchatListchat();
                  }
                }}
              >
                {sortedData?.map((chat) => (
                  <div>
                    {chat?.user?.id === user?.id ? (
                      <div className="sender">
                        <div className="message">
                          {chat?.message === null ? (
                            <img
                              alt=""
                              src={chat?.attachments[0]?.file}
                              height={200}
                              width={200}
                            />
                          ) : (
                            <p>{chat?.message}</p>
                          )}
                        </div>
                        <div className="time">
                          {moment(chat?.created_at).format(
                            "DD MMM, YYYY h:mm A"
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="receiver">
                        <div className="profile-pic">
                          <img src={logoSmall} alt="Logo" />
                        </div>
                        <div className="profile">
                          <div className="message">
                            {chat?.message === null ? (
                              <img
                                alt=""
                                src={chat?.attachments[0]?.file}
                                height={200}
                                width={200}
                              />
                            ) : (
                              <p>{chat?.message}</p>
                            )}
                          </div>
                          <div className="time">
                            {moment(chat?.created_at).format(
                              "DD MMM, YYYY h:mm A"
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
      {tab === "closed" ? (
        <div className="card-footer closed-message">
          Ticket Closed By BusiMeet Team
        </div>
      ) : (
        <SupportChatInput
          loadingChats={loadingChat}
          loadingChat={loading}
          sortedData={sortedData}
          ticketIds={ticketIds}
          chatHistory={chatHistory}
          message={message}
          setMessage={setMessage}
          supportDetails={supportDetails}
          attachment={attachment}
        />
      )}
    </>
  );
};

export default SupportChatBox;
