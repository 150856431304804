import React, { useCallback, useEffect, useRef, useState } from "react";
import "../common/scss/pages/home.scss";
import "../common/scss/pages/notification.scss";
import Header from "../common/header";
import { Link, useNavigate } from "react-router-dom";
import { get, getAuthConfig } from "../libs/http-hydrate";
import Auth from "../libs/auth";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import moment from "moment";
import LeftSideBar from "./LeftSideBar";
import RightSideBar from "./RightSideBar";
import Skeleton from "react-loading-skeleton";
import NotificationListSkeleton from "../components/notifications/NotificationListSkeleton";
import { redirectNotification } from "../utils/notificationHelper";

const Notification = () => {
  const navigate = useNavigate();
  const currentUser = Auth.getCurrentUser();

  const [NotificationData, setNotificationData] = useState({
    has_more: false,
    items: [],
  });
  const [NotificationPage, setNotificationPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loggedUserDetails, setLoggedUserDetails] = useState(null);

  UseEffectOnce(() => {
    fetchDataT();
  }, []);

  useEffect(() => {
    if (NotificationPage === 0) {
    } else {
      fetchDataT();
    }
  }, [NotificationPage]);

  async function fetchDataT() {
    // setLoading(true);
    await get(
      `/user/notification_listing?page=${NotificationPage + 1}`,
      getAuthConfig()
    )
      .then((datas) => {
        if (datas.status === 200) {
          // setLoading(false);
          if (NotificationPage === 0) {
            setNotificationData((p) => ({
              ...p,
              items: datas?.data?.data?.items,
            }));
          } else {
            setNotificationData((p) => ({
              ...p,
              items: NotificationData.items.concat(datas?.data?.data?.items),
            }));
          }
          setNotificationData((p) => ({
            ...p,
            has_more: datas?.data?.data?.has_more,
          }));
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e?.response?.status === 401) {
          Auth.logout();
          navigate("/login");
        }
      });
  }

  const observer1 = useRef();
  const lastStateElementRef = useCallback(
    (node) => {
      if (observer1.current) observer1.current.disconnect();
      observer1.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && NotificationData?.has_more) {
          setNotificationPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer1.current.observe(node);
    },
    [NotificationData?.has_more]
  );

  function momentT(ed) {
    if (Math.abs(moment(ed).diff()) < 5000) {
      return "just now";
    }
    return moment(ed).fromNow();
  }

  const handleNotificationClick = async (notification) => {
    const redirectRoute = await redirectNotification(notification);
    if (redirectRoute) {
      if (notification?.transaction_id) {
        navigate(redirectRoute, {
          state: { id: notification?.transaction_id },
        });
      } else if (notification?.negotiation_id) {
        navigate(redirectRoute, {
          state: {
            negotiationId: notification?.negotiation_id,
            id: notification?.negotiation_id,
          },
        });
      } else {
        navigate(redirectRoute, {
          state: { negotiationId: notification?.item?.id },
        });
      }
    }
  };

  const decapitalize = ([first, ...rest], upperRest = false) => {
    const lowercaseString =
      first.toLowerCase() +
      (upperRest ? rest.join("").toUpperCase() : rest.join(""));
    return lowercaseString.replaceAll(" ", "");
  };

  const fetchAllLoggedUserDetails = async () => {
    if (currentUser?.slug) {
      const deCapsSlug = decapitalize(currentUser?.slug);

      await get(`/user/profile/details/${deCapsSlug}`, getAuthConfig())
        .then((res) => {
          setLoggedUserDetails(res?.data?.data);
        })
        .catch((err) => {});
    }
  };

  UseEffectOnce(() => {
    if (currentUser?.slug) {
      fetchAllLoggedUserDetails();
    }
  });

  return (
    <>
      <Header home />

      <div className="grey-bg">
        <div className="container-fluid">
          <div className="layout-grid-box">
            <LeftSideBar loggedUserDetails={loggedUserDetails} />
            <div className="layout-grid">
              <div className="card notification">
                <div className="card-header">
                  <h6>Notifications</h6>
                </div>
                <div className="card-body notification-wrap">
                  {loading && <NotificationListSkeleton />}
                  {!loading &&
                    NotificationData?.items &&
                    NotificationData?.items.length > 0 &&
                    NotificationData?.items.map((item) => {
                      return (
                        <>
                          <div ref={lastStateElementRef} key={item?.id}>
                            <Link
                              to=""
                              onClick={() => handleNotificationClick(item)}
                              // to={() => handleNotificationClick(item)}
                              state={{ negotiationId: item?.negotiation_id }}
                              className="notification-item"
                            >
                              <div className="icon light-orange">
                                {loading ? (
                                  <Skeleton width={45} height={45} />
                                ) : (
                                  <img
                                    src={
                                      item?.user?.profile_image
                                        ? item?.user?.profile_image
                                        : "https://dev-busimeet.s3.ap-south-1.amazonaws.com/notification/Group+162800.png"
                                    }
                                    alt="notification-icon"
                                  />
                                )}
                              </div>
                              <div className="notification-title">
                                {loading ? (
                                  <Skeleton width={500} />
                                ) : (
                                  <h6>{item?.title}</h6>
                                )}
                              </div>
                              <div className="notification-right ">
                                <div className="time">
                                  {" "}
                                  {momentT(item?.created_at)}{" "}
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      );
                    })}
                  {!loading &&
                  NotificationData?.items &&
                  NotificationData?.items.length <= 0 ? (
                    <>
                      <div className="notification-item">
                        <div className="text-center">
                          No Notification Found{" "}
                        </div>{" "}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <RightSideBar />
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
