import React, { useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import Breadcrumb from "../common/breadcrumb";
// import Delivered from "../assets/images/delivered.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ShortOrderStepper from "../common/Stepper/ShortOrderStepper";
import { get, getAuthConfig } from "../libs/http-hydrate";
function ProductDelivered() {
  let currentStep;

  const [shortOrderDetails, setShortOrderDetails] = useState();
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const getProductCartSO = async () => {
    if (location?.state?.id) {
      setLoading(true);

      await get(`/short_order/details/${location?.state?.id}`, getAuthConfig())
        .then((res) => {
          setLoading(false);
          setShortOrderDetails(res?.data?.data);
          // setProducts(res?.data?.data?.items);
          localStorage.setItem("paymentType", res?.data?.data?.payment_type);
        })
        .catch((err) => {
          setLoading(false);
          if (
            err.response.status === 400 &&
            err?.response?.data?.message === "Short order not found"
          ) {
            navigate("/");
          }
        });
    }
  };

  useEffect(() => {
    getProductCartSO();
  }, [location?.state?.id]);

  const navigate = useNavigate();

  if (!localStorage.getItem("paymentType")) {
    localStorage.setItem("paymentType", shortOrderDetails?.payment_type);
  }

  const payment = localStorage.getItem("paymentType");

  currentStep = payment === "advance" ? 9 : 7;

  return (
    <>
      <Header home disableSwitch />

      <div className="grey-bg">
        <div className="container-fluid order-page">
          <Breadcrumb shortOrderDetails={shortOrderDetails} />
          <div className="steps">
            <ShortOrderStepper
              currentStep={currentStep}
              isCompleted={Number(currentStep)}
            />
          </div>
          <div className="order product-order e-bill-page">
            <div className="company-detail e-bill">
              {/* <img src={Delivered} className='delivered-img' /> */}
            </div>
            <div className="page-title pb-0">
              <h6 style={{ fontSize: "18px" }}>
                Your material is dispatched and transaction is completed,
                <br />
                <br /> kindly move to ratings and provide your valuable inputs
                for your business partner.
              </h6>
            </div>
            {!loading && (
              <div className="btn-wrap">
                <Link
                  state={{
                    id: shortOrderDetails?.id,
                    route: location?.state?.route,
                  }}
                  to="/product-material-dispatch"
                  className="button button-blue"
                >
                  Previous
                </Link>
                <Link
                  state={{
                    id: shortOrderDetails?.id,
                    route: location?.state?.route,
                  }}
                  to={`${
                    payment === "advance"
                      ? "/product-rating"
                      : "/product-bank-detail"
                  }`}
                  className="button button-primary"
                >
                  Next
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDelivered;
