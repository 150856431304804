import React, { useContext, useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

import Header from "../common/header";
import LeftSideBar from "./LeftSideBar";
import { SwitchProfile } from "../context/switchProfile.context";
import ChatRoom from "./chatroom";
import RightSideBar from "./RightSideBar";
import { get, getAuthConfig } from "../libs/http-hydrate";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import Auth from "../libs/auth";

const MeetingRoomMain = () => {
  const currentUser = Auth.getCurrentUser();

  const [HomeDatas, setHomeDatas] = useState({});
  const [Update, setSupdate] = useState(false);
  const [loggedUserDetails, setLoggedUserDetails] = useState(null);

  const { accountFor } = useContext(SwitchProfile);

  async function HomeData() {
    try {
      const data = await get("/home", getAuthConfig());
      if (data.status === 200) {
        setHomeDatas(data.data.data);

        localStorage.setItem("PENDREQ", data?.data?.data?.pending_request);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  }

  UseEffectOnce(() => {
    HomeData();
  }, []);

  useEffect(() => {
    if (Update === true) {
      HomeData();
    }
    setSupdate(false);
  }, [Update]);

  const decapitalize = ([first, ...rest], upperRest = false) => {
    const lowercaseString =
      first.toLowerCase() +
      (upperRest ? rest.join("").toUpperCase() : rest.join(""));
    return lowercaseString.replaceAll(" ", "");
  };

  const fetchAllLoggedUserDetails = async () => {
    if (currentUser?.slug) {
      const deCapsSlug = decapitalize(currentUser?.slug);

      await get(`/user/profile/details/${deCapsSlug}`, getAuthConfig())
        .then((res) => {
          setLoggedUserDetails(res?.data?.data);
        })
        .catch((err) => {});
    }
  };

  UseEffectOnce(() => {
    if (currentUser?.slug) {
      fetchAllLoggedUserDetails();
    }
  });

  return (
    <>
      <ToastContainer />
      <Header home />

      <div className="grey-bg">
        <div className="container-fluid">
          <div className="layout-grid-box">
            <LeftSideBar
              childFunc={accountFor?.buyer}
              loggedUserDetails={loggedUserDetails}
            />
            <div className="layout-grid">
              <ChatRoom />
            </div>
            {HomeDatas?.suggested_users ? (
              <RightSideBar Suggested={HomeDatas?.suggested_users} />
            ) : (
              <Skeleton height={500} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetingRoomMain;
