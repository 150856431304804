import React, { useState, useRef } from "react";
import Slider from "react-slick";
import Header from "../common/header";
import profilePlaceholder from "../assets/images/profile-placeholder.svg";
import coverPlaceHolder from "../assets/images/img-placeholder.svg";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as ConnectIcon } from "../assets/images/connect-icon.svg";
import { ReactComponent as MoreIcon } from "../assets/images/more-icon.svg";
import { ReactComponent as CameraIcon } from "../assets/images/camera-icon.svg";
import { ReactComponent as VisitingCardIcon } from "../assets/images/vc-icon.svg";
import { ReactComponent as ButtonEditIcon } from "../assets/images/button-edit-icon.svg";
import { ReactComponent as TickIcon } from "../assets/images/tick-icon.svg";
import { ReactComponent as InfoIcon } from "../assets/images/info.svg";
import { ReactComponent as CertificateIcon } from "../assets/images/certificate-icon.svg";
import { ReactComponent as ViewIcon } from "../assets/images/view-icon.svg";
import { ReactComponent as EditIcon } from "../assets/images/edit-icon2.svg";
import { ReactComponent as DeleteIcon } from "../assets/images/delete-icon.svg";
import { ReactComponent as AwardIcon } from "../assets/images/award-icon.svg";
import { ReactComponent as BusinessIcon } from "../assets/images/business-map-new.svg";
import { ReactComponent as ContactIcon } from "../assets/images/contact-icon.svg";
import { ReactComponent as ProtfolioIcon } from "../assets/images/protfolio-icon.svg";
import { ReactComponent as CaretDownIcon } from "../assets/images/caret-down.svg";
import { ReactComponent as BrandIcon } from "../assets/images/brand-icon.svg";
import { ReactComponent as OfferSentIcon } from "../assets/images/offer-sent-icon.svg";
import { ReactComponent as NegotiationsIcon } from "../assets/images/negotiation-new.svg";
import { ReactComponent as FinalOfferIcon } from "../assets/images/final-offer-icon.svg";
import { ReactComponent as RejectedOfferIcon } from "../assets/images/rejected-offer-icon.svg";
import { ReactComponent as DraftIcon } from "../assets/images/draft-icon.svg";
import { ReactComponent as CreateIcon } from "../assets/images/create-icon.svg";
import { ReactComponent as Transactions2Icon } from "../assets/images/transactions2-icon.svg";
import { ReactComponent as InCompleteIcon } from "../assets/images/incomplete-icon.svg";
import { ReactComponent as CompleteIcon } from "../assets/images/complete-icon.svg";
import { ReactComponent as OngoingIcon } from "../assets/images/ongoing-icon.svg";
import { ReactComponent as NetworkIcon } from "../assets/images/network-icon.svg";
import { ReactComponent as TestimonialIcon } from "../assets/images/testimonial-icon.svg";
import { ReactComponent as ProductCategoryIcon } from "../assets/images/product-category-icon.svg";
import { ReactComponent as ReportIcon } from "../assets/images/report-icon.svg";
import { ReactComponent as BlockIcon } from "../assets/images/block-icon.svg";
import ChangeCover from "./edit-cover-photo";
import EditInfo from "./edit-profile-modal";
import CertificationModal from "./certifications-modal";
import AwardsModal from "./awards-modal";
import RequireProductsCategories from "./product-category-modal";
import ContactModal from "./contact-modal";
import BusinessMapModal from "./business-map-modal";
import ViewCertificate from "./view-certificate";
import EditCertificationModal from "./edit-certifications-modal";
import EditAwardsModal from "./edit-awards-modal";
import BrandsModal from "./brands-modal";
import EditBrandsModal from "./edit-brands-modal";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Auth from "../libs/auth";
import { get, getAuthConfig, postwithOu } from "../libs/http-hydrate";
import ViewAward from "./viewAward";
import DeleteModal from "./delete-modal";
import NetworkModal from "./network-modal";
import EditVisitingCard from "./visiting-card";
import ViewBrand from "./viewBrand";
import EditAboutModal from "./edit-about";
import BlockModal from "./block-modal";
import ReportModal from "./report-modal";
import pending from "../assets/images/pending.jpg";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import { useEffect } from "react";
import { ReactComponent as MeetingRoomIconFilled } from "../assets/images/meeting-room-icon-filled.svg";
import VerifyModal from "./verify-modal";
import swal from "sweetalert";
import ReviewCard from "../components/Profile/ReviewCard";
import GaugeChart from "react-gauge-chart";
import MyActivityCard from "../components/Profile/MyActivityCard";
import { capitalizeString, toTitleCase } from "../utils/capitalize";
import CoverImagePopup from "../components/Profile/CoverImagePopup";
import CircularProgress from "../components/CircularProgress";
import CompanyDataProfile from "./company-data-profile";

// import SuccessIcon from "../assets/images/suucess-outline.svg";
import editProfileIcon from "../assets/images/edit-icon.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../common/scss/pages/edit-profile.scss";
import {
  IconAddUser,
  IconBlock,
  IconBlockedUser,
  IconCheckmark,
  IconEdit,
  IconFlag,
  IconVisitCard,
} from "../utils/icons";

const settingsCertificat = {
  dots: false,
  arrows: true,
  infinite: false,
  autoplay: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  lazyLoad: "on-demand",
};

function EditProfileSeller() {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }

  const user = Auth.getCurrentUser();
  const [loading, setLoading] = useState();
  const [companyData, setCompanyData] = useState({});
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [showEditCertificationModal, setShowEditCertificationModal] =
    useState(false);
  const [showEditCertificationData, setShowEditCertificationData] = useState(
    []
  );
  const [certificateData, setShowCertificateData] = useState({});
  const [showAwardsModal, setShowAwardsModal] = useState(false);
  const [showAwardData, setShowAwardData] = useState({});
  const [showEditAwardData, setShowEditAwardData] = useState([]);
  const [showEditAwardModal, setShowEditAwardModal] = useState(false);
  const [
    showSpecificEditCertificationModal,
    setShowSpecificEditCertificationModal,
  ] = useState(false);
  const [
    showSpecificEditCertificationModalData,
    setShowSpecificEditCertificationModalData,
  ] = useState({});
  const [showSpecificEditAwardModal, setShowSpecificEditAwardModal] =
    useState(false);
  const [showSpecificEditAwardModalData, setShowSpecificEditAwardModalData] =
    useState({});
  const [showBrandsModal, setShowBrandsModal] = useState(false);
  const [showBrandsData, setShowBrandsData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState({});
  const [showNetworkModal, setShowNetworkModal] = useState(false);
  const [networkModalData, setNetworkModalData] = useState({});
  const [showVisitingCard, setShowVisitingCard] = useState(false);
  const [visitingCardData, setVisitingCardData] = useState();
  const [visitingCardDataBackImg, setVisitingCardDataBackImg] = useState();
  const [showBrand, setShowBrand] = useState(false);
  const [brandData, setBrandData] = useState({});
  const [showSpecificEditBrand, setShowSpecificEditBrand] = useState(false);
  const [showSpecificEditBrandData, setShowSpecificEditBrandData] = useState(
    {}
  );
  const [Update, setSupdate] = useState(false);
  useEffect(() => {
    if (Update === true) {
      getDetails();
    }
  }, [Update]);

  function UpdateTrue() {
    setSupdate(true);
  }
  const [name, setName] = useState("");
  const [blockUser, setBlockUser] = useState();
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [isConnected, setIsConnected] = useState();
  const [isConnectReq, setIsConnectReq] = useState(0);
  const [showEditAboutModal, setShowEditAboutModal] = useState(false);
  const [showBusinessMap, setShowBusinessMap] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showCoverModal, setShowCoverModal] = useState(false);
  const [showRequiredProductCategories, setShowRequiredProductCategories] =
    useState(false);
  const isRunned = useRef(false);
  const [requestSent, setRequestSent] = useState(false);
  const [isShowAllCertificates, setIsShowAllCertificates] = useState(false);
  const [isShowAllAwards, setIsShowAllAwards] = useState(false);
  const [isShowAllBrands, setIsShowAllBrands] = useState(false);
  const [showCoverImgModal, setShowCoverImgModal] = useState(false);

  const params = useParams();
  const slug = params.slug;

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settingsBrands = {
    dots: false,
    arrows: true,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    lazyLoad: "on-demand",
  };

  const decapitalize = ([first, ...rest], upperRest = false) => {
    const lowercaseString =
      first.toLowerCase() +
      (upperRest ? rest.join("").toUpperCase() : rest.join(""));
    return lowercaseString.replaceAll(" ", "");
  };
  const navigate = useNavigate();

  UseEffectOnce(() => {
    if (isRunned.current) return;
    isRunned.current = true;

    const deCapsSlug = decapitalize(slug);

    if (!deCapsSlug) {
      navigate("/");
    }

    if (deCapsSlug) {
      getDetails();
    }
  });

  useEffect(() => {
    getDetails();
  }, [slug]);

  function getDetails() {
    setLoading(true);
    const deCapsSlug = decapitalize(slug);
    get(`/user/profile/details/${deCapsSlug}`, getAuthConfig())
      .then((response) => {
        setCompanyData(response?.data?.data);
        setName(response?.data?.data?.name);
        setBlockUser(response?.data?.data?.is_block);
        setIsConnected(response?.data?.data?.has_connection);
        setIsConnectReq(response?.data?.data?.has_connection_requested);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setBlockUser(true);
      });
  }
  const viewCertificate = (data) => {
    setShowCertificateData(data);
    setShowCertificateModal(true);
    return;
  };
  const handleEditCertificate = (data) => {
    setShowEditCertificationData(data);
    setShowEditCertificationModal(true);
    return;
  };
  const handleSpecificEditCertificate = (e, data) => {
    e.preventDefault();
    setShowSpecificEditCertificationModalData(data);
    setShowSpecificEditCertificationModal(true);
    return;
  };
  const handleSpecificEditBrandData = (e, data) => {
    e.preventDefault();
    setShowSpecificEditBrandData(data);
    setShowSpecificEditBrand(true);
    return;
  };
  const handleSpecificAwardEditModal = (e, data) => {
    e.preventDefault();
    setShowSpecificEditAwardModalData(data);
    setShowSpecificEditAwardModal(true);
    return;
  };
  const viewAward = (data) => {
    setShowAwardData(data);
    setShowAwardsModal(true);
    return;
  };
  const handleEditAward = (data) => {
    setShowEditAwardData(data);
    setShowEditAwardModal(true);
    return;
  };
  const handleDeleteModal = (data) => {
    setDeleteModalData(data);
    setShowDeleteModal(true);
    return;
  };

  const gstRef = useRef();

  const handleNetworkModal = (e) => {
    setNetworkModalData(companyData?.total_network);
    setShowNetworkModal(true);
    return;
  };
  const handleVisitingCardModal = (e) => {
    setVisitingCardData(companyData?.visiting_card || null);
    setVisitingCardDataBackImg(companyData?.visiting_card_back || null);
    setShowVisitingCard(true);
    return;
  };

  const handleBrand = (data) => {
    setBrandData(data);
    setShowBrand(true);
    return;
  };
  const handleCoverImage = () => {
    setShowCoverModal(true);
    return;
  };
  const handleBusinessMap = () => {
    setShowBusinessMap(true);
    return;
  };
  const handleContactModal = () => {
    setShowContactModal(true);
    return;
  };
  const handleEditModal = () => {
    setShowEditModal(true);
  };
  const handleAbout = () => {
    setShowEditAboutModal(true);
  };
  const handleRequiredProductCategories = () => {
    setShowRequiredProductCategories(true);
  };

  const getStringArray = (arrayData) => {
    let initialString = "";

    arrayData?.map((str) => {
      initialString = str.name + "," + initialString;
    });

    return initialString.slice(0, -1);
  };

  const industryString = getStringArray(companyData?.company_details?.industry);

  const contactData = {
    birthDate: companyData?.birth_date,
    foundation_date: companyData?.company_details?.details?.founding_date,
    companyAddress:
      companyData?.company_details?.details?.office_address ||
      companyData?.company_details?.details?.billing_address,
    factoryAddress:
      companyData?.company_details?.details?.factory_address ||
      companyData?.company_details?.details?.shipping_address,
    office_mail:
      companyData?.company_details?.details?.office_email ||
      companyData?.company_details?.details?.email,
    office_phone_number:
      companyData?.company_details?.details?.office_mobile_number ||
      companyData?.company_details?.details?.mobile_number,
  };
  const [average, setAverage] = useState(0);

  const location = useLocation();

  const onTop = () => {
    window.scrollTo(0, 0);
  };
  UseEffectOnce(() => {
    onTop();
  }, [location?.state]);

  const parentToChild = (slug) => {
    if (user?.id === companyData?.id) {
      setLoading(true);
      get(`/user/profile/details/${slug}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
        .then((response) => {
          setCompanyData(response?.data?.data);

          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          // alert("this is error", e);
        });
    }
  };

  const createThread = async (item) => {
    const formdata = new FormData();
    formdata.append("to_user_id", item);
    await postwithOu("/chat/init_thread", getAuthConfig(), formdata)
      .then((res) => {
        navigate("/chat-room", {
          state: {
            id:
              res?.data?.data?.to_user_id === user?.id
                ? res?.data?.data?.user_id
                : res?.data?.data?.to_user_id,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function sentConnectionRequest(id) {
    const formdata = new FormData();
    formdata.append("user_id", id);
    await postwithOu("/connection/send", getAuthConfig(), formdata)
      .then((datta) => {
        if (datta.status === 200) {
          setRequestSent(true);
          swal(
            "Success",
            "Your Connection Request Successfully Sent ",
            "success"
          );
        }
      })
      .catch((e) => {
        if (e.response.status === 400) {
          swal("Warning", "Request Already sent to this user ", "warning");
        }
      });
  }

  useEffect(() => {
    // Calculate the average
    if (companyData?.reviews) {
      const values = Object.values(companyData?.reviews);
      const actualValues = values.map((value) => parseFloat(value) * 20);
      const sum = actualValues.reduce(
        (acc, value) => acc + parseFloat(value),
        0
      );
      const average = sum / values.length;
      // const scaledAverage = (average / 100).toFixed(10); // Limit it to 2 decimal places
      // Scale the average to a range of 0-5
      const scaledAverage = average / 100;

      setAverage(scaledAverage);
    }
  }, [companyData]);

  const handleShowCoverImage = () => {
    setShowCoverImgModal(true);
  };

  const closeCoverModal = () => {
    setShowCoverImgModal(false);
  };

  return (
    <>
      <Header home parentToChild={parentToChild} />
      <>
        <div className="grey-bg">
          <div className="container-fluid">
            {blockUser ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <IconBlockedUser />
                  <h5> There is nothing to show here!</h5>
                </div>
              </div>
            ) : (
              <div className="layout-grid-box-profile">
                <>
                  <div className="layout-grid layout-grid--left">
                    <div className="card card--personal-info">
                      <div
                        data-toggle="modal"
                        data-target="#ShowCoverImage"
                        className="cover-image action"
                      >
                        <img
                          src={companyData?.cover_image || coverPlaceHolder}
                          alt="cover Img"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShowCoverImage();
                          }}
                          style={
                            !companyData?.cover_image
                              ? {
                                  width: "27%",
                                  height: "100%",
                                  position: "relative",
                                  top: "10%",
                                  left: "38%",
                                }
                              : null
                          }
                        />
                        {user?.id === companyData?.id ? (
                          <div
                            className="action"
                            data-toggle="modal"
                            data-target="#ChangeCover"
                            onClick={(e) => handleCoverImage()}
                            style={{ cursor: "pointer" }}
                          >
                            <CameraIcon />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="card-body pt-0">
                        <div
                          style={{
                            borderBottom: darkMode
                              ? "1px solid #383a3d"
                              : "1px solid #BDBDBD",
                          }}
                          className="profile-details flex-column mb-0"
                        >
                          <div className="d-flex justify-content-between w-100">
                            <div className="profile-details--left">
                              <div
                                className="profile-image"
                                onClick={
                                  user?.id === companyData?.id
                                    ? handleEditModal
                                    : () => {}
                                }
                                data-toggle="modal"
                                data-target="#EditInfo"
                              >
                                <img
                                  alt="profileImg"
                                  style={{
                                    cursor:
                                      user?.id === companyData?.id
                                        ? "pointer"
                                        : "default",
                                  }}
                                  src={
                                    companyData?.profile_image ||
                                    profilePlaceholder
                                  }
                                />
                              </div>
                              {loading ? (
                                <Skeleton height={15} width={150} />
                              ) : (
                                <h6 className="profile-name">
                                  {companyData?.name}
                                </h6>
                              )}
                              {loading || companyData?.i_am === undefined ? (
                                <Skeleton height={15} width={250} />
                              ) : (
                                <>
                                  {companyData?.i_am === "individual" ? (
                                    <p
                                      className="text-small"
                                      style={{
                                        display: "flex",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          marginRight: companyData
                                            ?.company_details?.details
                                            ?.designation
                                            ? "10px"
                                            : "0px",
                                        }}
                                      >
                                        {companyData?.i_am === "individual"
                                          ? "Founder at "
                                          : companyData?.company_details
                                              ?.details?.designation || ""}
                                      </span>
                                      &nbsp;
                                      <span
                                        style={{
                                          marginRight: companyData
                                            ?.company_details?.details?.name
                                            ? "10px"
                                            : "0px",
                                        }}
                                      >
                                        {companyData?.company_details?.details
                                          ?.name || ""}
                                      </span>
                                    </p>
                                  ) : companyData?.company_details?.details
                                      ?.name === null ? (
                                    <p
                                      className="text-small"
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {companyData?.i_am}
                                    </p>
                                  ) : companyData?.company_details?.details
                                      ?.designation &&
                                    companyData?.company_details?.details
                                      ?.name ? (
                                    <p
                                      className="text-small"
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                      }}
                                    >{`${toTitleCase(
                                      companyData?.company_details?.details
                                        ?.designation
                                    )} at ${toTitleCase(
                                      companyData?.company_details?.details
                                        ?.name
                                    )}`}</p>
                                  ) : (
                                    <p
                                      className="text-small"
                                      style={{
                                        display: "flex",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          marginRight: companyData
                                            ?.company_details?.details
                                            ?.designation
                                            ? "10px"
                                            : "0px",
                                        }}
                                      >
                                        {companyData?.company_details?.details
                                          ?.designation || ""}
                                      </span>
                                      <span
                                        style={{
                                          marginRight: companyData
                                            ?.company_details?.details?.name
                                            ? "10px"
                                            : "0px",
                                        }}
                                      >
                                        {companyData?.company_details?.details
                                          ?.name || ""}
                                      </span>
                                    </p>
                                  )}
                                </>
                              )}
                            </div>
                            <div
                              className="d-flex align-items-center flex-column"
                              style={{ marginTop: "25px" }}
                            >
                              <div
                                style={{
                                  paddingLeft: "40px",
                                  width: "100%",
                                  textAlign: "center",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                  textTransform: "capitalize",
                                  color: "#1cacda",
                                }}
                              >
                                {average < 0.33
                                  ? "average"
                                  : average < 0.66
                                  ? "good"
                                  : "excellent"}
                              </div>
                              <GaugeChart
                                style={{ width: "80%" }}
                                id="gauge-chart3"
                                nrOfLevels={5}
                                colors={["red", "green"]}
                                percent={average || 0}
                              />
                            </div>
                            <div className="profile-details--right">
                              <div className="button-wrap">
                                {loading ? (
                                  <Skeleton
                                    height={40}
                                    style={{ marginTop: "10px" }}
                                  />
                                ) : (
                                  <>
                                    {user?.id === companyData?.id ? (
                                      <button
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          background: darkMode
                                            ? "#1C2226"
                                            : "#fff",
                                          color: darkMode ? "#fff" : "#000",
                                          borderRadius: "5px",
                                          border: "1px solid #ffbe26",
                                          padding: "8px 15px",
                                          width: "100%",
                                          gap: "5px",
                                          fontWeight: 600,
                                        }}
                                        data-toggle="modal"
                                        data-target="#EditInfo"
                                        onClick={() => handleEditModal()}
                                      >
                                        {/* <img
                                        src={editProfileIcon}
                                        alt="editProfileIcon"
                                      /> */}
                                        <IconEdit
                                          fill={darkMode ? "#b5b5b5" : "#000"}
                                        />
                                        Edit Profile
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                                <>
                                  {isConnected === 1 &&
                                    user?.id !== companyData?.id &&
                                    requestSent === false && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          background: darkMode
                                            ? "#1C2226"
                                            : "#fff",
                                          color: darkMode ? "#fff" : "#000",
                                          borderRadius: "5px",
                                          border: "1px solid #1cacda",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {isConnected === 1 &&
                                          user?.id !== companyData?.id && (
                                            <button
                                              style={{
                                                margin: 0,
                                                borderRadius: "5px",
                                                padding: "8px 15px",
                                                background: darkMode
                                                  ? "#1C2226"
                                                  : "#fff",
                                                color: darkMode
                                                  ? "#fff"
                                                  : "#000",
                                                border: "none",
                                                display: "flex",
                                                gap: "5px",
                                                alignItems: "center",
                                              }}
                                              // onClick={(e) => {
                                              //   e.preventDefault();
                                              //   if (
                                              //     user?.verified_at === null &&
                                              //     user.is_seller === 1
                                              //   ) {
                                              //     handleShowModal();
                                              //   } else {
                                              //     sentConnectionRequest(
                                              //       companyData?.id
                                              //     );
                                              //   }
                                              // }}
                                            >
                                              {/* <img
                                              style={{
                                                height: "18px",
                                                width: "18px",
                                              }}
                                              src={SuccessIcon}
                                              alt="success"
                                            /> */}
                                              <IconCheckmark
                                                fill={
                                                  darkMode ? "#b5b5b5" : "#000"
                                                }
                                              />
                                              Connected
                                            </button>
                                          )}
                                        {!loading &&
                                          isConnected === 1 &&
                                          user?.id !== companyData?.id &&
                                          requestSent === false && (
                                            <>
                                              <Link
                                                to={""}
                                                href="#"
                                                id="navbarDropdown"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                style={{
                                                  rotate: "90deg",
                                                  color: "#ffbe26",
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                <MoreIcon />
                                              </Link>
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="navbarDropdown"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  data-toggle="modal"
                                                  data-target="#ReportModal"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "5px",
                                                  }}
                                                >
                                                  {/* <ReportIcon /> */}
                                                  <IconFlag
                                                    fill={
                                                      darkMode
                                                        ? "#b5b5b5"
                                                        : "#000"
                                                    }
                                                  />
                                                  Report
                                                </a>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  data-toggle="modal"
                                                  data-target="#BlockModal"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "5px",
                                                  }}
                                                >
                                                  {/* <BlockIcon /> */}
                                                  <IconBlock
                                                    fill={
                                                      darkMode
                                                        ? "#b5b5b5"
                                                        : "#000"
                                                    }
                                                  />
                                                  {blockUser === 1
                                                    ? "Unblock"
                                                    : "Block"}
                                                </a>
                                              </div>
                                            </>
                                          )}
                                      </div>
                                    )}
                                </>
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginBottom: "10px",
                                      alignItems: "center",
                                      background: darkMode ? "#1C2226" : "#fff",
                                      color: darkMode ? "#fff" : "#000",
                                      borderRadius: "5px",
                                      height: "fit-content",
                                    }}
                                  >
                                    {isConnectReq === 1 ? (
                                      <>
                                        <button
                                          // className={
                                          //   requestSent === true
                                          //     ? "button btn btn-success button-success button-connect"
                                          //     : "button button-primary text-white  button-connect"
                                          // }
                                          style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                            alignItems: "center",
                                            background: darkMode
                                              ? "#1C2226"
                                              : "#fff",
                                            color: darkMode ? "#fff" : "#000",
                                            borderRadius: "5px",
                                            border: "1px solid #ffbe26",
                                            padding: "8px 15px",
                                            width: "100%",
                                            gap: "5px",
                                            fontWeight: 600,
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (
                                              user?.verified_at === null &&
                                              user.is_seller === 1
                                            ) {
                                              handleShowModal();
                                            } else {
                                              sentConnectionRequest(
                                                companyData?.id
                                              );
                                            }
                                          }}
                                        >
                                          <IconAddUser />
                                          Request Sent
                                        </button>
                                      </>
                                    ) : (
                                      isConnected === 0 &&
                                      blockUser === 0 &&
                                      user?.id !== companyData?.id && (
                                        <button
                                          // className={
                                          //   requestSent === true
                                          //     ? "button btn btn-success button-success button-connect"
                                          //     : "button button-primary text-white  button-connect"
                                          // }
                                          style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                            alignItems: "center",
                                            background: darkMode
                                              ? "#1C2226"
                                              : "#fff",
                                            color: darkMode ? "#fff" : "#000",
                                            borderRadius: "5px",
                                            border: "1px solid #ffbe26",
                                            padding: "8px 15px",
                                            width: "100%",
                                            gap: "5px",
                                            fontWeight: 600,
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (
                                              user?.verified_at === null &&
                                              user.is_seller === 1
                                            ) {
                                              handleShowModal();
                                            } else {
                                              sentConnectionRequest(
                                                companyData?.id
                                              );
                                            }
                                          }}
                                        >
                                          <IconAddUser />
                                          {requestSent === true
                                            ? " Request sent"
                                            : " Connect"}
                                        </button>
                                      )
                                    )}
                                    {/* {!loading &&
                                    user?.id !== companyData?.id &&
                                    requestSent !== true &&
                                    isConnected > 0 && (
                                      <>
                                        <Link
                                          to={""}
                                          href="#"
                                          id="navbarDropdown"
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                          style={{
                                            rotate: "90deg",
                                            color: "#ffbe26",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          <MoreIcon />
                                        </Link>
                                        <div
                                          className="dropdown-menu"
                                          aria-labelledby="navbarDropdown"
                                        >
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#ReportModal"
                                          >
                                            <IconFlag
                                              fill={
                                                darkMode ? "#b5b5b5" : "#000"
                                              }
                                            />
                                            Report
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#BlockModal"
                                          >
                                            <IconBlock
                                              fill={
                                                darkMode ? "#b5b5b5" : "#000"
                                              }
                                            />
                                            {blockUser === 1
                                              ? "Unblock"
                                              : "Block"}
                                          </a>
                                        </div>
                                      </>
                                    )} */}
                                  </div>
                                </>
                                <button
                                  style={{
                                    display: "flex",
                                    marginBottom: "10px",
                                    alignItems: "center",
                                    background: darkMode ? "#1C2226" : "#fff",
                                    color: darkMode ? "#fff" : "#000",
                                    borderRadius: "5px",
                                    border: "1px solid #ffbe26",
                                    padding: "8px 15px",
                                    width: "100%",
                                    gap: "5px",
                                    fontWeight: 600,
                                    justifyContent: "space-between",
                                  }}
                                  data-toggle="modal"
                                  data-target="#VisitingCardModal"
                                  onClick={(e) => handleVisitingCardModal(e)}
                                >
                                  {/* <VisitingCardIcon /> */}
                                  <IconVisitCard
                                    fill={darkMode ? "#b5b5b5" : "#000"}
                                  />
                                  <span>Visiting Card</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            className="d-flex justify-content-between w-100 mt-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                          >
                            {companyData && companyData.id === user.id ? (
                              <Link
                                style={{ color: "#1cacda" }}
                                to="/my-network"
                                state={companyData?.id}
                              >
                                <div>
                                  <span className="mr-1">
                                    {companyData?.counters?.total_followers}
                                  </span>
                                  <span>Connections</span>
                                </div>
                              </Link>
                            ) : (
                              <div>
                                <span className="mr-1">
                                  {companyData?.counters?.total_followers}
                                </span>
                                <span>Connections</span>
                              </div>
                            )}
                            <div>
                              <span>Deals Closed: </span>
                              <span>
                                {companyData?.counters?.deal_closed
                                  ? companyData?.counters?.deal_closed
                                  : 0}
                              </span>
                            </div>
                            <div>
                              <span>Profile ID: </span>
                              <span>{companyData?.id}</span>
                            </div>
                          </div>
                        </div>
                        <div>
                          {loading ? (
                            <div className="col-sm">
                              <div className="chips">
                                <Skeleton height={50} width={550} />
                              </div>
                            </div>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <div className="company-details w-100">
                                  <div className="card-body py-0 ">
                                    <div className="company-profile d-flex align-items-center">
                                      {loading ? (
                                        <Skeleton
                                          height={60}
                                          width={120}
                                          style={{ marginRight: "10px" }}
                                        />
                                      ) : (
                                        <div className="company-profile-image">
                                          <img
                                            alt=""
                                            src={
                                              companyData?.company_details
                                                ?.details?.logo
                                            }
                                            className="profile-pic "
                                            style={{
                                              width: "120px",
                                              height: "120px",
                                              padding: "10px",
                                              borderRadius: "5px",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </div>
                                      )}
                                      <div className="company-profile-content">
                                        <div className="company-profile-name">
                                          <h6>
                                            {companyData?.company_details?.name}
                                          </h6>
                                          <ul>
                                            <li>
                                              <label>GST</label>:
                                              <span>
                                                {companyData?.gst_number}{" "}
                                                <span className="verified">
                                                  {companyData?.gst_number ===
                                                  null ? (
                                                    "N/A"
                                                  ) : (
                                                    <TickIcon />
                                                  )}
                                                </span>
                                              </span>
                                            </li>
                                            <li>
                                              <label>ESTD</label>:
                                              <span>
                                                {companyData?.company_details
                                                  ?.details?.establish_date ||
                                                  "N/A"}
                                              </span>
                                            </li>
                                            <li>
                                              <label>Industry</label>:
                                              <span>
                                                {industryString || "N/A"}
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {companyData?.id !== user?.id && (
                                  <div
                                    style={{
                                      width: "25%",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Link
                                      to={""}
                                      onClick={() =>
                                        createThread(companyData?.id)
                                      }
                                    >
                                      <div
                                        className="chips"
                                        style={{
                                          width: "120px",
                                          padding: "10px",
                                        }}
                                      >
                                        <div className="chips--value">
                                          <MeetingRoomIconFilled />
                                        </div>
                                        <div
                                          className="chips--text"
                                          style={{ fontSize: "12px" }}
                                        >
                                          Meeting Room
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                )}
                              </div>

                              {/* <div className="col-sm">
                              <div className="chips">
                                <div className="chips--value">
                                  {companyData?.id}
                                </div>
                                <div className="chips--text">Profile ID</div>
                              </div>
                            </div>
                            <div className="col-sm">
                              {companyData && companyData.id === user.id ? (
                                <Link to="/my-network" state={companyData?.id}>
                                  <div className="chips">
                                    <div className="chips--value">
                                      {companyData?.counters?.total_followers}
                                    </div>
                                    <div className="chips--text">
                                      Connection
                                    </div>
                                  </div>
                                </Link>
                              ) : (
                                <div className="chips">
                                  <div className="chips--value">
                                    {companyData?.counters?.total_followers}
                                  </div>
                                  <div className="chips--text">Connection</div>
                                </div>
                              )}
                            </div>
                            <div className="col-sm">
                              <div className="chips">
                                <div className="chips--value">
                                  {companyData?.counters?.deal_closed
                                    ? companyData?.counters?.deal_closed
                                    : 0}
                                </div>
                                <div className="chips--text">Deals Closed</div>
                              </div>
                            </div> */}
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    {loading ? (
                      <Skeleton height={70} style={{ marginBottom: "10px" }} />
                    ) : (
                      <>
                        <div className="card card-about">
                          <div
                            style={{
                              borderBottom: darkMode
                                ? "1px solid #383a3d"
                                : "1px solid #BDBDBD",
                            }}
                            className="card-header card-header--large"
                          >
                            <h6>
                              <InfoIcon />
                              About
                            </h6>
                            <>
                              {user?.id === companyData?.id ? (
                                <button
                                  className="button-edit"
                                  data-toggle="modal"
                                  data-target="#AboutModal"
                                  onClick={() => handleAbout()}
                                >
                                  <ButtonEditIcon />
                                </button>
                              ) : (
                                ""
                              )}
                            </>
                          </div>
                          <div className="card-body">
                            <p style={{ fontSize: "14px" }}>
                              {companyData?.about}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="card card-certificate image-lists">
                      <div
                        style={{
                          borderBottom: darkMode
                            ? "1px solid #383a3d"
                            : "1px solid #BDBDBD",
                        }}
                        className="card-header card-header--large"
                      >
                        <h6
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <CertificateIcon />
                          Certifications
                        </h6>
                        {user?.id === companyData?.id ? (
                          <button
                            className="button-edit"
                            data-toggle="modal"
                            data-target="#CertificationsModal"
                            onClick={() =>
                              handleEditCertificate(
                                companyData.show_case.certificates
                              )
                            }
                          >
                            <ButtonEditIcon />
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="card-body">
                        <div
                          className="row"
                          style={{
                            height:
                              companyData?.show_case?.certificates?.length > 0
                                ? "150px"
                                : "20px",
                            overflowY: "hidden",
                          }}
                        >
                          {companyData?.show_case?.certificates?.length > 0 ? (
                            <div style={{ width: "100%" }}>
                              <Slider {...settingsCertificat} arrows={true}>
                                {companyData?.show_case?.certificates?.map(
                                  (certificate) => (
                                    <div className="col-sm">
                                      <div className="img-block">
                                        <img src={certificate?.file} />
                                        <div className="actions">
                                          <button
                                            className="button-view"
                                            data-toggle="modal"
                                            data-target="#ViewCertificate"
                                            onClick={() =>
                                              viewCertificate(certificate)
                                            }
                                          >
                                            <ViewIcon />
                                          </button>
                                          {user?.id === companyData?.id ? (
                                            <>
                                              <button
                                                className="button-edit"
                                                data-toggle="modal"
                                                data-target="#EditCertificationModal"
                                                onClick={(e) =>
                                                  handleSpecificEditCertificate(
                                                    e,
                                                    certificate
                                                  )
                                                }
                                              >
                                                <EditIcon props={certificate} />
                                              </button>
                                              <button
                                                className="button-delete"
                                                data-toggle="modal"
                                                data-target="#DeleteModal"
                                                onClick={() =>
                                                  handleDeleteModal(certificate)
                                                }
                                              >
                                                <DeleteIcon />
                                              </button>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </Slider>
                            </div>
                          ) : (
                            <div className="col-sm">
                              <p style={{ fontSize: "14px" }}>
                                To increase your profile value please add the
                                certificate if you have
                              </p>
                            </div>
                          )}
                        </div>
                        {/* {companyData?.show_case?.certificates?.length > 0 && (
                        <div
                          className="show-less-or-all-content"
                          onClick={() =>
                            setIsShowAllCertificates(!isShowAllCertificates)
                          }
                        >
                          <span>
                            {" "}
                            {isShowAllCertificates ? "Show less" : "Show all"}
                          </span>
                        </div>
                      )} */}
                      </div>
                    </div>
                    <div className="card card-achivements image-lists">
                      <div
                        style={{
                          borderBottom: darkMode
                            ? "1px solid #383a3d"
                            : "1px solid #BDBDBD",
                        }}
                        className="card-header card-header--large"
                      >
                        <h6 style={{ fontSize: "16px" }}>
                          <AwardIcon />
                          Awards & Achivements
                        </h6>
                        {user?.id === companyData?.id ? (
                          <button
                            className="button-edit"
                            data-toggle="modal"
                            data-target="#AwardsModal"
                            onClick={() =>
                              handleEditAward(
                                companyData.show_case.achievements
                              )
                            }
                          >
                            <ButtonEditIcon />
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="card-body">
                        <div
                          className="row"
                          style={{
                            height: `${
                              !companyData?.show_case?.achievements?.length > 0
                                ? "20px"
                                : "150px"
                            }`,
                            overflowY: "hidden",
                          }}
                        >
                          {companyData?.show_case?.achievements?.length > 0 ? (
                            <>
                              <div style={{ width: "100%" }}>
                                <Slider {...settingsCertificat} arrows={true}>
                                  {companyData?.show_case.achievements?.map(
                                    (award) => (
                                      <div className="col-sm">
                                        <div className="img-block">
                                          <img src={award?.file} />
                                          <div className="actions">
                                            <button
                                              className="button-view"
                                              data-toggle="modal"
                                              data-target="#ViewAward"
                                              onClick={() => viewAward(award)}
                                            >
                                              <ViewIcon />
                                            </button>
                                            {user?.id === companyData?.id ? (
                                              <>
                                                <button
                                                  className="button-edit"
                                                  data-toggle="modal"
                                                  data-target="#EditAwardsModal"
                                                  onClick={(e) =>
                                                    handleSpecificAwardEditModal(
                                                      e,
                                                      award
                                                    )
                                                  }
                                                >
                                                  <EditIcon />
                                                </button>
                                                <button
                                                  className="button-delete"
                                                  data-toggle="modal"
                                                  data-target="#DeleteModal"
                                                  onClick={() =>
                                                    handleDeleteModal(award)
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </button>{" "}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </Slider>
                              </div>
                            </>
                          ) : (
                            <div className="col-sm">
                              <p style={{ fontSize: "14px" }}>
                                To increase your profile value please add the
                                Awards & Achievements if you have
                              </p>
                            </div>
                          )}
                        </div>
                        {/* {companyData?.show_case?.achievements?.length > 0 && (
                        <div
                          className="show-less-or-all-content"
                          onClick={() => setIsShowAllAwards(!isShowAllAwards)}
                        >
                          <span>
                            {" "}
                            {isShowAllAwards ? "Show less" : "Show all"}
                          </span>
                        </div>
                      )} */}
                      </div>
                    </div>
                    <MyActivityCard id={user?.id} />
                    {companyData.is_seller === 1 ? (
                      <>
                        <div className="card">
                          <div
                            style={{
                              borderBottom: darkMode
                                ? "1px solid #383a3d"
                                : "1px solid #BDBDBD",
                            }}
                            className="card-header card-header--large"
                          >
                            <h6 style={{ fontSize: "16px" }}>
                              <BusinessIcon />
                              Business Map
                            </h6>
                            {user?.id === companyData?.id ? (
                              <button
                                className="button-edit"
                                data-toggle="modal"
                                data-target="#BusinessMapModal"
                                onClick={(e) => handleBusinessMap()}
                              >
                                <ButtonEditIcon />
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="card-body">
                            <div className="card-lists map">
                              {companyData?.business_timelines?.length > 0 ? (
                                <>
                                  {companyData?.business_timelines?.map(
                                    (businessData) => (
                                      <div className="card-lists-item user-profile">
                                        <div className="user-profile-image icon">
                                          <img
                                            alt=""
                                            src={businessData?.media?.file}
                                          />
                                        </div>
                                        <div className="user-profile-content">
                                          <div className="user-profile-name">
                                            <h6
                                              style={{
                                                fontSize: "16px",
                                                fontWeight: 600,
                                              }}
                                            >
                                              {businessData?.year}
                                            </h6>
                                            <p
                                              style={{
                                                fontSize: "14px",
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {businessData?.description}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className="col-sm">
                                    <p>
                                      To increase your profile value please add
                                      business map of your organization
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className="card card-contact">
                      <div
                        style={{
                          borderBottom: darkMode
                            ? "1px solid #383a3d"
                            : "1px solid #BDBDBD",
                        }}
                        className="card-header card-header--large"
                      >
                        <h6 style={{ fontSize: "16px", display: "flex" }}>
                          <ContactIcon />
                          Contact
                        </h6>
                        {user?.id === companyData?.id ? (
                          <button
                            className="button-edit"
                            data-toggle="modal"
                            data-target="#ContactModal"
                            onClick={(e) => handleContactModal()}
                          >
                            <ButtonEditIcon />
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="card-body contact-profile">
                        <ul>
                          <li className="d-flex mb-3">
                            <label
                              style={{
                                minWidth: "200px",
                                marginRight: "10px",
                              }}
                            >
                              Office Address
                            </label>
                            :
                            <span style={{ whiteSpace: "pre-wrap" }}>
                              {contactData?.companyAddress || "N/A"}
                            </span>
                          </li>
                          <li className="d-flex mb-3">
                            <label
                              style={{
                                minWidth: "200px",
                                marginRight: "10px",
                              }}
                            >
                              Factory Address
                            </label>
                            :
                            <span style={{ whiteSpace: "pre-wrap" }}>
                              {contactData?.factoryAddress || "N/A"}
                            </span>
                          </li>
                          <li className="d-flex mb-3">
                            <label
                              style={{
                                minWidth: "200px",
                                marginRight: "10px",
                              }}
                            >
                              Contact Number
                            </label>
                            :
                            <span style={{ whiteSpace: "pre-wrap" }}>
                              {contactData?.office_phone_number || "N/A"}
                            </span>
                          </li>
                          <li className="d-flex mb-3">
                            <label
                              style={{
                                minWidth: "200px",
                                marginRight: "10px",
                              }}
                            >
                              Email Address
                            </label>
                            :
                            <span style={{ whiteSpace: "pre-wrap" }}>
                              {contactData?.office_mail || "N/A"}
                            </span>
                          </li>
                          <li className="d-flex mb-3">
                            <label
                              style={{
                                minWidth: "200px",
                                marginRight: "10px",
                              }}
                            >
                              Company Foundation
                            </label>
                            :
                            <span style={{ whiteSpace: "pre-wrap" }}>
                              {contactData?.foundation_date || "N/A"}
                            </span>
                          </li>
                          <li className="d-flex mb-3">
                            <label
                              style={{
                                minWidth: "200px",
                                marginRight: "10px",
                              }}
                            >
                              Owner Birthday
                            </label>
                            :
                            <span style={{ whiteSpace: "pre-wrap" }}>
                              {contactData?.birthDate || "N/A"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="layout-grid layout-grid--right">
                    {loading ? (
                      <Skeleton
                        height={400}
                        count={2}
                        style={{ marginBottom: "12px" }}
                      />
                    ) : (
                      <>
                        {companyData?.is_seller === 1 ? (
                          <>
                            <div className="card card-product-protfolio">
                              <div
                                style={{
                                  borderBottom: darkMode
                                    ? "1px solid #383a3d"
                                    : "1px solid #BDBDBD",
                                }}
                                className="card-header card-header--large"
                              >
                                <h6 style={{ fontSize: "16px" }}>
                                  <ProtfolioIcon />
                                  Product Portfolio
                                </h6>
                                {user?.id === companyData?.id ? (
                                  <Link
                                    to="/product-portfolio-initial"
                                    className="button-edit"
                                  >
                                    <ButtonEditIcon />
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div
                                style={{ paddingTop: "5px" }}
                                className="card-body"
                              >
                                <div className="card-lists">
                                  {companyData?.products?.length > 0 ? (
                                    <>
                                      {companyData?.products
                                        ?.slice(0, 3)
                                        ?.map((product) => (
                                          <div
                                            className="card-lists-item product-protfolio"
                                            onClick={() => {
                                              navigate("/product-portfolio", {
                                                state: product,
                                              });
                                            }}
                                            style={{
                                              borderBottom: darkMode
                                                ? "1px solid #383a3d"
                                                : "1px solid #BDBDBD",
                                            }}
                                          >
                                            <div className="product-protfolio-image">
                                              <img
                                                alt=""
                                                src={product?.thumb_image?.file}
                                              />
                                            </div>
                                            <div className="product-protfolio-content">
                                              <div className="product-name">
                                                <h6>{product.name}</h6>
                                                <span className="product-tag">
                                                  {product?.category?.name}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                    </>
                                  ) : (
                                    <>
                                      <h4 style={{ fontSize: "16px" }}>
                                        {companyData?.id === user?.id
                                          ? "To get the business please create your product portfolio"
                                          : "No Records Found"}
                                      </h4>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="card-footer">
                                {companyData?.products?.length > 4 ? (
                                  <>
                                    <Link
                                      to={
                                        user?.id === companyData?.id
                                          ? "/product-portfolio-initial"
                                          : `/product-portfolio-initial/${companyData?.id}`
                                      }
                                      className={
                                        darkMode
                                          ? "show-less-or-all-content-dark"
                                          : "show-less-or-all-content"
                                      }
                                      state={{ userSloug: companyData?.slug }}
                                    >
                                      Show all
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div className="card card-brand image-lists">
                              <div className="card-header card-header--large">
                                <h6 style={{ fontSize: "16px" }}>
                                  <BrandIcon />
                                  Brands
                                </h6>
                              </div>
                              <div className="card-body">
                                <div
                                  style={{
                                    gap: "30px",
                                    height: `${
                                      isShowAllBrands ? "auto" : "120px"
                                    }`,
                                    overflowY: "hidden",
                                  }}
                                >
                                  {companyData?.show_case?.brands?.length >
                                  0 ? (
                                    <>
                                      <Slider {...settingsBrands}>
                                        {companyData?.show_case?.brands?.map(
                                          (brand) => (
                                            <div className="col-sm">
                                              <div className="img-block">
                                                {brand?.status !== 1 ? (
                                                  <>
                                                    {" "}
                                                    <div
                                                      style={{
                                                        width: "100px",
                                                        height: "100px",
                                                      }}
                                                    >
                                                      <img
                                                        alt=""
                                                        src={pending}
                                                      />
                                                      <span>
                                                        Pending Approval
                                                      </span>
                                                      <div
                                                        style={{
                                                          width: "100px",
                                                        }}
                                                        className="actions"
                                                      >
                                                        <button
                                                          className="button-view"
                                                          data-toggle="modal"
                                                          data-target="#ViewBrand"
                                                          onClick={() =>
                                                            handleBrand(brand)
                                                          }
                                                        >
                                                          <ViewIcon />
                                                        </button>
                                                        <button
                                                          className="button-edit"
                                                          data-dismiss="modal"
                                                          data-toggle="modal"
                                                          data-target="#EditBrandsModal"
                                                          onClick={(e) => {
                                                            handleSpecificEditBrandData(
                                                              e,
                                                              brand
                                                            );
                                                          }}
                                                        >
                                                          <EditIcon />
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    <div
                                                      style={{
                                                        width: "100px",
                                                        height: "100px",
                                                      }}
                                                    >
                                                      <img
                                                        style={{
                                                          objectFit: "contain",
                                                        }}
                                                        src={brand?.icon}
                                                      />
                                                      <div
                                                        style={{
                                                          width: "100px",
                                                        }}
                                                        className="actions d-flex justify-content-center"
                                                      >
                                                        <button
                                                          className="button-view"
                                                          data-toggle="modal"
                                                          data-target="#ViewBrand"
                                                          onClick={() =>
                                                            handleBrand(brand)
                                                          }
                                                        >
                                                          <ViewIcon />
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </Slider>
                                    </>
                                  ) : (
                                    <>
                                      <div className="col-sm">
                                        <h4 style={{ fontSize: "16px" }}>
                                          People wants to know which brands you
                                          have
                                        </h4>
                                      </div>
                                    </>
                                  )}
                                </div>
                                {/* <div
                                className="show-less-or-all-content"
                                onClick={() =>
                                  setIsShowAllBrands(!isShowAllBrands)
                                }
                              >
                                <span>
                                  {" "}
                                  {isShowAllBrands ? "Show less" : "Show all"}
                                </span>
                              </div>{" "} */}
                              </div>
                            </div>
                          </>
                        ) : null}
                        {companyData?.is_buyer === 1 ? (
                          <>
                            <div className="card">
                              <div className="card-header card-header--large">
                                <h6>
                                  <ProductCategoryIcon /> Require Products
                                  Categories
                                </h6>
                                {user?.id === companyData?.id ? (
                                  <button
                                    className="button-edit"
                                    data-toggle="modal"
                                    data-target="#ProductCategoryModal"
                                    onClick={handleRequiredProductCategories}
                                  >
                                    <ButtonEditIcon />
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="card-body">
                                <div className="tags tags-regular">
                                  {companyData?.product_categories?.map(
                                    (category) => (
                                      <span className="tag">
                                        {category?.name}
                                      </span>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </>
                    )}
                    {user?.id === companyData?.id ? (
                      <div className="card card-chips-withButton">
                        <div className="card-header card-header--large">
                          <h6 style={{ fontSize: "16px" }}>
                            <NegotiationsIcon />
                            Negotiations
                          </h6>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <>
                              <div className="col-sm-6">
                                <Link
                                  to={
                                    companyData.is_seller === 1
                                      ? "/negotiation-seller"
                                      : "/negotiation-buyer"
                                  }
                                  state={{
                                    filtering:
                                      companyData.is_seller === 1
                                        ? "initial_offer_sent"
                                        : "initial_offer_received",
                                  }}
                                >
                                  <div className="chips chips-withButton">
                                    <div className="chips--value">
                                      {
                                        companyData?.counters?.negotiations
                                          ?.initial_offer
                                      }
                                    </div>
                                    <div className="chips--text">
                                      {`Initial Offer ${
                                        companyData.is_buyer === 1
                                          ? "Received"
                                          : "Sent"
                                      }`}
                                    </div>
                                    <button className="button button-blue">
                                      <OfferSentIcon />
                                    </button>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-sm-6">
                                <Link
                                  to={
                                    companyData.is_seller === 1
                                      ? "/negotiation-seller"
                                      : "/negotiation-buyer"
                                  }
                                  state={{
                                    filtering:
                                      companyData.is_seller === 1
                                        ? "revised_offer_received"
                                        : "revised_offer_sent",
                                  }}
                                >
                                  <div className="chips chips-withButton">
                                    <div className="chips--value">
                                      {
                                        companyData?.counters?.negotiations
                                          ?.revised_offer_requests
                                      }
                                    </div>
                                    <div className="chips--text">
                                      Revised Offer{" "}
                                      {`${
                                        companyData.is_buyer === 0
                                          ? "Received"
                                          : "Requested"
                                      }`}
                                    </div>
                                    <button className="button button-blue">
                                      <OfferSentIcon />
                                    </button>
                                  </div>
                                </Link>
                              </div>

                              <div className="col-sm-6">
                                <Link
                                  to={
                                    companyData.is_seller === 1
                                      ? "/negotiation-seller"
                                      : "/negotiation-buyer"
                                  }
                                  state={{
                                    filtering:
                                      companyData.is_seller === 1
                                        ? "final_offer_sent"
                                        : "final_offer_received",
                                  }}
                                >
                                  <div className="chips chips-withButton">
                                    <div className="chips--value">
                                      {" "}
                                      {
                                        companyData?.counters?.negotiations
                                          ?.final_offer
                                      }{" "}
                                    </div>
                                    <div className="chips--text">
                                      Final Offer Sent / Received
                                    </div>
                                    <button className="button button-blue">
                                      <FinalOfferIcon />
                                    </button>
                                  </div>
                                </Link>
                              </div>
                            </>

                            <div className="col-sm-6">
                              <Link
                                to={
                                  companyData.is_seller === 1
                                    ? "/negotiation-seller"
                                    : "/negotiation-buyer"
                                }
                                state={{
                                  filtering:
                                    companyData.is_seller === 1
                                      ? "rejected"
                                      : "rejected",
                                }}
                              >
                                <div className="chips chips-withButton">
                                  <div className="chips--value">
                                    {
                                      companyData?.counters?.negotiations
                                        ?.rejected_offers
                                    }
                                  </div>
                                  <div className="chips--text">
                                    Rejected Offers
                                  </div>
                                  <button className="button button-blue">
                                    <RejectedOfferIcon />
                                  </button>
                                </div>
                              </Link>
                            </div>
                            <div className="col-sm-6">
                              <Link
                                to={
                                  companyData.is_seller === 1
                                    ? "/negotiation-seller"
                                    : "/negotiation-buyer"
                                }
                                state={{
                                  filtering:
                                    companyData.is_seller === 1
                                      ? "draft"
                                      : "draft",
                                }}
                              >
                                <div className="chips chips-withButton">
                                  <div className="chips--value">
                                    {companyData?.counters?.negotiations?.draft}
                                  </div>
                                  <div className="chips--text">Draft</div>
                                  <button className="button button-blue">
                                    <DraftIcon />
                                  </button>
                                </div>
                              </Link>
                            </div>
                            {/* {companyData?.is_buyer === 1 ? (
                          <>
                            <div className="col-sm-6">
                              <div className="chips chips-withButton">
                                <div className="chips--value">
                                  {
                                    companyData?.counters?.negotiations
                                      ?.revised_offer_requests
                                  }
                                </div>
                                <div className="chips--text">
                                  Revised Offer Request
                                </div>
                                <button className="button button-blue">
                                  <DraftIcon />
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )} */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="card card-chips-withButton">
                        <div className="card-header card-header--large">
                          <h6 style={{ fontSize: "16px" }}>
                            <NegotiationsIcon />
                            Negotiations
                          </h6>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <>
                              <div className="col-sm-6">
                                <div className="chips chips-withButton">
                                  <div className="chips--value">
                                    {
                                      companyData?.counters?.negotiations
                                        ?.initial_offer
                                    }
                                  </div>
                                  <div className="chips--text">
                                    {`Initial Offer ${
                                      companyData.is_buyer === 1
                                        ? "Received"
                                        : "Sent"
                                    }`}
                                  </div>
                                  <button className="button button-blue">
                                    <OfferSentIcon />
                                  </button>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="chips chips-withButton">
                                  <div className="chips--value">
                                    {
                                      companyData?.counters?.negotiations
                                        ?.revised_offer_requests
                                    }
                                  </div>
                                  <div className="chips--text">
                                    Revised Offer{" "}
                                    {`${
                                      companyData.is_buyer === 0
                                        ? "Received"
                                        : "Requested"
                                    }`}
                                  </div>
                                  <button className="button button-blue">
                                    <OfferSentIcon />
                                  </button>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="chips chips-withButton">
                                  <div className="chips--value">
                                    {" "}
                                    {
                                      companyData?.counters?.negotiations
                                        ?.final_offer
                                    }{" "}
                                  </div>
                                  <div className="chips--text">
                                    Final Offer Sent / Received
                                  </div>
                                  <button className="button button-blue">
                                    <FinalOfferIcon />
                                  </button>
                                </div>
                              </div>
                            </>

                            <div className="col-sm-6">
                              <div className="chips chips-withButton">
                                <div className="chips--value">
                                  {
                                    companyData?.counters?.negotiations
                                      ?.rejected_offers
                                  }
                                </div>
                                <div className="chips--text">
                                  Rejected Offers
                                </div>
                                <button className="button button-blue">
                                  <RejectedOfferIcon />
                                </button>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="chips chips-withButton">
                                <div className="chips--value">
                                  {companyData?.counters?.negotiations?.draft}
                                </div>
                                <div className="chips--text">Draft</div>
                                <button className="button button-blue">
                                  <DraftIcon />
                                </button>
                              </div>
                            </div>
                            {/* {companyData?.is_buyer === 1 ? (
                        <>
                          <div className="col-sm-6">
                            <div className="chips chips-withButton">
                              <div className="chips--value">
                                {
                                  companyData?.counters?.negotiations
                                    ?.revised_offer_requests
                                }
                              </div>
                              <div className="chips--text">
                                Revised Offer Request
                              </div>
                              <button className="button button-blue">
                                <DraftIcon />
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )} */}
                          </div>
                        </div>
                      </div>
                    )}
                    {user?.id === companyData?.id ? (
                      <>
                        <div className="card card-chips-withButton">
                          <div className="card-header card-header--large">
                            <h6 style={{ fontSize: "16px" }}>
                              <Transactions2Icon />
                              Transactions
                            </h6>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              {/* <div className="col-sm-6">
                              <button className="chips chips-create">
                                <div className="chips--text">
                                  <CreateIcon />
                                  New Transaction
                                </div>
                              </button>
                            </div> */}
                              <div className="col-sm-6">
                                <Link
                                  to={"/transaction"}
                                  state={{ filtering: "in_complete" }}
                                >
                                  <div className="chips chips-withButton">
                                    <div className="chips--value">
                                      {
                                        companyData?.counters?.transactions
                                          ?.in_completed
                                      }
                                    </div>
                                    <div className="chips--text">
                                      Incomplete
                                    </div>
                                    <button className="button button-blue">
                                      <InCompleteIcon />
                                    </button>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-sm-6">
                                <Link
                                  to={"/transaction"}
                                  state={{ filtering: "completed" }}
                                >
                                  <div className="chips chips-withButton">
                                    <div className="chips--value">
                                      {
                                        companyData?.counters?.transactions
                                          ?.completed
                                      }
                                    </div>
                                    <div className="chips--text">Completed</div>
                                    <button className="button button-blue">
                                      <CompleteIcon />
                                    </button>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-sm-6">
                                <Link
                                  to={"/transaction"}
                                  state={{ filtering: "on_going" }}
                                >
                                  <div className="chips chips-withButton">
                                    <div className="chips--value">
                                      {
                                        companyData?.counters?.transactions
                                          ?.on_going
                                      }
                                    </div>
                                    <div className="chips--text">Ongoing</div>
                                    <button className="button button-blue">
                                      <OngoingIcon />
                                    </button>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ReviewCard
                          isSeller={companyData?.is_seller}
                          reviews={companyData?.reviews}
                        />
                      </>
                    ) : (
                      <>
                        <div className="card card-chips-withButton">
                          <div className="card-header card-header--large">
                            <h6 style={{ fontSize: "16px" }}>
                              <Transactions2Icon />
                              Transactions
                            </h6>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              {/* <div className="col-sm-6">
                              <button className="chips chips-create">
                                <div className="chips--text">
                                  <CreateIcon />
                                  New Transaction
                                </div>
                              </button>
                            </div> */}
                              <div className="col-sm-6">
                                <div className="chips chips-withButton">
                                  <div className="chips--value">
                                    {
                                      companyData?.counters?.transactions
                                        ?.in_completed
                                    }
                                  </div>
                                  <div className="chips--text">Incomplete</div>
                                  <button className="button button-blue">
                                    <InCompleteIcon />
                                  </button>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="chips chips-withButton">
                                  <div className="chips--value">
                                    {
                                      companyData?.counters?.transactions
                                        ?.completed
                                    }
                                  </div>
                                  <div className="chips--text">Completed</div>
                                  <button className="button button-blue">
                                    <CompleteIcon />
                                  </button>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="chips chips-withButton">
                                  <div className="chips--value">
                                    {
                                      companyData?.counters?.transactions
                                        ?.on_going
                                    }
                                  </div>
                                  <div className="chips--text">Ongoing</div>
                                  <button className="button button-blue">
                                    <OngoingIcon />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ReviewCard
                          isSeller={companyData?.is_seller}
                          reviews={companyData?.reviews}
                        />
                      </>
                    )}

                    {companyData?.is_seller === 1 ? (
                      <>
                        <div className="card">
                          <div className="card-header card-header--large">
                            <h6 style={{ fontSize: "16px" }}>
                              <NetworkIcon />
                              Our Network
                            </h6>
                            {user?.id === companyData?.id ? (
                              <button
                                className="button-edit"
                                data-toggle="modal"
                                data-target="#NetworkModal"
                                onClick={(e) => handleNetworkModal(e)}
                              >
                                <ButtonEditIcon />
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="card-body">
                            <CompanyDataProfile
                              isOwnProfile={user?.id === companyData?.id}
                              companyData={companyData}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}

                    {/* <div className="card card-testimonial">
                    <div className="card-header card-header--large">
                      <h6>
                        <TestimonialIcon />
                        Client’s Testimonials
                      </h6>
                    </div>
                    <div className="card-body">
                      <Slider {...settings} className="card-lists">
                        <div className="slide-item">
                          <div className="card-lists-item user-profile">
                            <div className="user-profile-image">
                              <img
                                src={profilePic}
                                alt=""
                                className="profile-pic"
                              />
                            </div>
                            <div className="user-profile-content">
                              <div className="user-profile-name">
                                <h6>Retailer</h6>
                                <p>M-one Enterprise</p>
                                <p className="text-medium">
                                  “We Greatly appreciated M-One Group for their
                                  kind support at every stage in terms of
                                  quality of the product, price and timely
                                  delivery”
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="slide-item">
                          <div className="card-lists-item user-profile">
                            <div className="user-profile-image">
                              <img
                                src={profilePic}
                                alt=""
                                className="profile-pic"
                              />
                            </div>
                            <div className="user-profile-content">
                              <div className="user-profile-name">
                                <h6>Retailer</h6>
                                <p>M-one Enterprise</p>
                                <p className="text-medium">
                                  “We Greatly appreciated M-One Group for their
                                  kind support at every stage in terms of
                                  quality of the product, price and timely
                                  delivery”
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div> */}
                  </div>
                </>
                {/* )} */}
              </div>
            )}
          </div>
        </div>
        <BlockModal
          id={companyData.id}
          name={name}
          blockUsers={blockUser}
          UpdateTrue={UpdateTrue}
        />
        <ReportModal id={companyData.id} />
      </>
      {showCoverModal ? <ChangeCover data={companyData?.cover_image} /> : <></>}{" "}
      {/* for buyer */}
      {showEditModal ? (
        <EditInfo
          data={companyData}
          getDetails={getDetails}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
        />
      ) : (
        <></>
      )}{" "}
      {/* for generic */}
      {showEditCertificationModal ? (
        <>
          <CertificationModal data={showEditCertificationData} />
        </>
      ) : (
        <></>
      )}
      {showEditAwardModal ? (
        <>
          <AwardsModal data={showEditAwardData} />
        </>
      ) : (
        <></>
      )}{" "}
      {/* for both */}
      {showRequiredProductCategories ? (
        <RequireProductsCategories data={companyData?.product_categories} />
      ) : (
        <></>
      )}{" "}
      {/* for buyer */}
      {showContactModal ? <ContactModal data={contactData} /> : <></>}{" "}
      {/* for buyer */}
      {showBusinessMap ? (
        <BusinessMapModal
          data={companyData.business_timelines}
          getDetails={getDetails}
        />
      ) : (
        <></>
      )}{" "}
      {/* for seller */}
      {showCertificateModal ? (
        <>
          <ViewCertificate data={certificateData} />
        </>
      ) : (
        <></>
      )}
      {showSpecificEditCertificationModal ? (
        <>
          <EditCertificationModal
            data={showSpecificEditCertificationModalData}
          />
        </>
      ) : (
        <></>
      )}
      {showSpecificEditAwardModal ? (
        <>
          <EditAwardsModal data={showSpecificEditAwardModalData} />
        </>
      ) : (
        <></>
      )}
      {showBrandsModal ? (
        <>
          <BrandsModal data={showBrandsData} />
        </>
      ) : (
        <></>
      )}
      {showAwardsModal ? (
        <>
          <ViewAward data={showAwardData} />
        </>
      ) : (
        <></>
      )}
      {showDeleteModal ? (
        <>
          <DeleteModal data={deleteModalData} />
        </>
      ) : (
        <></>
      )}
      {showNetworkModal ? (
        <>
          <NetworkModal data={networkModalData} />
        </>
      ) : (
        <></>
      )}
      {showVisitingCard ? (
        <>
          <EditVisitingCard
            data={visitingCardData}
            visitingCardDataBackImg={visitingCardDataBackImg}
            is_Owner={true}
          />
        </>
      ) : (
        <></>
      )}
      {showSpecificEditBrand ? (
        <>
          <EditBrandsModal data={showSpecificEditBrandData} />
        </>
      ) : (
        <></>
      )}
      {showBrand ? (
        <>
          <ViewBrand data={brandData} />
        </>
      ) : (
        <></>
      )}
      {showEditAboutModal ? (
        <>
          {" "}
          <EditAboutModal data={companyData} />{" "}
        </>
      ) : (
        <></>
      )}
      <VerifyModal
        gstRef={gstRef}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
      <CoverImagePopup
        showCoverImgModal={showCoverImgModal}
        closeCoverModal={closeCoverModal}
        coverImage={companyData?.cover_image || coverPlaceHolder}
      />
    </>
  );
}

export default EditProfileSeller;
