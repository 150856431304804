import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../common/header";
import "../common/scss/pages/product-protfolio.scss";
import { ReactComponent as EditIcon } from "../assets/images/edit-icon2.svg";
import { ReactComponent as InfoIcon } from "../assets/images/info.svg";
import ProductImagePlaceholder from "../assets/images/uploadImg.svg";
import ArrowDownIcon from "../assets/images/arrow-down.svg";
import Cancel from "../assets/images/cancel.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { get, getAuthConfig, post, postwithOu } from "../libs/http-hydrate";
import Select from "react-select";
import FieldValidationError from "../components/error-messages/field-validation-error";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-easy-crop";
import getCroppedImg from "./Crop";
import { PuffLoader } from "react-spinners";
import getCroppedImgr, { dataURLtoFile } from "../utils/dataURLtoFile";

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PriceHideModal from "./price-hide-modal";
import PostHideFrom from "./post-hide-modal";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import { SwitchProfile } from "../context/switchProfile.context";
import { customStyles } from "../utils/react-select-input-style";
import { IconArrow } from "../utils/icons";

function CreatePostToBuy() {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }
  const { accountFor } = useContext(SwitchProfile);

  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [availableColor, setAvailableColor] = useState([]);
  const [availableUnit, setAvailableUnit] = useState({
    items: [],
    has_more: false,
  });
  const [unitPage, setUnitPage] = useState(0);

  const [productArray, setProductArray] = useState({
    items: [],
    has_more: false,
  });
  const [productSubCategoryArray, setProductSubCategoryArray] = useState({
    items: [],
    has_more: false,
  });
  const [categoryPage, setcategoryPage] = useState(0);
  const [subCatPage, setsubCatPage] = useState(0);
  const [productArrayCity, setProductArrayCity] = useState([]);
  const [BrandArray, setBrandArray] = useState([
    { id: "other", name: "other" },
  ]);
  const [templateArray, setTemplateArray] = useState([]);
  const [ProductData, setProductData] = useState({
    name: "",
    terms: "",
    category_id: "",
    required_in_days: "",
    preferred_transport: "",
    order_qty: "",
    sub_category_id: "",
    city_id: "",
    size: "",
    city: "",
    material_type: "",
    code: "",
    shape: "",
    capacity: "",
    color_id: "",
    brand_id: "",
    min_order_qty: "",
    quality_standard: "",
    certificate: "",
    unit_id: "",
    for_advance_payment: "",
    for_credit_payment: "",
    hsn_code: "",
    gst_rate: "",
    delivery_within_state: "",
    delivery_outside_state: "",
    description: "",
    Front_Image: "",
    Back_Image: "",
    Upper_Image: "",
    Lower_Image: "",
    Left_Image: "",
    Right_Image: "",
    gallery: [],
    otherBrandName: "",
    template: "",
    resticate: [],
    product_catalogue: "",
  });
  const [ProductDataErr, setProductDataErr] = useState({
    category_id: false,
    sub_category_id: false,
    qty: false,
    city: false,
    Front_Image: false,
    name: false,
    required_in_days: false,
    preferred_transport: false,
    template: false,
  });
  const selectInputRef = useRef(null);

  function intialCat() {
    post("/general/category_listing", {
      type: "product",
      page: categoryPage + 1,
    })
      .then((response) => {
        setProductArray((p) => ({
          ...p,
          items: productArray.items.concat(response?.data?.data),
        }));
        setProductArray((p) => ({ ...p, has_more: response?.data?.has_more }));
      })
      .catch((e) => {});
  }

  function searcSubCat(parent_id, newValue) {
    post("/general/category_listing", {
      type: "product_sub_category",
      page: subCatPage + 0,
      per_page: 50,
      search: newValue,
      parent_id: parent_id,
    })
      .then((response) => {
        setProductSubCategoryArray((p) => ({
          ...p,
          items: response?.data?.data,
        }));
        setProductSubCategoryArray((p) => ({
          ...p,
          has_more: response?.data?.has_more,
        }));
        // setProductSubCategoryArray(response.data.data);
      })
      .catch((e) => {
        // alert("this is the error", e);
      });
  }

  function intialcity() {
    get("/general/region_listing?get_cities_only=1")
      .then((response) => {
        let cities = response.data?.data || [];

        cities = cities.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });

        setProductArrayCity(cities);
      })
      .catch((e) => {
        // alert("this is the error", e);
      });
  }
  function IntialColor() {
    post("/general/category_listing", { type: "color" })
      .then((response) => {
        setAvailableColor(response.data.data);
      })
      .catch((e) => {
        // alert("this is the error", e);
      });
  }

  useEffect(() => {
    IntialUnit();
  }, [unitPage]);
  function IntialUnit() {
    post("/general/category_listing", { type: "unit", per_page: 5000 })
      .then((response) => {
        if (unitPage > 1) {
          setAvailableUnit((p) => ({
            ...p,
            items: availableUnit?.items?.concat(response?.data?.data),
          }));
        } else {
          setAvailableUnit((p) => ({ ...p, items: response?.data?.data }));
        }
        setAvailableUnit((p) => ({ ...p, has_more: response?.data?.has_more }));
      })
      .catch((e) => {
        // alert("this is the error", e);
      });
  }

  function searcBrand(newValue, parent_id) {
    post("/general/category_listing", {
      type: "brand",
      page: 1,
      search: newValue,
      parent_id: parent_id,
    })
      .then((response) => {
        setBrandArray(response.data.data);
        setBrandArray((oldArray) => [
          ...oldArray,
          { id: "other", name: "other" },
        ]);
      })
      .catch((e) => {
        // alert("this is the error", e);
      });
  }
  function BrandGet() {
    post("/general/category_listing", {
      type: "brand",
      page: 1,
      parent_id: ProductData?.sub_category_id,
    })
      .then((response) => {
        setBrandArray(response.data.data);

        setBrandArray((oldArray) => [
          ...oldArray,
          { id: "other", name: "other" },
        ]);
      })
      .catch((e) => {
        // alert("this is the error", e);
      });
  }
  // function clear() {
  //   // alert("inside clear")

  //   // selectInputRef.current.select.clearValue();
  // }

  // function subCategory(id) {
  //   post("/general/category_listing", {
  //     type: "product_sub_category",
  //     parent_id: id,
  //   })
  //     .then((response) => {
  //       // setProductSubCategoryArray(response.data.data);
  //       setProductSubCategoryArray(response.data.data);
  //       selectInputRef.current.props.options.splice(0,selectInputRef.current.props.options.length);
  //       selectInputRef.current.props.options = response.data.data;
  //       alert(response?.data?.has_more)
  //       setHas_moreCat(response.data.has_more);
  //       return false;
  //     })

  // }
  // function searcCat(newValue) {
  //   post("/general/category_listing", {
  //     type: "product",
  //     page: 1,
  //     search: newValue,
  //   })
  //     .then((response) => {
  //       setProductArrayCat(response.data.data);
  //     })
  //     .catch((e) => {
  //       alert("this is the error", e);
  //     });
  // }

  useEffect(() => {
    if (subCatPage === 0) {
    } else {
      // searcSubCat(ProductData?.category_id);
    }
  }, [subCatPage]);
  useEffect(() => {
    if (categoryPage === 0) {
    } else {
      intialCat();
    }
  }, [categoryPage]);

  function TemplateFunction() {
    get("/general/content?search&type=post_to_buy")
      .then((response) => {
        setTemplateArray(response.data.data);
      })
      .catch((e) => {
        // alert("this is the error", e);
      });
  }
  const [croppedImages, setCroppedImages] = useState({
    Front_Image: false,
    Back_Image: false,
    Upper_Image: false,
    Lower_Image: false,
    Left_Image: false,
    Right_Image: false,
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        croppedImages?.Front_Image === true
          ? ProductData?.Front_Image
          : croppedImages?.Back_Image === true
          ? ProductData?.Back_Image
          : croppedImages?.Left_Image === true
          ? ProductData?.Left_Image
          : croppedImages?.Right_Image === true
          ? ProductData?.Right_Image
          : croppedImages?.Upper_Image === true
          ? ProductData?.Upper_Image
          : croppedImages?.Lower_Image === true
          ? ProductData?.Lower_Image
          : "",
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
      if (croppedImages?.Front_Image === true) {
        setProductData((p) => ({ ...p, Front_Image: croppedImage }));
      }

      if (croppedImages?.Back_Image === true) {
        setProductData((p) => ({ ...p, Back_Image: croppedImage }));
      }

      if (croppedImages?.Left_Image === true) {
        setProductData((p) => ({ ...p, Left_Image: croppedImage }));
      }

      if (croppedImages?.Lower_Image === true) {
        setProductData((p) => ({ ...p, Lower_Image: croppedImage }));
      }
      if (croppedImages?.Right_Image === true) {
        setProductData((p) => ({ ...p, Right_Image: croppedImage }));
      }
      if (croppedImages?.Upper_Image === true) {
        setProductData((p) => ({ ...p, Upper_Image: croppedImage }));
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, ProductData?.Front_Image]);

  const categoryRef = useRef();
  const DifferentiationRef = useRef();
  const OtherRef = useRef();
  const PricingRef = useRef();
  const TaxRef = useRef();
  const DeliveryRef = useRef();
  const descriptionRef = useRef();
  const imagesRef = useRef();
  const nameRef = useRef();
  const cityRef = useRef();
  const qtyRef = useRef();
  const preRef = useRef();
  const dayRef = useRef();
  const templateRef = useRef();
  const refy = useRef(null);

  async function createPostToBuy(e) {
    e.preventDefault();
    setLoading(true);
    var bool = false;

    if (ProductData?.template === "") {
      bool = true;
      setProductDataErr((p) => ({ ...p, template: true }));
      templateRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // if ((!(localStorage.getItem("buyerAccount") === "true")) && ProductData?.preferred_transport === "") {
    //   bool = true;
    //   setProductDataErr((p) => ({ ...p, preferred_transport: true }));
    //   preRef.current.scrollIntoView({ behavior: "smooth" });
    // }

    // if (ProductData?.required_in_days === "") {
    //   bool = true;
    //   setProductDataErr((p) => ({ ...p, required_in_days: true }));
    //   dayRef.current.scrollIntoView({ behavior: "smooth" });
    // }
    if (ProductData?.city_id === "") {
      bool = true;

      setProductDataErr((p) => ({ ...p, city: true }));
      cityRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (ProductData?.name === "") {
      bool = true;

      setProductDataErr((p) => ({ ...p, name: true }));
      nameRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (ProductData?.sub_category_id === "") {
      bool = true;

      setProductDataErr((p) => ({ ...p, sub_category_id: true }));
      categoryRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (ProductData?.category_id === "") {
      bool = true;

      setProductDataErr((p) => ({ ...p, category_id: true }));
      categoryRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (
      ProductData?.Front_Image.trim() === "" &&
      ProductData?.Back_Image.trim() === "" &&
      ProductData?.Lower_Image.trim() === "" &&
      ProductData?.Upper_Image.trim() === "" &&
      ProductData?.Left_Image.trim() === "" &&
      ProductData?.Right_Image.trim() === ""
    ) {
      bool = true;
      setProductDataErr((p) => ({ ...p, Front_Image: true }));
      imagesRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (
      ProductDataErr?.category_id === true ||
      ProductDataErr?.sub_category_id === true ||
      ProductDataErr?.city === true ||
      ProductDataErr?.qty === true ||
      ProductDataErr?.Front_Image === true ||
      ProductDataErr?.template === true
    ) {
      setLoading(false);
      bool = true;
      return false;
    }

    console.log("ProductData", ProductData);

    if (
      ProductData?.size === "" &&
      ProductData?.color_id === "" &&
      ProductData?.material_type === "" &&
      ProductData?.brand_id === "" &&
      ProductData?.code === "" &&
      ProductData?.shape === "" &&
      ProductData?.capacity === ""
    ) {
      bool = true;

      setProductDataErr((p) => ({ ...p, size: true }));
      DifferentiationRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (bool === true) {
      setLoading(true);
      return false;
    }

    const formdata = new FormData();
    if (ProductData?.brand_id === "other") {
      const BrandFromData = new FormData();
      BrandFromData.append("sub_category_id", ProductData?.sub_category_id);
      BrandFromData.append("name", ProductData?.otherBrandName);

      const url = "/product/brand/create";
      const response = await postwithOu(url, getAuthConfig(), BrandFromData)
        .then((res) => {
          if (res.status === 200) {
            // alert(res.data.data.id);
            formdata.append("brand_id", res.data.data.id);
          }
        })
        .catch((err) => {
          alert("Brand Category Not Created or Brand Name is duplicated");
        });
    }

    if (ProductData?.brand_id != "other") {
      formdata.append("brand_id", ProductData?.brand_id);
    }

    if (ProductData?.order_qty > 10000000) {
      swal(
        "warning",
        "The order qty must not be greater than 10000000. ",
        "warning"
      );
      setLoading(false);

      return false;
    }

    formdata.append("name", ProductData?.name);
    formdata.append("post_template_id", ProductData?.template);
    formdata.append("type", "post_to_buy");
    formdata.append("category_id", ProductData?.category_id);
    formdata.append("sub_category_id", ProductData?.sub_category_id);
    formdata.append("order_qty", ProductData?.order_qty);
    formdata.append("required_in_city_id", ProductData?.city_id);
    formdata.append("preferred_transport", ProductData?.preferred_transport);
    formdata.append("required_in_city", ProductData?.city);
    formdata.append("required_in_days", ProductData?.required_in_days);
    if (ProductData?.product_catalogue != "") {
      formdata.append("product_catalogue", ProductData?.product_catalogue);
    }

    if (ProductData?.size != "") {
      formdata.append("size", ProductData?.size);
    }
    if (ProductData?.color_id != undefined) {
      formdata.append("color_id", ProductData?.color_id);
    }
    if (ProductData?.material_type != "") {
      formdata.append("material_type", ProductData?.material_type);
    }
    if (ProductData?.code != "") {
      formdata.append("product_code", ProductData?.code);
    }
    if (ProductData?.shape != "") {
      formdata.append("shape", ProductData?.shape);
    }
    if (ProductData?.capacity != "") {
      formdata.append("storage_capacity", ProductData?.capacity);
    }
    if (ProductData?.terms != "") {
      formdata.append("terms", ProductData?.terms);
    }
    if (ProductData?.amount != undefined) {
      formdata.append("amount", ProductData?.amount);
    }
    if (ProductData?.hsn_code != "") {
      formdata.append("hsn_code", ProductData?.hsn_code);
    }
    if (ProductData?.gst_rate != "") {
      formdata.append("gst_rate", ProductData?.gst_rate);
    }
    if (ProductData?.unit_id != "") {
      formdata.append("unit_id", ProductData?.unit_id);
    }
    if (ProductData?.quality_standard != "") {
      formdata.append("quality_standard", ProductData?.quality_standard);
    }

    if (ProductData?.Front_Image != "") {
      formdata.append("gallery[0][type]", "front");
      if (ProductData?.Front_Image.substring(0, 4) == "blob") {
        const ddata = await getCroppedImgr(ProductData?.Front_Image);
        formdata.append(
          "gallery[0][image]",
          dataURLtoFile(ddata, "Front.jpeg")
        );
      } else {
        formdata.append(
          "gallery[0][image]",
          dataURLtoFile(ProductData?.Front_Image, "Front.jpeg")
        );
      }
    }
    if (ProductData?.Back_Image != "") {
      formdata.append("gallery[1][type]", "back");

      if (ProductData?.Back_Image.substring(0, 4) == "blob") {
        const ddata = await getCroppedImgr(ProductData?.Back_Image);
        formdata.append("gallery[1][image]", dataURLtoFile(ddata, "Back.jpeg"));
      } else {
        formdata.append(
          "gallery[1][image]",
          dataURLtoFile(ProductData?.Back_Image, "Back.jpeg")
        );
      }
    }
    if (ProductData?.Upper_Image != "") {
      formdata.append("gallery[2][type]", "upper");
      if (ProductData?.Upper_Image.substring(0, 4) == "blob") {
        const ddata = await getCroppedImgr(ProductData?.Upper_Image);
        formdata.append(
          "gallery[2][image]",
          dataURLtoFile(ddata, "Uppper.jpeg")
        );
      } else {
        formdata.append(
          "gallery[2][image]",
          dataURLtoFile(ProductData?.Upper_Image, "Upper.jpeg")
        );
      }
    }
    if (ProductData?.Lower_Image != "") {
      formdata.append("gallery[3][type]", "lower");
      if (ProductData?.Lower_Image.substring(0, 4) == "blob") {
        const ddata = await getCroppedImgr(ProductData?.Lower_Image);
        formdata.append(
          "gallery[3][image]",
          dataURLtoFile(ddata, "lower.jpeg")
        );
      } else {
        formdata.append(
          "gallery[3][image]",
          dataURLtoFile(ProductData?.Lower_Image, "lower.jpeg")
        );
      }
    }
    if (ProductData?.Right_Image != "") {
      formdata.append("gallery[4][type]", "right");
      if (ProductData?.Right_Image.substring(0, 4) == "blob") {
        const ddata = await getCroppedImgr(ProductData?.Right_Image);
        formdata.append(
          "gallery[4][image]",
          dataURLtoFile(ddata, "right.jpeg")
        );
      } else {
        formdata.append(
          "gallery[4][image]",
          dataURLtoFile(ProductData?.Right_Image, "right.jpeg")
        );
      }
    }
    if (ProductData?.Left_Image != "") {
      formdata.append("gallery[5][type]", "left");
      if (ProductData?.Left_Image.substring(0, 4) == "blob") {
        const ddata = await getCroppedImgr(ProductData?.Left_Image);
        formdata.append("gallery[5][image]", dataURLtoFile(ddata, "left.jpeg"));
      } else {
        formdata.append(
          "gallery[5][image]",
          dataURLtoFile(ProductData?.Left_Image, "left.jpeg")
        );
      }
    }

    if (ProductData?.resticate?.length > 0) {
      ProductData?.resticate.map((item, index) => {
        formdata.append(`resticated_users[${index}]`, item);
      });
    }
    const url = "/product/store";
    const response = await postwithOu(url, getAuthConfig(), formdata)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          navigate("/", { state: "scrollToDiv" });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  }

  function postHideArray(data) {
    let arr = data;
    let filter = [];

    arr.map((item, index) => {
      if (item?.is_blocked === 1) {
        filter.push(item?.id);
      }
    });
    setProductData((p) => ({ ...p, resticate: filter }));
  }

  // const loadOptions = async (searchQuery, loadedOptions, { page }) => {
  //   let responses;
  //   let has_more;

  //   const data = await post("/general/category_listing", {
  //     type: "product",
  //     page: CategoryPage + 1,
  //   });

  //   if (data.status === 200) {
  //     setProductSubCategoryArray(data.data.data);
  //     setHas_moreCat(data.data.has_more);
  //     responses = data.data.data;
  //     has_more = data.data.has_more;
  //     let pg = CategoryPage + 1;
  //     setcategoryPage(pg);
  //   }
  //   let options = responses.map(function (productArray) {
  //     return {
  //       value: productArray.id,
  //       label: productArray.name,
  //     };
  //   });
  //   return {
  //     options: options,
  //     hasMore: has_more,
  //   };
  // };

  useEffect(() => {
    clear();
  }, [ProductData?.category_id]);
  // const loadOptionssub = async (searchQuery, loadedOptions) => {
  //   let responses;
  //   let has_more;

  //   const data = await post("/general/category_listing", {
  //     type: "product_sub_category",
  //     page: subCatPage + 1,
  //     parent_id: ProductData?.category_id,
  //   });

  //   if (data.status === 200) {
  //     responses = data.data.data;
  //     has_more = data.data.has_more;
  //     let pg = subCatPage + 1;
  //     setsubcatPage(pg);
  //   }
  //   let options = responses.map(function (productArray) {
  //     return {
  //       value: productArray.id,
  //       label: productArray.name,
  //     };
  //   });
  //   return {
  //     options: options,
  //     hasMore: has_more,
  //   };
  // };
  function clear() {
    refy.current.commonProps.setValue("");

    // selectInputRef.current.select.clearValue();
  }

  function searcCat(newValue) {
    post("/general/category_listing", {
      type: "product",
      page: 1,
      search: newValue,
    })
      .then((response) => {
        setProductArray((p) => ({
          ...p,
          items: response?.data?.data,
        }));
        setProductArray((p) => ({ ...p, has_more: response?.data?.has_more }));
      })
      .catch((e) => {});
  }

  function searcSubCat(parent_id, newValue) {
    post("/general/category_listing", {
      type: "product_sub_category",
      per_page: 50,
      page: subCatPage + 0,
      search: newValue,
      parent_id: parent_id,
    })
      .then((response) => {
        if (subCatPage === 0) {
          setProductSubCategoryArray((p) => ({
            ...p,
            items: response?.data?.data,
          }));
        } else {
          setProductSubCategoryArray((p) => ({
            ...p,
            items: productSubCategoryArray.items.concat(response?.data?.data),
          }));
        }

        setProductSubCategoryArray((p) => ({
          ...p,
          has_more: response?.data?.has_more,
        }));
        // setProductSubCategoryArray(response.data.data);
      })
      .catch((e) => {
        // alert("this is the error", e);
      });
  }

  return (
    <>
      <ToastContainer />
      <Header home />
      <div className="page">
        <div
          style={{ width: "70%" }}
          className="container-fluid"
          ref={imagesRef}
        >
          <div className="create-post">
            <div
              className="page-heading"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h6>Create Post To Buy</h6>
            </div>
            <form>
              <div style={{ padding: "20px 40px" }}>
                <div className="row" ref={nameRef}>
                  <div class="col-sm">
                    <div className="field-set">
                      <div className="field-set-label">
                        <label>
                          1. Product Details<span className="mendatory">*</span>
                        </label>
                      </div>
                    </div>
                    <div className="form-field">
                      {/* <label className="form-label">Product Name</label> */}
                      <input
                        type="text"
                        value={ProductData?.name}
                        name="size"
                        id="size"
                        className="form-input"
                        placeholder="Enter Product Name "
                        onChange={(e) => {
                          setProductData({
                            ...ProductData,
                            name: e.target.value,
                          });
                          setProductDataErr({ ...ProductDataErr, name: false });
                        }}
                        style={{ borderRadius: "10px" }}
                      />

                      {ProductDataErr?.name === true ? (
                        <FieldValidationError message="Name is required" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="field-set">
                  {/* <div className="field-set-label">
                  <label>
                    Product Categories<span className="mendatory">*</span>
                  </label>
                  <span className="info">
                    Both Must Be Selected
                    <span className="tooltip-icon">
                      <OverlayTrigger
                        key="mobile-tip"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-mobile-tip">
                            Product Categories and Sub Categories
                          </Tooltip>
                        }
                      >
                        <InfoIcon />
                      </OverlayTrigger>
                    </span>
                  </span>
                </div> */}
                  <div className="row" ref={categoryRef}>
                    <div class="col-sm">
                      <div>
                        {/* <label className="form-label">Category</label> */}

                        {/* <AsyncPaginate
                        loadOptions={loadOptions}
                        // options={productArrayCat.map(function (productArray) {
                        //   return {
                        //     value: productArray.id,
                        //     label: productArray.name,
                        //   };
                        // })}
                        onChange={(e) => {
                          // setProductSubCategoryArray([]);
                          setProductData((p) => ({
                            ...p,
                            category_id: e.value,
                          }));
                          subCategory(e.value);
                          setProductDataErr({
                            ...ProductDataErr,
                            category_id: false,
                          });

                          setProductData((p) => ({
                            ...p,
                            sub_category_id: "",
                          }));
                        }}
                        isSearchable={true}
                        placeholder="Select Category"
                        additional={{
                          page: 1,
                        }}
                      /> */}
                        {/* <AsyncPaginate
      
      loadOptions={loadOptions}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      isSearchable={false}
      placeholder="Select Category"
      additional={{
        page: 1,
      }}
    /> */}
                        <Select
                          placeholder="Select Product Category"
                          id="category"
                          onFocus={(e) => {
                            e.preventDefault();
                            intialCat();
                          }}
                          options={
                            productArray?.items &&
                            productArray?.items.map(function (te) {
                              return {
                                value: te.id,
                                label: te.name,
                              };
                            })
                          }
                          onKeyDown={(e) => {
                            setTimeout(() => {
                              searcCat(e.target.value);
                            }, 1000);
                          }}
                          onChange={(e) => {
                            clear();
                            setProductData((p) => ({
                              ...p,
                              category_id: e.value,
                            }));

                            setProductDataErr({
                              ...ProductDataErr,
                              category_id: false,
                            });
                            //  selectInputRef.current.commonProps.setValue("");
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                            setcategoryPage(0);
                            setProductArray((p) => ({ ...p, items: [] }));
                          }}
                          onMenuScrollToBottom={(e) => {
                            if (productArray?.has_more === true) {
                              let p = categoryPage + 1;
                              setcategoryPage(p);
                            }
                          }}
                          styles={customStyles(darkMode)}
                        ></Select>
                        {ProductDataErr?.category_id === true ? (
                          <FieldValidationError message="Please select category "></FieldValidationError>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                    <div class="col-sm">
                      <div className="mb-3">
                        {/* <label className="form-label">Sub Category</label> */}

                        {/* <AsyncPaginate
                        selectRef={selectInputRef}
                        loadOptions={() => {
                          let options = productSubCategoryArray.map(function (
                            productArray
                          ) {
                            return {
                              value: productArray.id,
                              label: productArray.name,
                            };
                          });
                          return {
                            options: options,
                            hasMore: has_moreCat,
                          };
                        }}
                        // options={productArrayCat.map(function (productArray) {
                        //   return {
                        //     value: productArray.id,
                        //     label: productArray.name,
                        //   };
                        // })}
                        onChange={(e) => {
                          setProductData({
                            ...ProductData,
                            sub_category_id: e.value,
                          });

                          setProductDataErr({
                            ...ProductDataErr,
                            sub_category_id: false,
                          });
                        }}
                        isSearchable={true}
                        placeholder="Select Sub Category"
                        additional={{
                          page: 1,
                        }}
                      /> */}
                        <Select
                          ref={refy}
                          id="subCategory"
                          placeholder="Select Product Sub-Category"
                          options={
                            productSubCategoryArray?.items &&
                            productSubCategoryArray?.items.map(function (
                              sub_category
                            ) {
                              return {
                                value: sub_category.id,
                                label: sub_category.name,
                              };
                            })
                          }
                          onFocus={(e) => {
                            e.preventDefault();
                            if (ProductData?.category_id != "") {
                              searcSubCat(ProductData?.category_id);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (ProductData?.category_id != "") {
                              setTimeout(() => {
                                searcSubCat(
                                  ProductData?.category_id,
                                  e.target.value
                                );
                              }, 1000);
                            }
                          }}
                          // onInputChange={(newValue) => {
                          //   searcSubCat(newValue, ProductData?.category_id);
                          // }}
                          onChange={(e) => {
                            setProductData({
                              ...ProductData,
                              sub_category_id: e.value,
                            });

                            setProductDataErr({
                              ...ProductDataErr,
                              sub_category_id: false,
                            });
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                          }}
                          onMenuScrollToBottom={(e) => {
                            if (productSubCategoryArray?.has_more === true) {
                              let p = subCatPage + 1;
                              setsubCatPage(p);
                            }
                          }}
                          styles={customStyles(darkMode)}
                        ></Select>

                        {ProductDataErr?.sub_category_id === true ? (
                          <FieldValidationError message="Please select sub category "></FieldValidationError>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{ marginTop: "20px" }}
                  className="field-set"
                  ref={DifferentiationRef}
                >
                  <div className="field-set-label ">
                    <label>
                      2. Product Differentiation{" "}
                      <span className="mendatory ml-1">
                        (Any one field must be filled)
                      </span>{" "}
                    </label>
                    {/* <span className="info">
                    Any one must be filled. and All or Multiple can be filled
                    <span className="tooltip-icon">
                      <OverlayTrigger
                        key="mobile-tip"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-mobile-tip">
                            Any one must be filled. and All or Multiple can be
                            filled
                          </Tooltip>
                        }
                      >
                        <InfoIcon />
                      </OverlayTrigger>
                    </span>
                  </span> */}
                    {/* <a
                    className="collapse-button collapsed"
                    data-toggle="collapse"
                    href="#collap_OtherDetails2"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collap_OtherDetails2"
                  >
                    <ArrowDownIcon />
                  </a> */}
                  </div>
                  {/* <div className="collapse" id="collap_OtherDetails2"> */}
                  <div>
                    <div className="row">
                      <div class="col-sm">
                        <div className="form-field">
                          {/* <label className="form-label">Size</label> */}
                          <input
                            type="text"
                            value={ProductData?.size}
                            name="size"
                            id="size"
                            className="form-input"
                            placeholder="Product Size"
                            onChange={(e) => {
                              setProductData({
                                ...ProductData,
                                size: e.target.value,
                              });

                              setProductDataErr({
                                ...ProductDataErr,
                                size: false,
                              });
                            }}
                            style={{
                              borderRadius: "10px",
                            }}
                          />{" "}
                        </div>
                      </div>
                      <div class="col-sm">
                        <div>
                          {/* <label className="form-label">Colours</label> */}
                          <Select
                            // ref={selectInputRef}
                            id="color"
                            placeholder="Select Color"
                            onFocus={(e) => {
                              e.preventDefault();
                              IntialColor();
                            }}
                            options={availableColor.map(function (color) {
                              return {
                                value: color.id,
                                label: color.name,
                              };
                            })}
                            onChange={(e) => {
                              setProductData({
                                ...ProductData,
                                color_id: e.value,
                              });

                              setProductDataErr({
                                ...ProductDataErr,
                                size: false,
                              });
                            }}
                            styles={customStyles(darkMode)}
                          ></Select>{" "}
                        </div>
                      </div>

                      <div class="col-sm">
                        <div className="form-field">
                          {/* <label className="form-label">Product Code</label> */}
                          <input
                            type="text"
                            value={ProductData?.code}
                            name="pcode"
                            id="pcode"
                            className="form-input"
                            placeholder="Product Code"
                            onChange={(e) => {
                              setProductData({
                                ...ProductData,
                                code: e.target.value,
                              });

                              setProductDataErr({
                                ...ProductDataErr,
                                size: false,
                              });
                            }}
                            style={{
                              borderRadius: "10px",
                            }}
                          />{" "}
                        </div>
                      </div>

                      <div class="col-sm">
                        <div className="form-field">
                          {/* <label className="form-label d-flex align-items-center justify-content-between">
                          Storage capacity/Weight
                        </label> */}
                          <input
                            type="text"
                            value={ProductData?.capacity}
                            name="capacity"
                            id="capacity"
                            className="form-input"
                            placeholder="Weight / Storage"
                            onChange={(e) => {
                              setProductData({
                                ...ProductData,
                                capacity: e.target.value,
                              });

                              setProductDataErr({
                                ...ProductDataErr,
                                size: false,
                              });
                            }}
                            style={{
                              borderRadius: "10px",
                            }}
                          />{" "}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div class="col-sm">
                        <div className="form-field">
                          {/* <label className="form-label">Shape</label> */}
                          <input
                            type="text"
                            value={ProductData?.shape}
                            name="pshape"
                            id="pshape"
                            className="form-input"
                            placeholder="Product Shape"
                            onChange={(e) => {
                              setProductData({
                                ...ProductData,
                                shape: e.target.value,
                              });

                              setProductDataErr({
                                ...ProductDataErr,
                                size: false,
                              });
                            }}
                            style={{
                              borderRadius: "10px",
                            }}
                          />{" "}
                        </div>
                      </div>

                      <div class="col-sm">
                        <div className="form-field">
                          {/* <label className="form-label">Material Type </label> */}
                          <input
                            type="text"
                            value={ProductData?.material_type}
                            name="type"
                            id="type"
                            className="form-input"
                            placeholder="Material Type"
                            onChange={(e) => {
                              setProductData({
                                ...ProductData,
                                material_type: e.target.value,
                              });

                              setProductDataErr({
                                ...ProductDataErr,
                                size: false,
                              });
                            }}
                            style={{ borderRadius: "10px" }}
                          />{" "}
                        </div>
                      </div>

                      <div class="col-sm">
                        <div>
                          {/* <label className="form-label">Brand</label> */}
                          <Select
                            onFocus={(e) => {
                              e.preventDefault();
                              if (ProductData.sub_category_id === "") {
                              } else {
                                BrandGet(ProductData?.sub_category_id);
                              }
                            }}
                            placeholder="Select Brand"
                            options={BrandArray.map(function (productArray) {
                              return {
                                value: productArray.id,
                                label: productArray.name,
                              };
                            })}
                            onKeyDown={(newValue) => {
                              searcBrand(
                                newValue,
                                ProductData?.sub_category_id
                              );
                            }}
                            onChange={(e) => {
                              setProductData({
                                ...ProductData,
                                brand_id: e.value,
                              });
                              // setBrandArray((oldArray) => [...oldArray,{id: "other",name:"other"}])

                              setProductDataErr({
                                ...ProductDataErr,
                                brand_id: false,
                                size: false,
                              });
                            }}
                            styles={customStyles(darkMode)}
                          ></Select>{" "}
                        </div>
                      </div>
                      {ProductData?.brand_id === "other" ? (
                        <div className="col-sm">
                          <div className="form-field">
                            {/* <label className="form-label">
                            Enter Brand Name{" "}
                          </label> */}
                            <input
                              type="text"
                              onFocus={(e) => {
                                e.preventDefault();
                                BrandGet(ProductData?.sub_category_id);
                              }}
                              onBlur={(e) => {
                                e.preventDefault();
                              }}
                              value={ProductData?.otherBrandName}
                              name="pcode"
                              id="pcode"
                              className="form-input"
                              placeholder="Brand Name "
                              onChange={(e) => {
                                setProductData({
                                  ...ProductData,
                                  otherBrandName: e.target.value,
                                });

                                setProductDataErr({
                                  ...ProductDataErr,
                                  size: false,
                                });
                              }}
                              style={{ borderRadius: "10px" }}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {ProductDataErr?.size === true ? (
                    <FieldValidationError message="Please enter atleast one field to complete " />
                  ) : (
                    ""
                  )}
                </div>

                <div style={{ marginTop: "20px" }} className="field-set">
                  <div className="field-set-label">
                    <label>
                      3. Mandatory Details <span className="mendatory">*</span>
                    </label>
                  </div>
                  <div className="row">
                    <div class="col-sm" ref={qtyRef}>
                      <div className="form-field">
                        {/* <label className="form-label">
                        Required Quantity<span className="mendatory">*</span>
                      </label> */}
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="required_qty"
                          id="required_qty"
                          className="form-input"
                          placeholder="Required Qty"
                          value={ProductData?.order_qty}
                          onChange={(e) => {
                            setProductData((p) => ({
                              ...p,
                              order_qty: e.target.value,
                            }));

                            setProductDataErr((p) => ({ ...p, qty: false }));
                          }}
                          max={"10000000"}
                          style={{ borderRadius: "10px" }}
                        />
                        {ProductDataErr?.qty === true ? (
                          <FieldValidationError message="Please enter valid quantity requirement "></FieldValidationError>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>

                    <div class="col-sm">
                      <div>
                        {/* <label className="form-label">Please select Unit</label> */}

                        <Select
                          placeholder="Select Unit"
                          options={availableUnit?.items?.map(function (unit) {
                            return { value: unit.id, label: unit.name };
                          })}
                          onFocus={(e) => {
                            e.preventDefault();
                            IntialUnit();
                          }}
                          onChange={(e) => {
                            setProductData({
                              ...ProductData,
                              unit_id: e.value,
                            });
                            setProductDataErr({
                              ...ProductDataErr,
                              unit_id: false,
                            });
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                          }}
                          styles={customStyles(darkMode)}
                          // onMenuScrollToBottom={(e) =>{
                          //   setUnitPage((prevPageNumber) => prevPageNumber +1)
                          // }}
                        ></Select>
                      </div>
                    </div>
                    <div class="col-sm" ref={cityRef}>
                      <div>
                        {/* <label className="form-label">
                        Required in City<span className="mendatory">*</span>
                      </label> */}
                        <Select
                          id="city"
                          placeholder="Required in city"
                          onFocus={(e) => {
                            e.preventDefault();
                            intialcity();
                          }}
                          options={productArrayCity.map(function (
                            sub_category
                          ) {
                            return {
                              value: sub_category.id,
                              label: sub_category.name,
                            };
                          })}
                          onKeyDown={(e) => {}}
                          onChange={(e) => {
                            setProductData((p) => ({
                              ...p,
                              city_id: e.value,
                            }));

                            setProductData((p) => ({
                              ...p,
                              city: e.label,
                            }));
                            setProductDataErr({
                              ...ProductDataErr,
                              city_id: false,
                            });
                            setProductDataErr((p) => ({ ...p, city: false }));
                          }}
                          styles={customStyles(darkMode)}
                        ></Select>
                        {ProductDataErr?.city === true ? (
                          <FieldValidationError message="Please select city "></FieldValidationError>
                        ) : (
                          " "
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "20px" }} className="field-set">
                  <div
                    className="field-set-label "
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <a
                      className="collapse-button collapsed"
                      data-toggle="collapse"
                      href="#collap_Pricing"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collap_Pricing"
                    >
                      {/* <img
                        style={{ width: "16px", height: "16px" }}
                        src={ArrowDownIcon}
                      /> */}
                      <IconArrow />
                    </a>
                    <label>
                      <span>4. Pricing </span>
                      <span className="mendatory ml-1">
                        (All fields are optional)
                      </span>
                    </label>
                  </div>
                  <div className="collapse" id="collap_Pricing">
                    <div className="row">
                      <div class="col-sm">
                        <div className="form-field">
                          {/* <label className="form-label">Expected Price</label> */}
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            value={ProductData?.amount}
                            name="advance_payment"
                            id="advance_payment"
                            className="form-input"
                            placeholder="Expected Price"
                            onChange={(e) => {
                              setProductData({
                                ...ProductData,
                                amount: e.target.value,
                              });
                            }}
                            style={{ borderRadius: "10px" }}
                          />{" "}
                        </div>
                      </div>
                      <div class="col-sm">
                        <div className="form-field">
                          {/* <label className="form-label">
                        Preferred Payment Terms
                      </label> */}
                          <select
                            className="form-input"
                            onChange={(e) => {
                              e.preventDefault();
                              setProductData((p) => ({
                                ...p,
                                terms: e.target.value,
                              }));
                            }}
                            placeholder="Preferred Payment Terms"
                            style={{ borderRadius: "10px" }}
                          >
                            <option value={""}>Preferred Payment Terms</option>
                            <option value={"Credit"}>Credit</option>

                            <option value={"Advance"}>Advance</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div class="col-sm">
                        <div className="form-field">
                          {/* <label className="form-label">HSN Code</label> */}
                          <input
                            type="text"
                            value={ProductData?.hsn_code}
                            name="hsn"
                            id="hsn"
                            className="form-input"
                            placeholder="HSN Code"
                            onChange={(e) => {
                              setProductData({
                                ...ProductData,
                                hsn_code: e.target.value,
                              });
                            }}
                            style={{ borderRadius: "10px" }}
                          />{" "}
                          {ProductData?.hsn_code.length >= 1 &&
                          ProductData?.hsn_code?.length !== 8 ? (
                            <FieldValidationError message="Please enter 8 digit HSN code" />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div class="col-sm">
                        <div className="form-field">
                          {/* <label className="form-label">GST Rate</label> */}
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            min={0}
                            max={100}
                            value={ProductData?.gst_rate}
                            name="gst"
                            id="gst"
                            className="form-input"
                            placeholder="GST Rate %"
                            onChange={(e) => {
                              if (
                                !(e.target.value >= 0 && e.target.value <= 100)
                              ) {
                                return false;
                              }
                              setProductData({
                                ...ProductData,
                                gst_rate: e.target.value,
                              });
                            }}
                            style={{ borderRadius: "10px" }}
                          />{" "}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div class="col-sm" ref={preRef}>
                        <div className="form-field">
                          {/* <label className="form-label">
                          Preferred Transport
                        </label> */}
                          <input
                            type="text"
                            name="required_qty"
                            id="required_qty"
                            className="form-input"
                            placeholder="Preferred Transport"
                            value={ProductData?.preferred_transport}
                            onChange={(e) => {
                              setProductData({
                                ...ProductData,
                                preferred_transport: e.target.value,
                              });
                              setProductDataErr((p) => ({
                                ...p,
                                preferred_transport: false,
                              }));
                            }}
                            style={{ borderRadius: "10px" }}
                          />
                          {ProductDataErr?.preferred_transport === true ? (
                            <FieldValidationError message="Please enter your preferred transport "></FieldValidationError>
                          ) : (
                            " "
                          )}
                        </div>
                      </div>
                      <div class="col-sm" ref={dayRef}>
                        <div className="form-field">
                          {/* <label className="form-label">
                          Required in Nos. of Days{" "}
                          <span className="mendatory">*</span>
                        </label> */}
                          <input
                            type="text"
                            name="required_days"
                            id="required_days"
                            className="form-input"
                            placeholder="Required in Nos of Days"
                            value={ProductData?.required_in_days}
                            onChange={(e) => {
                              setProductData({
                                ...ProductData,
                                required_in_days: e.target.value,
                              });
                              setProductDataErr((p) => ({
                                ...p,
                                required_in_days: false,
                              }));
                            }}
                            style={{ borderRadius: "10px" }}
                          />
                          {ProductDataErr?.required_in_days === true ? (
                            <FieldValidationError message="Please Enter required days "></FieldValidationError>
                          ) : (
                            " "
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div class="col-sm">
                        <div className="form-field">
                          <textarea
                            value={ProductData?.description}
                            name="description"
                            id="description"
                            className="form-control"
                            placeholder="Other description (150 words max)"
                            onChange={(e) => {
                              setProductData({
                                ...ProductData,
                                description: e.target.value,
                              });
                              setProductDataErr({
                                ...ProductDataErr,
                                description: false,
                              });
                            }}
                            rows={2}
                            style={{
                              borderRadius: "10px",
                              backgroundColor: darkMode ? "#1c2226" : "#fff",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="field-set">
                <div className="field-set-label">
                  <label>Tax Details</label>
                  <a
                    className="collapse-button collapsed"
                    data-toggle="collapse"
                    href="#collap_tax"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collap_tax"
                  >
                    <ArrowDownIcon />
                  </a>
                </div>
                <div className="collapse" id="collap_tax"></div>
              </div> */}

                {/* <div className="field-set">
                <div className="field-set-label">
                  <label>Other Details</label>
                  <a
                    className="collapse-button collapsed"
                    data-toggle="collapse"
                    href="#collap_OtherDetails"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collap_OtherDetails"
                  >
                    <ArrowDownIcon />
                  </a>
                </div>
                <div className="collapse" id="collap_OtherDetails">
                  <div className="row">
                    <div class="col-sm">
                      <div className="form-field">
                        <label className="form-label">Required Qty</label>
                        <input
                          type="text"
                          name="order_qty"
                          id="order_qty"
                          className="form-input"
                          placeholder="Enter Value"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-6">
                      <div className="form-field">
                        <label className="form-label">Quality Standard</label>

                        <input
                          type="text"
                          name="order_qty"
                          id="order_qty"
                          className="form-input"
                          placeholder="Enter quality standard"
                          value={ProductData?.quality_standard}
                          onChange={(e) => {
                            e.preventDefault();
                            setProductData((p) => ({
                              ...p,
                              quality_standard: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div> */}

                <div style={{ marginTop: "20px" }} className="field-set">
                  <div className="field-set-label">
                    <label>5. Select your post template </label>
                  </div>
                  <div className="row" ref={templateRef}>
                    <div class="col-sm">
                      <div>
                        {/* <label className="form-label">
                        Post Template <span className="mendatory">*</span>{" "}
                      </label> */}

                        <Select
                          placeholder="Post Templates"
                          id="Template "
                          onFocus={(e) => {
                            e.preventDefault();
                            TemplateFunction();
                          }}
                          options={templateArray.map(function (productArray) {
                            return {
                              value: productArray.id,
                              label: productArray.content,
                            };
                          })}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          onChange={(e) => {
                            setProductData((p) => ({
                              ...p,
                              template: e.value,
                            }));

                            setProductDataErr({
                              ...ProductDataErr,
                              template: false,
                            });
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                          }}
                          styles={{
                            ...customStyles(darkMode),
                            menu: (provided) => ({
                              ...provided,
                              borderRadius: "8px", // Rounded corners for the dropdown
                              marginTop: "0px", // No margin between the input and the dropdown
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow for the dropdown
                              maxHeight: "300px", // Fixed height for the dropdown
                              overflowY: "auto",
                              background: darkMode ? "#1C2226" : "#fff", // Background color for dropdown in dark mode
                              color: darkMode ? "#fff" : "#000",
                            }),
                          }}
                        ></Select>
                        {ProductDataErr?.template === true ? (
                          <FieldValidationError message="Please select post template "></FieldValidationError>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                    {/* <div class="col-6 ">
                  <div className="form-field">
                    <label className="form-label">
                      Product Catelogue 
                    </label>
                    <div className="file-upload">
                        <input
                          type="file"
                          id="upload_certificate"
                          accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf,application/msword"
                          onChange={(e) => {
                            if (
                              !(
                                e.target.files[0].type === "image/png" ||
                                e.target.files[0].type === "image/jpg" ||
                                e.target.files[0].type === "image/jpeg" ||
                                e.target.files[0].type === "image/gif" ||
                                e.target.files[0].type === "application/pdf" ||
                                e.target.files[0].type ===
                                  "application/msword" ||
                                e.target.files[0].type ===
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              )
                            ) {
                              alert("Only images,pdf,doc,docx allowded");
                              return false;
                            }
                            if (e.target.files[0].size > 2097152) {
                              alert("Not More than 2 MB is allowded");
                              return false;
                            }
                            setProductData({
                              ...ProductData,
                              product_catalogue: e.target.files[0],
                            });
                          }}
                        />
                        <label htmlFor="upload_certificate">
                          {ProductData?.product_catalogue?.name
                            ? ProductData?.product_catalogue?.name
                            : "Browse"}
                          <span className="icon">
                            <AttachmentIcon />
                          </span>
                        </label>
                      </div>
                   
                 
                  </div>
                </div> */}
                  </div>
                </div>

                <div style={{ marginTop: "20px" }} className="field-set">
                  <div
                    className="field-set-label"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <a
                      className="collapse-button collapsed"
                      data-toggle="collapse"
                      href="#collap_Images"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collap_Images"
                    >
                      {/* <img
                        style={{ width: "16px", height: "16px" }}
                        src={ArrowDownIcon}
                      /> */}
                      <IconArrow />
                    </a>
                    <label>
                      6. Attach Product Images{" "}
                      <span className="mendatory ml-1">
                        (Optional to upload)
                      </span>
                    </label>
                    {/* <span className="info">
                    Max 6 (Optional)
                    <span className="tooltip-icon">
                      <OverlayTrigger
                        key="mobile-tip"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-mobile-tip">Max 6</Tooltip>
                        }
                      >
                        <InfoIcon />
                      </OverlayTrigger>
                    </span>
                  </span> */}
                  </div>
                  <div className="collapse" id="collap_Images">
                    <div className="row">
                      <div class="col-sm-2">
                        <div className="form-field">
                          <div className="file-upload upload-prod-image">
                            <input
                              type="file"
                              accept="image/*"
                              id="upload_prod_img1"
                              onChange={(e) => {
                                if (
                                  !(
                                    e.target.files[0].type === "image/png" ||
                                    e.target.files[0].type === "image/jpg" ||
                                    e.target.files[0].type === "image/jpeg" ||
                                    e.target.files[0].type === "image/gif" ||
                                    e.target.files[0].type === "image/svg"
                                  )
                                ) {
                                  alert("Only images allowed");
                                  return false;
                                }
                                if (e.target.files[0].size > 2097152) {
                                  alert("Not More than 2 MB is allowded");
                                  return false;
                                }
                                setProductData({
                                  ...ProductData,
                                  Front_Image: URL.createObjectURL(
                                    e.target.files[0]
                                  ),
                                });
                                setProductDataErr({
                                  ...ProductDataErr,
                                  Front_Image: false,
                                });
                              }}
                            />

                            {ProductData?.Front_Image === "" ? (
                              <label htmlFor="upload_prod_img1">
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    padding: "15px",
                                  }}
                                  src={ProductImagePlaceholder}
                                />
                                <div className="image-type">Front</div>
                              </label>
                            ) : (
                              <>
                                <label>
                                  <img
                                    style={{
                                      width: "80px",
                                      height: "80px",
                                    }}
                                    src={ProductData.Front_Image}
                                    // onImageLoaded={setImage}
                                  />

                                  <img
                                    src={Cancel}
                                    className="cancel-button"
                                    style={{ height: "20px", width: "20px" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setProductData({
                                        ...ProductData,
                                        Front_Image: "",
                                      });
                                    }}
                                  />

                                  <div className="image-type">Front</div>

                                  <span
                                    onClick={(e) => {
                                      setShow(true);

                                      setCroppedImages((p) => ({
                                        ...p,
                                        Front_Image: true,
                                      }));
                                    }}
                                  >
                                    <EditIcon />
                                  </span>
                                </label>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col-sm">
                        <div className="form-field">
                          <div className="file-upload upload-prod-image">
                            <input
                              type="file"
                              accept="image/*"
                              id="upload_prod_img2"
                              onChange={(e) => {
                                if (
                                  !(
                                    e.target.files[0].type === "image/png" ||
                                    e.target.files[0].type === "image/jpg" ||
                                    e.target.files[0].type === "image/jpeg" ||
                                    e.target.files[0].type === "image/gif" ||
                                    e.target.files[0].type === "image/svg"
                                  )
                                ) {
                                  alert("Only images allowed");
                                  return false;
                                }
                                if (e.target.files[0].size > 2097152) {
                                  alert("Not More than 2 MB is allowded");
                                  return false;
                                }
                                setProductData({
                                  ...ProductData,
                                  Back_Image: URL.createObjectURL(
                                    e.target.files[0]
                                  ),
                                });
                                setProductDataErr({
                                  ...ProductDataErr,
                                  Front_Image: false,
                                });
                              }}
                            />

                            {ProductData?.Back_Image === "" ? (
                              <label htmlFor="upload_prod_img2">
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    padding: "15px",
                                  }}
                                  src={ProductImagePlaceholder}
                                />
                                <div className="image-type">Back</div>
                              </label>
                            ) : (
                              <label>
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                  }}
                                  src={ProductData?.Back_Image}
                                  // onImageLoaded={setImage}
                                />

                                <img
                                  src={Cancel}
                                  className="cancel-button"
                                  style={{ height: "20px", width: "20px" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setProductData({
                                      ...ProductData,
                                      Back_Image: "",
                                    });
                                  }}
                                />
                                {/* <button
                              className="cancel-button"
                              onClick={(e) => {
                                e.preventDefault();
                                setProductData({
                                  ...ProductData,
                                  Front_Image: "",
                                });
                              }}
                            >
                              <img src={Cancel} />
                            </button> */}
                                <div className="image-type">Back</div>

                                <span
                                  onClick={(e) => {
                                    setShow(true);
                                    setCroppedImages((p) => ({
                                      ...p,
                                      Back_Image: true,
                                    }));
                                  }}
                                >
                                  <EditIcon />
                                </span>
                              </label>
                            )}
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-2">
                        <div className="form-field">
                          <div className="file-upload upload-prod-image">
                            <input
                              type="file"
                              accept="image/*"
                              id="upload_prod_img3"
                              onChange={(e) => {
                                if (
                                  !(
                                    e.target.files[0].type === "image/png" ||
                                    e.target.files[0].type === "image/jpg" ||
                                    e.target.files[0].type === "image/jpeg" ||
                                    e.target.files[0].type === "image/gif" ||
                                    e.target.files[0].type === "image/svg"
                                  )
                                ) {
                                  alert("Only images allowed");
                                  return false;
                                }
                                if (e.target.files[0].size > 2097152) {
                                  alert("Not More than 2 MB is allowded");
                                  return false;
                                }
                                setProductData({
                                  ...ProductData,
                                  Left_Image: URL.createObjectURL(
                                    e.target.files[0]
                                  ),
                                });
                                setProductDataErr({
                                  ...ProductDataErr,
                                  Front_Image: false,
                                });
                              }}
                            />

                            {ProductData?.Left_Image === "" ? (
                              <label htmlFor="upload_prod_img3">
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    padding: "15px",
                                  }}
                                  src={ProductImagePlaceholder}
                                />
                                <div className="image-type">Left</div>
                              </label>
                            ) : (
                              <label>
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                  }}
                                  src={ProductData?.Left_Image}
                                  // onImageLoaded={setImage}
                                />

                                <img
                                  src={Cancel}
                                  className="cancel-button"
                                  style={{ height: "20px", width: "20px" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setProductData({
                                      ...ProductData,
                                      Left_Image: "",
                                    });
                                  }}
                                />
                                {/* <button
                              className="cancel-button"
                              onClick={(e) => {
                                e.preventDefault();
                                setProductData({
                                  ...ProductData,
                                  Front_Image: "",
                                });
                              }}
                            >
                              <img src={Cancel} />
                            </button> */}
                                <div className="image-type">Left</div>

                                <span
                                  onClick={(e) => {
                                    setShow(true);
                                    setCroppedImages((p) => ({
                                      ...p,
                                      Left_Image: true,
                                    }));
                                  }}
                                >
                                  <EditIcon />
                                </span>
                              </label>
                            )}
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-2">
                        <div className="form-field">
                          <div className="file-upload upload-prod-image">
                            <input
                              type="file"
                              accept="image/*"
                              id="upload_prod_img4"
                              onChange={(e) => {
                                if (
                                  !(
                                    e.target.files[0].type === "image/png" ||
                                    e.target.files[0].type === "image/jpg" ||
                                    e.target.files[0].type === "image/jpeg" ||
                                    e.target.files[0].type === "image/gif" ||
                                    e.target.files[0].type === "image/svg"
                                  )
                                ) {
                                  alert("Only images allowed");
                                  return false;
                                }
                                if (e.target.files[0].size > 2097152) {
                                  alert("Not More than 2 MB is allowded");
                                  return false;
                                }
                                setProductData({
                                  ...ProductData,
                                  Right_Image: URL.createObjectURL(
                                    e.target.files[0]
                                  ),
                                });
                                setProductDataErr({
                                  ...ProductDataErr,
                                  Front_Image: false,
                                });
                              }}
                            />

                            {ProductData?.Right_Image === "" ? (
                              <label htmlFor="upload_prod_img4">
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    padding: "15px",
                                  }}
                                  src={ProductImagePlaceholder}
                                />
                                <div className="image-type">Right</div>
                              </label>
                            ) : (
                              <label>
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                  }}
                                  src={ProductData?.Right_Image}
                                  // onImageLoaded={setImage}
                                />

                                <img
                                  src={Cancel}
                                  className="cancel-button"
                                  style={{ height: "20px", width: "20px" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setProductData({
                                      ...ProductData,
                                      Right_Image: "",
                                    });
                                  }}
                                />

                                {/* <button
                              className="cancel-button"
                              onClick={(e) => {
                                e.preventDefault();
                                setProductData({
                                  ...ProductData,
                                  Front_Image: "",
                                });
                              }}
                            >
                              <img src={Cancel} />
                            </button> */}
                                <div className="image-type">Right</div>

                                <span
                                  onClick={(e) => {
                                    setShow(true);
                                    setCroppedImages((p) => ({
                                      ...p,
                                      Right_Image: true,
                                    }));
                                  }}
                                >
                                  <EditIcon />
                                </span>
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div className="form-field">
                          <div className="file-upload upload-prod-image">
                            <input
                              type="file"
                              accept="image/*"
                              id="upload_prod_img5"
                              onChange={(e) => {
                                if (
                                  !(
                                    e.target.files[0].type === "image/png" ||
                                    e.target.files[0].type === "image/jpg" ||
                                    e.target.files[0].type === "image/jpeg" ||
                                    e.target.files[0].type === "image/gif" ||
                                    e.target.files[0].type === "image/svg"
                                  )
                                ) {
                                  alert("Only images allowed");
                                  return false;
                                }
                                if (e.target.files[0].size > 2097152) {
                                  alert("Not More than 2 MB is allowded");
                                  return false;
                                }
                                setProductData({
                                  ...ProductData,
                                  Upper_Image: URL.createObjectURL(
                                    e.target.files[0]
                                  ),
                                });
                                setProductDataErr({
                                  ...ProductDataErr,
                                  Front_Image: false,
                                });
                              }}
                            />

                            {ProductData?.Upper_Image === "" ? (
                              <label htmlFor="upload_prod_img5">
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    padding: "15px",
                                  }}
                                  src={ProductImagePlaceholder}
                                />
                                <div className="image-type">Upper</div>
                              </label>
                            ) : (
                              <label>
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                  }}
                                  src={ProductData?.Upper_Image}
                                  // onImageLoaded={setImage}
                                />

                                <img
                                  src={Cancel}
                                  className="cancel-button"
                                  style={{ height: "20px", width: "20px" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setProductData({
                                      ...ProductData,
                                      Upper_Image: "",
                                    });
                                  }}
                                />
                                {/* <button
                              className="cancel-button"
                              onClick={(e) => {
                                e.preventDefault();
                                setProductData({
                                  ...ProductData,
                                  Front_Image: "",
                                });
                              }}
                            >
                              <img src={Cancel} />
                            </button> */}
                                <div className="image-type">Upper</div>

                                <span
                                  onClick={(e) => {
                                    setShow(true);
                                    setCroppedImages((p) => ({
                                      ...p,
                                      Upper_Image: true,
                                    }));
                                  }}
                                >
                                  <EditIcon />
                                </span>
                              </label>
                            )}
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-2">
                        <div className="form-field">
                          <div className="file-upload upload-prod-image">
                            <input
                              type="file"
                              accept="image/*"
                              id="upload_prod_img6"
                              onChange={(e) => {
                                if (
                                  !(
                                    e.target.files[0].type === "image/png" ||
                                    e.target.files[0].type === "image/jpg" ||
                                    e.target.files[0].type === "image/jpeg" ||
                                    e.target.files[0].type === "image/gif" ||
                                    e.target.files[0].type === "image/svg"
                                  )
                                ) {
                                  alert("Only images allowed");
                                  return false;
                                }
                                if (e.target.files[0].size > 2097152) {
                                  alert("Not More than 2 MB is allowded");
                                  return false;
                                }
                                setProductData({
                                  ...ProductData,
                                  Lower_Image: URL.createObjectURL(
                                    e.target.files[0]
                                  ),
                                });
                                setProductDataErr({
                                  ...ProductDataErr,
                                  Front_Image: false,
                                });
                              }}
                            />

                            {ProductData?.Lower_Image === "" ? (
                              <label htmlFor="upload_prod_img6">
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    padding: "15px",
                                  }}
                                  src={ProductImagePlaceholder}
                                />
                                <div className="image-type">Lower</div>
                              </label>
                            ) : (
                              <label>
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                  }}
                                  src={ProductData?.Lower_Image}
                                  // onImageLoaded={setImage}
                                />

                                <img
                                  src={Cancel}
                                  className="cancel-button"
                                  style={{ height: "20px", width: "20px" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setProductData({
                                      ...ProductData,
                                      Lower_Image: "",
                                    });
                                  }}
                                />

                                {/* <button
                              className="cancel-button"
                              onClick={(e) => {
                                e.preventDefault();
                                setProductData({
                                  ...ProductData,
                                  Front_Image: "",
                                });
                              }}
                            >
                              <img src={Cancel} />
                            </button> */}
                                <div className="image-type">Lower</div>

                                <span
                                  onClick={(e) => {
                                    setShow(true);
                                    setCroppedImages((p) => ({
                                      ...p,
                                      Lower_Image: true,
                                    }));
                                  }}
                                >
                                  <EditIcon />
                                </span>
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      {ProductDataErr?.Front_Image === true ? (
                        <FieldValidationError message="Please add atleast one image"></FieldValidationError>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{ marginTop: "20px" }}
                  className="row protfolio-boxes"
                >
                  <div class="col-sm-4">
                    <div className="chips chips--white">
                      <div className="chips--text">Post Hide From</div>
                      <div className="chips--value">
                        <span className="color-red">
                          {ProductData?.resticate.length}
                        </span>{" "}
                        Users
                      </div>
                      <button
                        className="button-edit"
                        data-toggle="modal"
                        data-target="#PostHideModal"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <EditIcon />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="form-button">
                  <button
                    className="button button-primary"
                    style={{ borderRadius: "5px" }}
                    onClick={(e) => {
                      createPostToBuy(e);
                    }}
                  >
                    {Loading === true && !ProductDataErr ? (
                      <PuffLoader />
                    ) : (
                      " Submit and Post"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <PostHideFrom postHideArray={postHideArray} />

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Title>
            <h3>Crop Your Image</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div
              className="col-lg-12 col-md-12 col-12"
              style={{ height: "60vh" }}
            >
              <Cropper
                image={
                  croppedImages?.Front_Image === true
                    ? ProductData?.Front_Image
                    : croppedImages?.Back_Image === true
                    ? ProductData?.Back_Image
                    : croppedImages?.Left_Image === true
                    ? ProductData?.Left_Image
                    : croppedImages?.Right_Image === true
                    ? ProductData?.Right_Image
                    : croppedImages?.Upper_Image === true
                    ? ProductData?.Upper_Image
                    : croppedImages?.Lower_Image === true
                    ? ProductData?.Lower_Image
                    : ""
                }
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                zoomSpeed={4}
                maxZoom={3}
                zoomWithScroll={true}
                showGrid={true}
                aspect={4 / 3}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
              />
            </div>
          </div>
          {/* <button
            style={{
              display: "block",
            }}
            onClick={showCroppedImage}
          >
            Preview
          </button>
          <div className="cropped-image-container">
            {croppedImage && (
              <img className="cropped-image" src={croppedImage} alt="cropped" />
            )}
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              if (croppedImages?.Front_Image === true) {
                setCroppedImages((p) => ({ ...p, Front_Image: false }));
              }

              if (croppedImages?.Back_Image === true) {
                setCroppedImages((p) => ({ ...p, Back_Image: false }));
              }

              if (croppedImages?.Left_Image === true) {
                setCroppedImages((p) => ({ ...p, Left_Image: false }));
              }
              if (croppedImages?.Lower_Image === true) {
                setCroppedImages((p) => ({ ...p, Lower_Image: false }));
              }
              if (croppedImages?.Right_Image === true) {
                setCroppedImages((p) => ({ ...p, Right_Image: false }));
              }
              if (croppedImages?.Upper_Image === true) {
                setCroppedImages((p) => ({ ...p, Upper_Image: false }));
              }
              showCroppedImage();
              handleClose();
            }}
          >
            Save{" "}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <PriceHideModal />
    </>
  );
}

export default CreatePostToBuy;
