import React from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import swal from "@sweetalert/with-react";
import { useNavigate } from "react-router-dom";

const DeleteTransaction = ({ id }) => {
  const navigate = useNavigate();

  const cancelTransaction = async (id) => {
    await get(`/short_order/cancel/${id}`, getAuthConfig())
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("SOID");
          toast.success("Order Deleted Successfully");
          navigate("/transaction");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div class="button-cancel">
      <Button
        className="button button-primary"
        variant="secondary"
        onClick={(e) => {
          swal({
            title: "Are you sure?",
            text: "Are you sure want to cancel this transaction?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
          }).then((willDelete) => {
            if (willDelete) {
              cancelTransaction(id);
            }
          });
        }}
        style={{ padding: "8px 15px" }}
      >
        Cancel Transaction
      </Button>
    </div>
  );
};

export default DeleteTransaction;
