import React, { useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import moment from "moment";
import Header from "../common/header";
import Breadcrumb from "../common/breadcrumb";
import { ReactComponent as AttachmentIcon } from "../assets/images/attachment.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ShortOrderStepper from "../common/Stepper/ShortOrderStepper";
import { ToastContainer, toast } from "react-toastify";
import { get, getAuthConfig, post } from "../libs/http-hydrate";

function ProductMaterialDispatch() {
  const [material, setMaterial] = useState({
    order_id: "",
    transporter: "",
    docket_number: "",
    vehicle_number: "",
    shipped_at: "",
  });
  const [image, setImage] = useState();
  const [shortOrderDetails, setShortOrderDetails] = useState();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);

  const parentToChild = (slug) => {
    setUserData(slug);
  };

  let currentStep;

  const location = useLocation();
  const navigate = useNavigate();

  if (!localStorage.getItem("paymentType")) {
    localStorage.setItem("paymentType", shortOrderDetails.payment_type);
  }

  const payment = localStorage.getItem("paymentType");

  const getProductCartSO = async () => {
    if (location?.state?.id) {
      setLoading(true);

      await get(`/short_order/details/${location?.state?.id}`, getAuthConfig())
        .then((res) => {
          setLoading(false);
          setShortOrderDetails(res?.data?.data);
          localStorage.setItem("paymentType", res?.data?.data?.payment_type);
          // setProducts(res?.data?.data?.items);
        })
        .catch((err) => {
          setLoading(false);
          if (
            err.response.status === 400 &&
            err?.response?.data?.message === "Short order not found"
          ) {
            navigate("/");
          }
        });
    }
  };

  useEffect(() => {
    getProductCartSO();
  }, [location?.state?.id]);

  currentStep = payment === "advance" ? 8 : 6;

  async function materialDispatch() {
    if (shortOrderDetails?.docket_detail?.shipped_at)
      return navigate("/product-delivered", {
        state: { id: shortOrderDetails?.id, route: location?.state?.route },
      });
    if (userData?.data?.is_seller === 0) return;
    const formData = new FormData();
    formData.append("order_id", shortOrderDetails?.id);
    formData.append(
      "transporter",
      shortOrderDetails?.docket_detail?.transporter
        ? shortOrderDetails?.docket_detail?.transporter
        : material?.transporter
    );
    formData.append(
      "docket_number",
      shortOrderDetails?.docket_detail?.docket_number
        ? shortOrderDetails?.docket_detail?.docket_number
        : material?.docket_number
    );
    formData.append(
      "shipped_at",
      moment(material?.shipped_at).format("YYYY-MM-DD HH:mm:ss")
    );
    if (
      material?.vehicle_number ||
      shortOrderDetails?.docket_detail?.vehicle_number
    ) {
      formData.append(
        "vehicle_number",
        shortOrderDetails?.docket_detail?.vehicle_number
          ? shortOrderDetails?.docket_detail?.vehicle_number
          : material?.vehicle_number
      );
    }
    if (image?.name) {
      formData.append("attachment", image);
    }
    try {
      const response = await post(
        "/short_order/update/material-dispatch",
        formData,
        getAuthConfig()
      );
      if (response.status !== 200) return;
      if (response.status === 200) {
        navigate("/product-delivered", {
          state: {
            id: shortOrderDetails?.id,
            route: location?.state?.route,
          },
        });
        toast.success("Docket details added successfully");
      }
    } catch (error) {
      if (error.response.data.message.includes("shipped")) {
        toast.error("Shipment Date is required");
      } else {
        toast.error(error.response.data.message);
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMaterial((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const currentStepBuyer = currentStep - 1;

  return (
    <>
      <ToastContainer />
      <Header home userType={parentToChild} disableSwitch />

      <div className="grey-bg">
        <div className="container-fluid order-page">
          <Breadcrumb shortOrderDetails={shortOrderDetails} />
          <div className="steps">
            <ShortOrderStepper
              currentStep={currentStep}
              isCompleted={
                userData?.data?.is_seller === 1
                  ? shortOrderDetails?.step?.current_step
                  : currentStepBuyer
              }
            />
          </div>
          <div className="order product-order bank-detail-page">
            <div style={{ height: "50px" }} className="page-title">
              <h6>Dockets Details - (Seller)</h6>
            </div>
            <div
              className={`company-detail bank-detail ${
                userData?.data?.is_seller === 0 &&
                ((shortOrderDetails?.step?.current_step <= 5 &&
                  shortOrderDetails?.payment_type === "credit") ||
                  (shortOrderDetails?.step?.current_step <= 7 &&
                    shortOrderDetails?.payment_type === "advance")) &&
                "blurred-card"
              }`}
            >
              {userData?.data?.is_seller === 0 &&
                ((shortOrderDetails?.step?.current_step <= 5 &&
                  shortOrderDetails?.payment_type === "credit") ||
                  (shortOrderDetails?.step?.current_step <= 7 &&
                    shortOrderDetails?.payment_type === "advance")) && (
                  <div className="overlay"></div>
                )}
              <div className="row">
                <div className="form-field col-sm">
                  <label className="form-label">
                    Transporter<span className="mendatory">*</span>
                  </label>
                  <input
                    type="text"
                    name="transporter"
                    id="Transporter"
                    className="form-input"
                    readOnly={
                      (shortOrderDetails?.step?.current_step >= 6 &&
                        shortOrderDetails?.payment_type === "credit") ||
                      (shortOrderDetails?.step?.current_step >= 8 &&
                        shortOrderDetails?.payment_type === "advance")
                        ? true
                        : false
                    }
                    placeholder="Enter Your Transporter Name"
                    value={
                      shortOrderDetails?.docket_detail?.transporter
                        ? shortOrderDetails?.docket_detail?.transporter
                        : material?.transporter
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className="form-field col-sm">
                  <label className="form-label">
                    Docket Number<span className="mendatory">*</span>
                  </label>
                  <input
                    type="text"
                    name="docket_number"
                    id="docket_num"
                    className="form-input"
                    readOnly={
                      (shortOrderDetails?.step?.current_step >= 6 &&
                        shortOrderDetails?.payment_type === "credit") ||
                      (shortOrderDetails?.step?.current_step >= 8 &&
                        shortOrderDetails?.payment_type === "advance")
                        ? true
                        : false
                    }
                    placeholder="Enter Your Docket Number"
                    value={
                      shortOrderDetails?.docket_detail?.docket_number
                        ? shortOrderDetails?.docket_detail?.docket_number
                        : material?.docket_number
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-field col-sm">
                  <label className="form-label">
                    Shipment Date<span className="mendatory">*</span>
                  </label>
                  <input
                    type={`${
                      shortOrderDetails?.docket_detail?.shipped_at
                        ? "text"
                        : "date"
                    }`}
                    name="shipped_at"
                    id="sdate"
                    className="form-input"
                    readOnly={
                      (shortOrderDetails?.step?.current_step >= 6 &&
                        shortOrderDetails?.payment_type === "credit") ||
                      (shortOrderDetails?.step?.current_step >= 8 &&
                        shortOrderDetails?.payment_type === "advance")
                        ? true
                        : false
                    }
                    placeholder="Please Select Date"
                    value={
                      shortOrderDetails?.docket_detail?.shipped_at
                        ? moment(
                            shortOrderDetails?.docket_detail?.shipped_at
                          ).format("DD-MM-YYYY")
                        : material?.shipped_at
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className="form-field col-sm">
                  <label className="form-label">Attach File</label>
                  <div className="file-upload">
                    <input
                      type="file"
                      disabled={
                        (shortOrderDetails?.step?.current_step >= 6 &&
                          shortOrderDetails?.payment_type === "credit") ||
                        (shortOrderDetails?.step?.current_step >= 8 &&
                          shortOrderDetails?.payment_type === "advance")
                          ? true
                          : false
                      }
                      // value={shortOrderDetails?.docket_detail?.attachment}
                      id="upload_attachement"
                      name="attachment"
                      onChange={(e) => {
                        if (
                          !(
                            e.target.files[0].type === "image/png" ||
                            e.target.files[0].type === "image/jpg" ||
                            e.target.files[0].type === "image/jpeg" ||
                            e.target.files[0].type === "image/gif" ||
                            e.target.files[0].type === "application/pdf" ||
                            e.target.files[0].type === "application/msword" ||
                            e.target.files[0].type ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          )
                        ) {
                          alert("Only images,pdf,doc,docx allowded");
                          return false;
                        }
                        if (e.target.files[0].size > 2097152) {
                          alert("Not More than 2 MB is allowded");
                          return false;
                        }
                        setImage(e.target.files[0]);
                      }}
                    />
                    <label htmlFor="upload_attachement">
                      {image?.name
                        ? image?.name
                        : `${
                            shortOrderDetails?.docket_detail?.attachment
                              ? "View Attachment"
                              : "Please choose your attachment file"
                          }`}
                      {shortOrderDetails?.docket_detail?.attachment ? (
                        <>
                          <span className="icon">
                            <a
                              href={
                                shortOrderDetails?.docket_detail?.attachment
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              View
                            </a>
                          </span>
                        </>
                      ) : (
                        <span className="icon">
                          <AttachmentIcon />{" "}
                        </span>
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-field col-sm">
                  <label className="form-label">
                    Vehicle Number (GJ01BL4567)
                  </label>
                  <input
                    type="text"
                    readOnly={
                      (shortOrderDetails?.step?.current_step >= 6 &&
                        shortOrderDetails?.payment_type === "credit") ||
                      (shortOrderDetails?.step?.current_step >= 8 &&
                        shortOrderDetails?.payment_type === "advance")
                        ? true
                        : false
                    }
                    name="vehicle_number"
                    id="sdate"
                    className="form-input"
                    placeholder="Enter Vehicle Number "
                    value={
                      shortOrderDetails?.docket_detail?.vehicle_number
                        ? shortOrderDetails?.docket_detail?.vehicle_number
                        : material?.vehicle_number
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            {!loading && (
              <>
                {userData?.data?.is_seller === 0 &&
                  ((shortOrderDetails?.step?.current_step <= 5 &&
                    shortOrderDetails?.payment_type === "credit") ||
                    (shortOrderDetails?.step?.current_step <= 7 &&
                      shortOrderDetails?.payment_type === "advance")) && (
                    <div
                      className="note"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "35px",
                        color: "red",
                      }}
                    >
                      <p>Wait until seller dispatch material for this order.</p>
                    </div>
                  )}
                <div
                  style={{
                    height: "60px",
                  }}
                  className="btn-wrap"
                >
                  <Link
                    state={{
                      id: shortOrderDetails?.id,
                      route: location?.state?.route,
                    }}
                    to="/product-e-bill"
                    className="button button-blue"
                  >
                    Previous
                  </Link>
                  <button
                    onClick={materialDispatch}
                    className="button button-primary"
                    disabled={
                      userData?.data?.is_seller === 0 &&
                      ((shortOrderDetails?.step?.current_step <= 5 &&
                        shortOrderDetails?.payment_type === "credit") ||
                        (shortOrderDetails?.step?.current_step <= 7 &&
                          shortOrderDetails?.payment_type === "advance"))
                        ? true
                        : false
                    }
                  >
                    Next
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductMaterialDispatch;
