import React, { useEffect, useState, useRef, useCallback } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../common/scss/pages/home.scss";
import "../common/scss/pages/request.scss";
import Header from "../common/header";
import { Link, useParams } from "react-router-dom";
import LeftSideBar from "./LeftSideBar";
import RightSideBar from "./RightSideBar";
import { get, getAuthConfig } from "../libs/http-hydrate";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import ConnectionFeeds from "./connection-feeds";
import Auth from "../libs/auth";

const ConnectionFeedsMain = () => {
  const currentUser = Auth.getCurrentUser();

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [TimeLine_usersPage, setTimeLine_usersPage] = useState(0);
  const [loggedUserDetails, setLoggedUserDetails] = useState(null);

  // const settingsPost = {
  //   arrows: true,
  //   dots: false,
  //   infinite: false,
  //   autoplay: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  // useEffect(() => {
  //   if (TimeLine_usersPage === 0) {
  //   } else {
  //     postData();
  //   }
  // }, [TimeLine_usersPage]);

  const decapitalize = ([first, ...rest], upperRest = false) => {
    const lowercaseString =
      first.toLowerCase() +
      (upperRest ? rest.join("").toUpperCase() : rest.join(""));
    return lowercaseString.replaceAll(" ", "");
  };

  const fetchAllLoggedUserDetails = async () => {
    if (currentUser?.slug) {
      const deCapsSlug = decapitalize(currentUser?.slug);

      await get(`/user/profile/details/${deCapsSlug}`, getAuthConfig())
        .then((res) => {
          setLoggedUserDetails(res?.data?.data);
        })
        .catch((err) => {});
    }
  };

  UseEffectOnce(() => {
    if (currentUser?.slug) {
      fetchAllLoggedUserDetails();
    }
  });

  return (
    <>
      <Header home />

      <div className="grey-bg">
        <div className="container-fluid">
          <div className="layout-grid-box">
            <LeftSideBar loggedUserDetails={loggedUserDetails} />
            <div className="layout-grid">
              <div className="posts">
                <div className="posts-header"></div>

                <div className="posts-content collapse show" id="collapupdate">
                  {loading ? (
                    <></>
                  ) : (
                    <>
                      <ConnectionFeeds
                      // postData={postData}
                      // lastBookElementRef={lastBookElementRef}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <RightSideBar />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectionFeedsMain;
