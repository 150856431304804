import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import { get, getAuthConfig, postwithOu } from "../libs/http-hydrate";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const SendQuote = ({ productDetail }) => {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }
  // const [paymentType, setPaymentType] = useState("");
  const [selectedOption, setSelectedOption] = useState("advance");
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [gst, setGst] = useState(0);
  const [freightCharge, setFreightCharge] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [negotiation, setNegotiation] = useState(false);
  const [Hsn, setHsn] = useState("");
  const CloseRef = useRef(null);
  const navigate = useNavigate();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleAmount = (e) => {
    setAmount(e.target.value);
  };

  const handleHsn = (e) => {
    setHsn(e.target.value);
  };
  const handleGST = (e) => {
    setGst(e.target.value);
  };

  const handleFreightCharge = (e) => {
    setFreightCharge(e.target.value);
  };

  const addOrderToCart = async (button) => {
    const formData = new FormData();
    formData.append("product_id", productDetail?.id);
    formData.append("payment_type", selectedOption);
    formData.append("quantity", productDetail?.order_qty);
    formData.append(
      "gst",
      productDetail?.gst_rate !== null ? productDetail?.gst_rate : gst
    );
    formData.append(
      "freight_charge_percentage",
      productDetail?.freight_charges !== null
        ? productDetail?.freight_charges
        : freightCharge
    );
    formData.append("amount", amount);
    formData.append(
      "hsn_code",
      productDetail?.hsn_code !== null ? productDetail?.hsn_code : Hsn
    );
    formData.append("can_revise_offer", negotiation === true ? 1 : 0);
    await postwithOu(
      "/negotiation/create_from_product",
      getAuthConfig(),
      formData
    )
      .then((res) => {
        setOrderId(res.data.data.id);
        // sendInitialOffer(res.data.data.id);
        localStorage.setItem("SOID", res?.data?.data?.id);
        if (button === "buttonView" && localStorage.getItem("SOID")) {
          CloseRef.current.click();
          navigate(
            "negotiation-seller"
            // `/negotiation-buyer-adjustment-post/${res?.data?.data?.id}`,
            // { state: { negotiationId: res?.data?.data?.id } }
          );
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    totalFunction();
  }, [amount, gst, freightCharge, productDetail?.freight_charges]);

  const totalFunction = () => {
    const totalAmount = Number(productDetail?.order_qty) * Number(amount);
    const totalFreight =
      (Number(totalAmount) *
        Number(
          productDetail?.freight_charges
            ? productDetail?.freight_charges
            : freightCharge
        )) /
      100;
    const totalAfterFreight = Number(totalAmount) + Number(totalFreight);
    const totalGst = (Number(totalAfterFreight) * Number(gst)) / 100;
    const total = totalAfterFreight + totalGst;
    setTotalInvoice(total);
  };

  const handleNegotiation = (e) => {
    setNegotiation(e.target.checked);
  };

  return (
    <>
      <ToastContainer />

      <div
        className="modal fade post-social-modal"
        id="SendQuote"
        tabindex="-1"
        role="dialog"
        aria-labelledby="SendQuoteLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Send Quote
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={CloseRef}
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="post-social-head row">
                <div className="select-wrap col-sm">
                  <div className="row">
                    <div className="form-field col-sm">
                      <label className="form-label">Payment Type</label>
                      <br />
                      <input
                        type="radio"
                        name="paymentTyp"
                        value="advance"
                        id="advance"
                        checked={selectedOption === "advance"}
                        onChange={handleOptionChange}
                      />
                      <label htmlFor="advance" style={{ fontWeight: "normal" }}>
                        Advance
                      </label>
                      &nbsp;&nbsp;
                      <input
                        type="radio"
                        name="paymentTyp"
                        value="credit"
                        id="credit"
                        checked={selectedOption === "credit"}
                        onChange={handleOptionChange}
                      />
                      <label htmlFor="credit" style={{ fontWeight: "normal" }}>
                        Credit
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-field col-sm">
                      <label className="form-label">Amount (Per Qty)</label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="amount"
                        id="amount"
                        onChange={handleAmount}
                        className="form-input"
                        placeholder="Amount"
                        value={amount}
                      />
                    </div>
                    <div className="form-field col-sm">
                      <label className="form-label">Quantity</label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="quantity"
                        value={productDetail?.order_qty}
                        id="quantity"
                        className="form-input"
                        placeholder="Quantity"
                        disabled={true}
                        style={{ color: "#000" }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-field col-sm">
                      <label className="form-label">Freight Charge (%)</label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="freight"
                        id="freight"
                        className="form-input"
                        placeholder="Freight"
                        disabled={
                          productDetail?.freight_charges !== null ? true : false
                        }
                        onChange={handleFreightCharge}
                        value={
                          productDetail?.freight_charges
                            ? productDetail?.freight_charges
                            : freightCharge
                        }
                      />
                    </div>
                    <div className="form-field col-sm">
                      <label className="form-label">GST (%)</label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="gst"
                        id="gst"
                        value={
                          productDetail?.gst_rate !== null
                            ? productDetail?.gst_rate
                            : gst
                        }
                        disabled={
                          productDetail?.gst_rate !== null ? true : false
                        }
                        onChange={handleGST}
                        className="form-input"
                        placeholder="GST"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-field col-sm">
                      <label className="form-label">HSN Code</label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="amount"
                        id="amount"
                        onChange={handleHsn}
                        className="form-input"
                        placeholder="HSN Code "
                        value={
                          productDetail?.hsn_code !== null
                            ? productDetail?.hsn_code
                            : Hsn
                        }
                        disabled={
                          productDetail?.hsn_code !== null ? true : false
                        }
                      />
                    </div>
                    <div className="col-sm">
                      <div
                        className="chips chips--white"
                        style={{ marginBottom: "15px" }}
                      >
                        <div className="chips--text">Negotiation</div>
                        <div className="chips--value">
                          <div className="switch-box switch-box--withLabel switch-box--small">
                            <input
                              type="checkbox"
                              id="negotiation_switch"
                              onChange={handleNegotiation}
                              value={negotiation}
                            />
                            <label htmlFor="negotiation_switch">
                              <span className="switch-icon off">OFF</span>
                              <span className="switch-icon on">ON</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm">
                      <span>
                        <b>Total Invoice Value</b> : {`${totalInvoice}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="button button-primary ml-0"
                onClick={() => addOrderToCart("buttonView")}
              >
                Send Initial Offer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendQuote;
