export const customStyles = (darkMode, isComefromProfile = false) => ({
  control: (provided, state) => ({
    ...provided,
    borderRadius: isComefromProfile ? "5px" : "10px", // Rounded corners
    borderColor: state.isFocused ? "#bdbdbd" : "#bdbdbd", // Focus and default border color
    boxShadow: state.isFocused ? "0 0 0 1px #bdbdbd" : "none", // Box shadow on focus
    background: darkMode ? "#1C2226" : "#fff", // Background color for dark mode
    color: darkMode ? "#fff" : "#000", // Text color for dark mode
    "&:hover": {
      borderColor: "#bdbdbd", // Hover border color
    },
    minHeight: "44px", // Height of the select input
    padding: "0 8px", // Padding inside the select box
    border: darkMode ? "1px solid #383a3d" : "1px solid #BDBDBD",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 8px", // Padding around the value
  }),
  placeholder: (provided) => ({
    ...provided,
    color: darkMode ? "#fff" : "#000", // Placeholder color for dark mode
    fontSize: "13px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: darkMode ? "#fff" : "#333333", // Text color for the selected value
    fontSize: "13px", // Font size for the selected value
  }),
  indicatorSeparator: () => ({
    display: "none", // Removes the vertical separator
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#B2D4FF" : "#999999", // Arrow color on focus and default
    "&:hover": {
      color: "#B2D4FF", // Hover color for the arrow
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "8px", // Rounded corners for the dropdown
    marginTop: "0px", // No margin between the input and the dropdown
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow for the dropdown
    background: darkMode ? "#1C2226" : "#fff", // Background color for dropdown in dark mode
    color: darkMode ? "#fff" : "#000", // Text color for dropdown options
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#2684FF"
      : state.isFocused
      ? darkMode
        ? "#3f92ff"
        : "#F5F5F5"
      : darkMode
      ? "#1C2226"
      : "white", // Background color on select and focus
    color: state.isSelected ? "white" : darkMode ? "#fff" : "#333333", // Text color on select
    "&:active": {
      backgroundColor: "#2684FF", // Background color on click
      color: "white", // Text color on click
    },
    "&:hover": {
      backgroundColor: darkMode ? "#3f92ff" : "#3f92ff", // Dark mode-specific hover color
      color: darkMode ? "white" : "white", // Text color for hover in dark mode
    },
    fontSize: "14px",
  }),
});
