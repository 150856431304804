import React, { useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import Breadcrumb from "../common/breadcrumb";
import { ReactComponent as AttachmentIcon } from "../assets/images/attachment.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ShortOrderStepper from "../common/Stepper/ShortOrderStepper";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import DigitalClock from "../components/ShortOrder/PaymentStep/Clock/DigitalClock";

function ProductPayment() {
  const [shortOrderDetails, setShortOrderDetails] = useState();

  const [PaymentDetails, setPaymentDetails] = useState({
    order_id: "",
    paid_by: "",
    reference_id: "",
    account_number: "",
    paid_at: "",
    amount: "",
    payment_proof: "",
  });

  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);

  const parentToChild = (slug) => {
    setUserData(slug);
  };

  let currentStep;

  const location = useLocation();

  const navigate = useNavigate();

  const getProductCartSO = async () => {
    setLoading(true);
    if (location?.state?.id) {
      await get(`/short_order/details/${location?.state?.id}`, getAuthConfig())
        .then((res) => {
          setLoading(false);
          setShortOrderDetails(res?.data?.data);
          localStorage.setItem("paymentType", res?.data?.data?.payment_type);
          // setProducts(res?.data?.data?.items);
        })
        .catch((err) => {
          setLoading(false);
          if (
            err.response.status === 400 &&
            err?.response?.data?.message === "Short order not found"
          ) {
            navigate("/");
          }
        });
    }
  };

  const submittable =
    PaymentDetails?.paid_by !== "" &&
    PaymentDetails?.account_number !== "" &&
    PaymentDetails?.paid_at !== "" &&
    PaymentDetails?.amount !== "" &&
    PaymentDetails?.reference_id !== "";

  useEffect(() => {
    getProductCartSO();
  }, [location?.state?.id]);

  const payment = localStorage.getItem("paymentType");

  currentStep = payment === "advance" ? 6 : 9;

  const currentStepBuyer = currentStep - 1;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async function AddPaymentDetails() {
    if (userData?.data?.is_seller === 0) {
      if (
        !shortOrderDetails?.payment_detail?.paid_by &&
        PaymentDetails.paid_by === ""
      ) {
        toast.error("Bank name is required field");
        return;
      } else if (
        !shortOrderDetails?.payment_detail?.account_number &&
        PaymentDetails?.account_number === ""
      ) {
        toast.error("Account number is required field");
        return;
      } else if (
        !shortOrderDetails?.payment_detail?.paid_at &&
        PaymentDetails?.paid_at === ""
      ) {
        toast.error("Payment date is required field");
        return;
      } else if (
        !shortOrderDetails?.payment_detail?.amount &&
        PaymentDetails?.amount === ""
      ) {
        toast.error("Amount is required field");
        return;
      } else if (
        !shortOrderDetails?.payment_detail?.reference_id &&
        PaymentDetails?.reference_id === ""
      ) {
        toast.error("Reference ID is required field");
        return;
      }
    }
    // if (!submittable) return;
    if (
      // userData?.data?.is_seller === 1 &&
      shortOrderDetails?.payment_detail?.paid_by &&
      shortOrderDetails?.payment_type === "credit"
    )
      return navigate("/product-rating", {
        state: { id: shortOrderDetails?.id, route: location?.state?.route },
      });
    if (
      userData?.data?.is_seller === 1 &&
      shortOrderDetails?.payment_detail?.paid_by &&
      shortOrderDetails?.payment_type === "advance"
    )
      return navigate("/product-e-bill", {
        state: {
          id: shortOrderDetails?.id,

          route: location?.state?.route,
        },
      });
    if (userData?.data?.is_seller === 1) return;
    if (
      shortOrderDetails?.payment_type === "advance" &&
      shortOrderDetails?.step?.current_step >= "6"
    ) {
      return navigate("/product-e-bill", {
        state: {
          id: shortOrderDetails?.id,
          route: location?.state?.route,
        },
      });
    }
    const formData = new FormData();
    formData.append("order_id", shortOrderDetails?.id);
    formData.append("paid_by", PaymentDetails?.paid_by);
    formData.append("reference_id", PaymentDetails?.reference_id);
    formData.append("account_number", PaymentDetails?.account_number);
    formData.append(
      "paid_at",
      moment(PaymentDetails?.paid_at).format("YYYY-MM-DD HH:mm:ss")
    );
    formData.append("amount", PaymentDetails?.amount);
    try {
      if (PaymentDetails?.payment_proof?.name) {
        formData.append("payment_proof", PaymentDetails?.payment_proof);
      }

      const response = await post(
        "/short_order/update/payment",
        formData,
        getAuthConfig()
      );
      if (response.status !== 200) return;
      if (response.status === 200) {
        navigate(
          `${
            shortOrderDetails?.payment_type === "credit"
              ? "/product-rating"
              : "/product-e-bill"
          }`,
          {
            state: { id: shortOrderDetails?.id, route: location?.state?.route },
          }
        );
        toast.success("Payment details added successfully");
      }
    } catch (err) {
      navigate("");
      toast.error(err.response.data.message);
    }
  }

  return (
    <>
      <ToastContainer />
      <Header home disableSwitch userType={parentToChild} />

      <div className="grey-bg">
        <div className="container-fluid order-page">
          <Breadcrumb shortOrderDetails={shortOrderDetails} />
          <div className="steps">
            <ShortOrderStepper
              currentStep={currentStep}
              isCompleted={currentStepBuyer}
            />
          </div>
          <div className="order product-order bank-detail-page">
            <div style={{ height: "50px" }} className="page-title">
              <h6>Payment Release - (Buyer)</h6>
            </div>
            <div
              className={`company-detail bank-detail ${
                userData?.data?.is_seller === 1 &&
                shortOrderDetails?.payment_detail?.reference_id === undefined &&
                "blurred-card"
              }`}
            >
              {userData?.data?.is_seller === 1 &&
                shortOrderDetails?.payment_detail?.reference_id ===
                  undefined && <div className="overlay"></div>}
              <div className="row">
                <div className="form-field col-sm">
                  <label className="form-label">
                    Bank Name/Payment Mode<span className="mendatory">*</span>
                  </label>

                  <input
                    type="text"
                    name="paid_by"
                    id="mode"
                    className="form-input"
                    placeholder="Enter Your Bank Name"
                    value={
                      shortOrderDetails?.payment_detail?.paid_by
                        ? shortOrderDetails?.payment_detail?.paid_by
                        : PaymentDetails?.paid_by
                    }
                    onChange={handleChange}
                    readOnly={
                      userData?.data.is_seller === 1 ||
                      shortOrderDetails?.payment_detail?.paid_by
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="form-field col-sm">
                  <label className="form-label">
                    Account Number<span className="mendatory">*</span>
                  </label>
                  <input
                    type="text"
                    name="account_number"
                    id="acc_num"
                    className="form-input"
                    placeholder="Enter Your Account Number"
                    value={
                      shortOrderDetails?.payment_detail?.account_number
                        ? shortOrderDetails?.payment_detail?.account_number
                        : PaymentDetails?.account_number
                    }
                    onChange={handleChange}
                    readOnly={
                      userData?.data.is_seller === 1 ||
                      shortOrderDetails?.payment_detail?.account_number
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-field col-sm">
                  <label className="form-label">
                    Payment Date<span className="mendatory">*</span>
                  </label>
                  <input
                    type={`${
                      shortOrderDetails?.payment_detail?.paid_at
                        ? "text"
                        : "date"
                    }`}
                    name="paid_at"
                    id="pdate"
                    className="form-input"
                    placeholder="Please Select Date"
                    value={
                      shortOrderDetails?.payment_detail?.paid_at
                        ? moment(
                            shortOrderDetails?.payment_detail?.paid_at
                          ).format("DD-MM-YYYY")
                        : PaymentDetails?.paid_at
                    }
                    onChange={handleChange}
                    readOnly={
                      userData?.data.is_seller === 1 ||
                      shortOrderDetails?.payment_detail?.paid_at
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="form-field col-sm">
                  <label className="form-label">
                    Amount<span className="mendatory">*</span>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="amount"
                    id="amount"
                    className="form-input"
                    placeholder="Enter Your Amount"
                    value={
                      shortOrderDetails?.payment_detail?.amount
                        ? shortOrderDetails?.payment_detail?.amount
                        : PaymentDetails?.amount
                    }
                    onChange={handleChange}
                    readOnly={
                      userData?.data.is_seller === 1 ||
                      shortOrderDetails?.payment_detail?.amount
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-field col-sm">
                  <label className="form-label">
                    Reference ID<span className="mendatory">*</span>
                  </label>
                  <input
                    type="text"
                    name="reference_id"
                    id="rid"
                    className="form-input"
                    placeholder="Enter Your Reference ID"
                    value={
                      shortOrderDetails?.payment_detail?.reference_id
                        ? shortOrderDetails?.payment_detail?.reference_id
                        : PaymentDetails?.reference_id
                    }
                    onChange={handleChange}
                    readOnly={
                      userData?.data.is_seller === 1 ||
                      shortOrderDetails?.payment_detail?.reference_id
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="form-field col-sm">
                  <label className="form-label">Attach File</label>
                  <div className="file-upload">
                    <input
                      disabled={
                        userData?.data.is_seller === 1 ||
                        shortOrderDetails?.payment_detail?.reference_id
                          ? true
                          : false
                      }
                      type="file"
                      name="payment_proof"
                      id="upload_attachement"
                      onChange={(e) => {
                        if (
                          !(
                            e.target.files[0].type === "image/png" ||
                            e.target.files[0].type === "image/jpg" ||
                            e.target.files[0].type === "image/jpeg" ||
                            e.target.files[0].type === "image/gif" ||
                            e.target.files[0].type === "application/pdf" ||
                            e.target.files[0].type === "application/msword" ||
                            e.target.files[0].type ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          )
                        ) {
                          alert("Only images,pdf,doc,docx allowded");
                          return false;
                        }
                        if (e.target.files[0].size > 2097152) {
                          alert("Not More than 2 MB is allowded");
                          return false;
                        }
                        setPaymentDetails({
                          ...PaymentDetails,
                          payment_proof: e.target.files[0],
                        });
                      }}
                    />
                    <label htmlFor="upload_attachement">
                      {PaymentDetails?.payment_proof?.name
                        ? PaymentDetails?.payment_proof?.name
                        : `${
                            shortOrderDetails?.payment_detail?.payment_proof
                              ? "View Attachment"
                              : "Please choose your attachment file"
                          }`}

                      {shortOrderDetails?.payment_detail?.payment_proof ? (
                        <>
                          <span className="icon">
                            <a
                              href={
                                shortOrderDetails?.payment_detail?.payment_proof
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              View
                            </a>
                          </span>
                        </>
                      ) : (
                        <span className="icon">
                          <AttachmentIcon />{" "}
                        </span>
                      )}
                    </label>
                  </div>
                </div>
              </div>
              {shortOrderDetails?.payment_type === "credit" &&
                shortOrderDetails?.status !== "completed" &&
                shortOrderDetails?.step?.current_step <= "7" && (
                  <DigitalClock
                    userType={userData?.data.is_seller === 1}
                    loading={loading}
                    paymentDays={shortOrderDetails?.payment_due_days}
                    dueTime={shortOrderDetails?.payment_due_at}
                  />
                )}
            </div>
            {!loading && (
              <>
                {userData?.data.is_seller === 1 &&
                  shortOrderDetails?.payment_detail?.paid_by === undefined && (
                    <div
                      className="note"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "35px",
                        color: "red",
                      }}
                    >
                      <p>Wait until buyer release payment for this order.</p>
                    </div>
                  )}
                <div
                  style={{
                    height: "60px",
                  }}
                  className="btn-wrap"
                >
                  <Link
                    state={{
                      id: shortOrderDetails?.id,
                      route: location?.state?.route,
                    }}
                    to={"/product-bank-detail"}
                    className="button button-blue"
                  >
                    Previous
                  </Link>
                  <button
                    disabled={
                      userData?.data?.is_seller === 1 &&
                      ((shortOrderDetails?.step?.current_step <= 4 &&
                        shortOrderDetails?.payment_type === "advance") ||
                        (shortOrderDetails?.step?.current_step <= 4 &&
                          shortOrderDetails?.payment_type === "advance"))
                        ? true
                        : false
                    }
                    onClick={AddPaymentDetails}
                    className="button button-primary"
                  >
                    {`${
                      userData?.data?.is_seller === 0 &&
                      ((shortOrderDetails?.step?.current_step <= 4 &&
                        shortOrderDetails?.payment_type === "advance") ||
                        (shortOrderDetails?.step?.current_step <= 4 &&
                          shortOrderDetails?.payment_type === "advance"))
                        ? "Submit & Next"
                        : "Next"
                    }`}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductPayment;
