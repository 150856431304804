export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export const dataURLtoFile = (dataurl, filename) => {
  try {
    if (!dataurl?.startsWith("data:")) {
      throw new Error("Input is not a valid Base64-encoded data URL.");
    }

    var arr = dataurl.split(",");
    if (arr.length !== 2) {
      throw new Error("Invalid data URL structure.");
    }

    var mime = arr[0]?.match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  } catch (error) {
    console.error("Error converting data URL to file:", error.message);
    return null; // Return null or handle the error as needed
  }
};

export default async function getCroppedImgr(imageSrc) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  canvas.width = 400;
  canvas.height = 400;
  const ctx = canvas.getContext("2d");
  // ctx.width = 400 + 'px';
  // ctx.height = 400 + 'px';
  ctx.drawImage(image, 0, 0, 400, 400);
  return new Promise((resolve, reject) => {
    resolve(canvas.toDataURL());
    // canvas.toBlob((file) => {
    //   resolve(URL.createObjectURL(file));
    // }, "image/jpeg");
  });
}
