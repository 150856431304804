import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import "./scss/common/breadcrumb.scss";
import Auth from "../libs/auth";

function Breadcrumb(props) {
  const user = Auth.getCurrentUser();

  const location = useLocation();

  const companyName = useMemo(
    () =>
      user?.id === props?.shortOrderDetails?.to_user?.id
        ? props?.shortOrderDetails?.user?.company_details?.name
        : props?.shortOrderDetails?.to_user?.company_details?.name,
    [user, props?.shortOrderDetails]
  );

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {location?.state?.route === "transaction" ? (
            <li className="breadcrumb-item">
              <Link
                style={{ fontSize: "16px", fontWeight: 700 }}
                to={`/transaction`}
              >
                Transaction - <span>{companyName || ""}</span>
              </Link>
            </li>
          ) : (
            <>
              <li className="breadcrumb-item">
                <Link
                  to={`/edit-profile-seller/${
                    props?.shortOrderDetails?.to_user?.id || user?.slug
                  }`}
                >
                  Profile
                </Link>
              </li>
              <li
                className={
                  props.props === "List"
                    ? `breadcrumb-item `
                    : `breadcrumb-item `
                }
                aria-current="page"
              >
                <Link
                  to={
                    props?.shortOrderDetails
                      ? `/product-portfolio-initial/${
                          props?.shortOrderDetails?.to_user?.id || user?.slug
                        }`
                      : `/product-portfolio-initial`
                  }
                >
                  Product Portfolio{" "}
                </Link>
              </li>
            </>
          )}

          {props.props === "Create" ? (
            <li className="breadcrumb-item active" aria-current="page">
              Create Product Portfolio
            </li>
          ) : (
            ""
          )}
          {props.props === "Edit" ? (
            <li className="breadcrumb-item active" aria-current="page">
              Edit Product Portfolio
            </li>
          ) : (
            ""
          )}
        </ol>
      </nav>
    </>
  );
}

export default Breadcrumb;
